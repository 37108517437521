import { boolean, route, string, union } from 'react-router-typesafe-routes';
export const ROUTES = {
    REGISTER: route({ path: 'register' }),
    AUTH: route({
        path: 'auth/*',
        children: {
            SIGN_IN: route({
                path: 'sign-in',
                searchParams: {
                    inviteCode: string(),
                    emailConfirmed: boolean().default(false),
                },
            }),
            SIGN_UP: route({
                path: 'sign-up',
                searchParams: {
                    inviteCode: string(),
                },
            }),
            FORGOT_PASSWORD: route({ path: 'forgot-password' }),
            RESET_PASSWORD: route({
                path: 'reset-password',
                searchParams: {
                    email: string().defined(),
                    token: string().defined(),
                },
            }),
            TWO_FACTOR_SELECT: route({
                path: 'two-factor/select',
                state: {
                    email: string().defined(),
                    password: string().defined(),
                    inviteCode: string(),
                },
            }),
            TWO_FACTOR: route({
                path: 'two-factor',
                state: {
                    email: string().defined(),
                    password: string().defined(),
                    challengeId: string().defined(),
                    type: union(['NONE', 'EMAIL', 'AUTHENTICATOR']).defined(),
                    inviteCode: string(),
                },
            }),
            CONFIRM_EMAIL: route({
                path: 'confirm-email',
                searchParams: {
                    token: string().defined(),
                    email: string().defined(),
                },
            }),
            ACCEPT_INVITE: route({
                path: 'accept-invite',
                searchParams: {
                    email: string().defined(),
                    token: string().defined(),
                },
            }),
        },
    }),
    INVITED: route({ path: 'invited' }),
    RESETTING_PW: route({ path: 'resetting-password' }),
    HOME: route({
        path: ':workspaceId/*',
        children: {
            CHAT: route({ path: 'chat/:chatId?' }),
            SEARCH: route({ path: 'search/:searchAnswerId?' }),
            FILE_MANAGER: route({ path: 'fileManager/:collectionId?' }),
            SETTINGS: route({
                path: 'settings/*',
                children: {
                    ACCOUNT: route({
                        path: 'account',
                        children: {
                            PERSONAL: route({
                                path: 'personal',
                                searchParams: {
                                    resetPassword: string(),
                                    setPassword: string(),
                                },
                            }),
                            SECURITY: route({ path: 'security' }),
                        },
                    }),
                    WORKSPACE: route({
                        path: 'workspace',
                        children: {
                            GENERAL: route({ path: 'general' }),
                            LICENSE: route({ path: 'license' }),
                            USER_MANAGEMENT: route({ path: 'user_management' }),
                            GLOSSARY: route({ path: 'glossary' }),
                        },
                    }),
                    INTEGRATIONS: route({
                        path: 'integrations',
                        children: {
                            SHAREPOINT: route({ path: 'sharepoint' }),
                            CONFLUENCE: route({ path: 'confluence' }),
                        },
                    }),
                    OPEN_SOURCE_SOFTWARE: route({ path: 'open-source-software' }),
                },
            }),
            PRICING: route({
                path: 'pricing',
                searchParams: { navBackOnClose: string() },
            }),
            ONBOARDING: route({
                path: 'onboarding',
                state: {
                    isTrial: boolean().defined(),
                    plan: union(['BASIC', 'PROFESSIONAL']).defined(),
                },
            }),
            DOCUMENT_DEBUG_VIEW: route({ path: 'documentView/:id' }),
        },
    }),
    INVITE_LINK_WITH_CODE: route({ path: 'invite/:inviteCode' }),
    IFRAME: route({
        path: 'iframe/:iframeId/*',
        children: { SEARCH: route({ path: 'search' }) },
    }),
    MS_OAUTH: route({
        path: 'ms_oauth',
        searchParams: {
            code: string().defined(),
            state: string().defined(),
        },
    }),
    ATL_OAUTH: route({
        path: 'atl_oauth',
        searchParams: {
            code: string().defined(),
            state: string().defined(),
        },
    }),
};
