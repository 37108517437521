import { HTMLAttributes } from 'react';

type ConfluenceTenantLinkProps = {
  tenantUrl: string;
} & HTMLAttributes<HTMLAnchorElement>;
export function ConfluenceTenantLink({
  tenantUrl,
  ...props
}: ConfluenceTenantLinkProps) {
  try {
    const url = new URL(tenantUrl);
    const parts = url.hostname.split('.');
    // If there's a subdomain (assuming more than two parts), take the first part
    const subdomain = parts.length > 2 ? parts[0] : url.hostname;
    return (
      <a {...props} href={tenantUrl}>
        {subdomain}
      </a>
    );
  } catch {
    return '';
  }
}
