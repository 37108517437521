import {
  NavLink,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { Separator, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import AccessControl from '../../components/AccessControl';
import ConfluenceSettings from '../workspaceSettings/ConfluenceSettings';
import ContentHeader from '../../components/ContentHeader';
import GeneralSettings from '../workspaceSettings/GeneralSettings';
import GlossarySettings from '../workspaceSettings/glossarySettings/GlossarySettings';
import LicenseManagement from '../workspaceSettings/LicenseManagement';
import ListItem from '../../components/ListItem';
import MaiaFullGridWidthContent from '../../components/layout/MaiaFullGridWidthContent';
import MaiaLayout from '../../components/MaiaLayout';
import MaiaMainGrid from '../../components/layout/MaiaMainGrid';
import MaiaMenuPanel from '../../components/menu/MaiaMenuPanel';
import { OpenSourceSoftware } from '../userSettings/legalSettings/OpenSourceSoftware';
import PersonalSettings from '../userSettings/PersonalSettings';
import { ROUTES } from 'common-ts';
import SecuritySetting from '../userSettings/securitySettings/SecuritySetting';
import SharepointSettings from '../workspaceSettings/SharepointSettings';
import SharepointWaitlistInfo from '../../components/sharepoint/SharepointWaitlistInfo';
import UserManagement from '../workspaceSettings/UserManagement';
import { faPlug } from '@fortawesome/pro-regular-svg-icons';
import { useBoundStore } from '@/store/useBoundStore';
import { useTranslation } from 'react-i18next';
import Feature from '@/components/Feature';

function Settings() {
  const location = useLocation();
  const { t } = useTranslation();
  const isStripeCustomer = useBoundStore((state) => state.isStripeCustomer);
  const workspaceLicense = useBoundStore((state) => state.workspaceLicenseType);
  const workspaceUserType = useBoundStore((state) => state.workspaceUserType);
  const featureFlags = useBoundStore((state) => state.featureFlags);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  // Paths to settings pages
  const accountPersonalPath = ROUTES.HOME.SETTINGS.ACCOUNT.PERSONAL.$buildPath({
    params: { workspaceId },
  });
  const accountSecurityPath = ROUTES.HOME.SETTINGS.ACCOUNT.SECURITY.$buildPath({
    params: { workspaceId },
  });
  const workspaceGeneralPath =
    ROUTES.HOME.SETTINGS.WORKSPACE.GENERAL.$buildPath({
      params: { workspaceId },
    });
  const workspaceUserManagementPath =
    ROUTES.HOME.SETTINGS.WORKSPACE.USER_MANAGEMENT.$buildPath({
      params: { workspaceId },
    });
  const workspaceLicensePath =
    ROUTES.HOME.SETTINGS.WORKSPACE.LICENSE.$buildPath({
      params: { workspaceId },
    });
  const workspaceGlossaryPath =
    ROUTES.HOME.SETTINGS.WORKSPACE.GLOSSARY.$buildPath({
      params: { workspaceId },
    });
  const integrationsSharepointPath =
    ROUTES.HOME.SETTINGS.INTEGRATIONS.SHAREPOINT.$buildPath({
      params: { workspaceId },
    });
  const integrationConfluencePath =
    ROUTES.HOME.SETTINGS.INTEGRATIONS.CONFLUENCE.$buildPath({
      params: { workspaceId },
    });
  const openSourceSoftwarePath =
    ROUTES.HOME.SETTINGS.OPEN_SOURCE_SOFTWARE.$buildPath({
      params: { workspaceId },
    });

  useEffect(() => {
    setSidePanelOpen(false);
  }, [location]);

  return (
    <>
      <MaiaLayout
        sidePanelContent={
          <MaiaMenuPanel selectedNavigation="SETTINGS">
            <div className="flex flex-col gap-2 px-4 pt-3">
              {/* ------------------------------------------------ Account ------------------------------------------------ */}
              <Text className="font-semibold">Account</Text>
              <NavLink to={accountPersonalPath}>
                <ListItem
                  selected={location.pathname.includes(accountPersonalPath)}
                >
                  {t('userSettings.menu.personal')}
                </ListItem>
              </NavLink>
              <AccessControl
                allowedLicenses={['BASIC', 'PROFESSIONAL', 'ENTERPRISE']}
              >
                <NavLink to={accountSecurityPath}>
                  <ListItem
                    // Special case: open source software is currently under security settings
                    selected={
                      location.pathname.includes(accountSecurityPath) ||
                      location.pathname.includes(openSourceSoftwarePath)
                    }
                  >
                    {t('userSettings.menu.security')}
                  </ListItem>
                </NavLink>
              </AccessControl>
              {/* ------------------------------------------------ Workspace ------------------------------------------------ */}
              <Separator />
              <Text className="text-sm font-semibold">Workspace</Text>
              <AccessControl
                allowedLicenses={['BASIC', 'PROFESSIONAL', 'ENTERPRISE']}
                allowedRoles={['OWNER', 'ADMIN']}
              >
                <NavLink to={workspaceGeneralPath}>
                  <ListItem
                    selected={location.pathname.includes(workspaceGeneralPath)}
                  >
                    {t('workspaceSettings.menu.generalSettings')}
                  </ListItem>
                </NavLink>
                <NavLink to={workspaceUserManagementPath}>
                  <ListItem
                    selected={location.pathname.includes(
                      workspaceUserManagementPath
                    )}
                  >
                    {t('workspaceSettings.menu.userManagement')}
                  </ListItem>
                </NavLink>
                {isStripeCustomer ? (
                  <NavLink to={workspaceLicensePath}>
                    <ListItem
                      selected={location.pathname.includes(
                        workspaceLicensePath
                      )}
                    >
                      {t('workspaceSettings.menu.license')}
                    </ListItem>
                  </NavLink>
                ) : null}
              </AccessControl>
              <AccessControl
                allowedLicenses={['BASIC', 'PROFESSIONAL', 'ENTERPRISE']}
              >
                <NavLink to={workspaceGlossaryPath}>
                  <ListItem
                    selected={location.pathname.includes(workspaceGlossaryPath)}
                  >
                    {t('workspaceSettings.menu.glossary')}
                  </ListItem>
                </NavLink>
              </AccessControl>
              {/* ------------------------------------------------ Integrations ------------------------------------------------ */}
              <AccessControl allowedLicenses={['ENTERPRISE', 'PROFESSIONAL']}>
                <Separator />
                <Text className="text-sm font-semibold">
                  {t('integrationSettings.title')}
                </Text>

                <NavLink to={integrationsSharepointPath}>
                  <ListItem
                    selected={location.pathname.includes(
                      integrationsSharepointPath
                    )}
                  >
                    {t('integrationSettings.sharepoint.title')}
                  </ListItem>
                </NavLink>
                <Feature name="confluence">
                  <NavLink to={integrationConfluencePath}>
                    <ListItem
                      selected={location.pathname.includes(
                        integrationConfluencePath
                      )}
                    >
                      {t('integrationSettings.confluence.title')}
                    </ListItem>
                  </NavLink>
                </Feature>
              </AccessControl>
            </div>
          </MaiaMenuPanel>
        }
        mainPanelContent={
          <Routes>
            {/* ------------------------------------------------ Account ------------------------------------------------ */}
            <Route
              path={ROUTES.HOME.SETTINGS.$.ACCOUNT.PERSONAL.$path({
                relative: true,
              })}
              element={<PersonalSettings />}
            />
            {/* Can't use AccessControl component here because Only Route component is allowed as child of Routes */}
            {workspaceLicense &&
            ['BASIC', 'PROFESSIONAL', 'ENTERPRISE'].includes(
              workspaceLicense
            ) ? (
              <Route
                path={ROUTES.HOME.SETTINGS.$.ACCOUNT.SECURITY.$path({
                  relative: true,
                })}
                element={<SecuritySetting />}
              />
            ) : null}
            {/* ------------------------------------------------ Workspace ------------------------------------------------ */}
            {workspaceLicense &&
            ['BASIC', 'PROFESSIONAL', 'ENTERPRISE'].includes(
              workspaceLicense
            ) &&
            ['OWNER', 'ADMIN'].includes(workspaceUserType) ? (
              <>
                <Route
                  path={ROUTES.HOME.SETTINGS.$.WORKSPACE.GENERAL.$path({
                    relative: true,
                  })}
                  element={<GeneralSettings />}
                />
                <Route
                  path={ROUTES.HOME.SETTINGS.$.WORKSPACE.USER_MANAGEMENT.$path({
                    relative: true,
                  })}
                  element={<UserManagement />}
                />
                {isStripeCustomer ? (
                  <Route
                    path={ROUTES.HOME.SETTINGS.$.WORKSPACE.LICENSE.$path({
                      relative: true,
                    })}
                    element={<LicenseManagement />}
                  />
                ) : null}
              </>
            ) : null}
            {workspaceLicense &&
            ['BASIC', 'PROFESSIONAL', 'ENTERPRISE'].includes(
              workspaceLicense
            ) ? (
              <Route
                path={ROUTES.HOME.SETTINGS.$.WORKSPACE.GLOSSARY.$path({
                  relative: true,
                })}
                element={<GlossarySettings />}
              />
            ) : null}
            {/* ------------------------------------------------ Integrations ------------------------------------------------ */}
            {workspaceLicense &&
            ['PROFESSIONAL', 'ENTERPRISE'].includes(workspaceLicense) ? (
              <>
                <Route
                  path={ROUTES.HOME.SETTINGS.$.INTEGRATIONS.SHAREPOINT.$path({
                    relative: true,
                  })}
                  element={
                    featureFlags?.sharepoint ? (
                      <SharepointSettings />
                    ) : (
                      <MaiaMainGrid>
                        <MaiaFullGridWidthContent className="flex flex-col gap-4">
                          <ContentHeader
                            headingLabel={t('integrationSettings.title')}
                            icon={faPlug}
                            iconLabel={t('integrationSettings.title')}
                            buttons={[]}
                          />
                          <SharepointWaitlistInfo />
                        </MaiaFullGridWidthContent>
                      </MaiaMainGrid>
                    )
                  }
                />
                {featureFlags?.confluence && (
                  <Route
                    path={ROUTES.HOME.SETTINGS.$.INTEGRATIONS.CONFLUENCE.$path({
                      relative: true,
                    })}
                    element={<ConfluenceSettings />}
                  />
                )}
              </>
            ) : null}
            <Route
              path=""
              element={
                <Navigate
                  to={ROUTES.HOME.SETTINGS.$.ACCOUNT.PERSONAL.$buildPath({
                    relative: true,
                  })}
                  replace
                />
              }
            />
            {/* ------------------------------------------------ Legal ------------------------------------------------ */}
            <Route
              path={ROUTES.HOME.SETTINGS.$.OPEN_SOURCE_SOFTWARE.$path({
                relative: true,
              })}
              element={<OpenSourceSoftware />}
            />
          </Routes>
        }
        sidePanelOpenMobile={sidePanelOpen}
        onOpenSidePanelMobile={() => setSidePanelOpen(true)}
        onCloseSidePanelMobile={() => setSidePanelOpen(false)}
      />
    </>
  );
}

export default Settings;
