import * as Sentry from '@sentry/react';

import LoginView, { LoginViewTabEnum } from './pages/login/LoginView.js';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import {
  browserTracingIntegration,
  captureConsoleIntegration,
} from '@sentry/react';
import { useEffect, useMemo } from 'react';

import AcceptInvite from './pages/login/AcceptInvite';
import AtlassianOauthPage from './pages/oauth/AtlassianOauthPage.js';
import ConfirmEmail from './pages/login/ConfirmEmail';
import DefaultWorkspaceReroute from './pages/home/DefaultWorkspaceReroute.js';
import ForgotPassword from './pages/login/ForgotPassword.js';
import Home from './pages/home/Home.js';
import IframeContainer from './pages/iframe/Container.js';
import InviteLinkeReroute from './pages/home/InviteLinkeReroute.js';
import LoginContainer from './pages/login/LoginContainer.js';
import MicrosoftOauthPage from './pages/oauth/MicrosoftOauthPage.js';
import { ROUTES } from 'common-ts';
import ResetPassword from './pages/login/ResetPassword';
import TwoFactorSelect from './pages/login/TwoFactorSelect.js';
import TwoFactorVerificationForm from './pages/login/TwoFactorVerificationForm.js';
import posthog from 'posthog-js';
import { useBoundStore } from './store/useBoundStore.js';
import useForceUpdateHook from './general/useForceUpdateHook.js';

function initPosthog() {
  // This can also be done by configuring our posthog account to filter out
  // events from localhost and certain domains.
  // However, this is the quickest way to get it working. We can always
  // change it later.

  const stage = import.meta.env.VITE_MAIA_STAGE;
  if (stage === 'prod') {
    posthog.init('phc_DBLAnNf6nbOhRG1E18VY2Nca2oWNxvMD86t4YunRSps', {
      api_host: 'https://eu.posthog.com',
    });
  }
}

/**
 * Initialize Sentry by setting up the integrations and the environment.
 */
function initSentry() {
  const stage = import.meta.env.VITE_MAIA_STAGE;
  const sentryDsn = import.meta.env.VITE_MAIA_APP_SENTRY_DSN;
  if (stage === 'prod') {
    Sentry.init({
      dsn: sentryDsn,
      environment: stage,
      // This enables automatic instrumentation (highly recommended),
      // but is not necessary for purely manual usage
      // If you only want to use custom instrumentation:
      // * Remove the `BrowserTracing` integration
      // * add `Sentry.addTracingExtensions()` above your Sentry.init() call
      integrations: [browserTracingIntegration(), captureConsoleIntegration()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }
}

function App() {
  const supabase = useBoundStore((state) => state.supabase);
  useForceUpdateHook();

  useEffect(() => {
    initPosthog();
    initSentry();
  }, []);

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: ROUTES.REGISTER.$path({ relative: true }),
          element: (
            <Navigate
              to={ROUTES.AUTH.SIGN_IN.$path({ relative: true })}
              replace
            />
          ),
        },
        {
          path: ROUTES.AUTH.$path({ relative: true }),
          element: <LoginContainer />,
          children: [
            {
              path: ROUTES.AUTH.$.SIGN_IN.$path({ relative: true }),
              element: <LoginView />,
              index: true,
            },
            {
              path: ROUTES.AUTH.$.SIGN_UP.$path({ relative: true }),
              element: <LoginView defaultTab={LoginViewTabEnum.SIGNUP} />,
            },
            {
              path: ROUTES.AUTH.$.FORGOT_PASSWORD.$path({ relative: true }),
              element: <ForgotPassword />,
            },
            {
              path: ROUTES.AUTH.$.RESET_PASSWORD.$path({ relative: true }),
              element: <ResetPassword />,
            },
            {
              path: ROUTES.AUTH.$.TWO_FACTOR_SELECT.$path({ relative: true }),
              element: <TwoFactorSelect />,
            },
            {
              path: ROUTES.AUTH.$.TWO_FACTOR.$path({ relative: true }),
              element: <TwoFactorVerificationForm />,
            },
            {
              path: ROUTES.AUTH.$.CONFIRM_EMAIL.$path({ relative: true }),
              element: <ConfirmEmail />,
            },
            {
              path: ROUTES.AUTH.$.ACCEPT_INVITE.$path({ relative: true }),
              element: <AcceptInvite />,
            },
          ],
        },
        {
          path: ROUTES.INVITED.$path({ relative: true }),
          element: (
            <DefaultWorkspaceReroute
              redirectTo={(workspaceId) => {
                return ROUTES.HOME.SETTINGS.ACCOUNT.PERSONAL.$buildPath({
                  params: { workspaceId },
                  searchParams: { setPassword: 'true' },
                });
              }}
            />
          ),
        },
        {
          path: ROUTES.RESETTING_PW.$path({ relative: true }),
          element: (
            <DefaultWorkspaceReroute
              redirectTo={(workspaceId) => {
                return ROUTES.HOME.SETTINGS.ACCOUNT.PERSONAL.$buildPath({
                  params: { workspaceId },
                  searchParams: { resetPassword: 'true' },
                });
              }}
            />
          ),
        },
        {
          path: ROUTES.MS_OAUTH.$path({ relative: true }), // Route outside of HOME (/:workspaceId) so that we have a static route to redirect to from the Oauth flow
          element: <MicrosoftOauthPage />,
        },
        {
          path: ROUTES.ATL_OAUTH.$path({ relative: true }), // Route outside of HOME (/:workspaceId) so that we have a static route to redirect to from the Oauth flow
          element: <AtlassianOauthPage />,
        },
        {
          path: ROUTES.HOME.$path({ relative: true }),
          element: <Home />,
        },
        {
          path: ROUTES.INVITE_LINK_WITH_CODE.$path({ relative: true }),
          element: <InviteLinkeReroute />,
        },
        {
          path: ROUTES.IFRAME.$path({ relative: true }),
          element: <IframeContainer />,
        },
        { path: '*', element: <DefaultWorkspaceReroute /> },
      ]),
    [supabase]
  );
  return <RouterProvider router={router} />;
}

export default App;
