import { defineRecipe } from '@chakra-ui/react';

export const inputRecipe = defineRecipe({
  base: {
    fontSize: '12px',
    borderRadius: 'xs',
    height: '32px',
    _selection: {
      backgroundColor: 'gray.200',
      color: 'inherit',
    },
  },
});
