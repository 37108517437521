import {
  AnswerGenerationModels,
  AvailableModelEnum,
  CONFLUENCE_SELECTION_MAP_PATH_DELIMITER,
  ChatClient,
  ChatMessageType,
  ChatServer,
  ConfluenceFileSelectionMap,
  GetAnswer,
  GetChatDocumentAnswer,
  GetChatDocumentPowerAnswer,
  GetPastMessages,
  MaiaChatGlossaryUsed,
  MaiaChatSnippets,
  MaiaStreamAbort,
  SHAREPOINT_SELECTION_MAP_PATH_DELIMITER,
  STORAGE_SELECTION_MAP_PATH_DELIMITER,
  SharepointFileSelectionMap,
  StorageFileSelectionMap,
  cleanUpFileSelectionMap,
} from 'common-ts';
import {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ChatInput from './chatInput/ChatInput.js';
import ChatMessage from './ChatMessage.js';
import ChatRequestLimitToaster from './ChatRequestLimitToaster.js';
import { FREE_USER_REQUEST_LIMIT } from '@/utils/userPlan';
import LogoPure from '../../components/Logo/LogoPure.js';
import MaiaAlertDialogWithUpgradeButton from '../../components/MaiaAlertDialogWithUpgradeButton.js';
import MaiaMessageGroup from './DocumentChatMessageGroup.js';
import MessageLoadingIndicator from './MessageLoadingIndicator.js';
import PowerModeLogs from './PowerModeLogs.js';
import { Skeleton } from '@chakra-ui/react';
import StreamingChatMessage from './StreamingChatMessage.js';
import { captureException } from '@sentry/react';
import { chatSocket } from '@/utils/socket';
import { isFileSelectable } from '@/pages/chat/fileSelector/utils';
import { toaster } from '@/components/ui/toaster';
import { useBoundStore } from '@/store/useBoundStore';
import useConfluenceSelectionStateHandlers from './fileSelector/useConfluenceSelectionStateHandlers.js';
import useSharepointSelectionStateHandlers from '@/pages/chat/fileSelector/useSharepointSelectionStateHandlers';
import { useSocketIo } from '@/utils/useSocketIo';
import useStorageSelectionStateHandlers from '@/pages/chat/fileSelector/useStorageSelectionStateHandlers';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

export const FILE_CONTEXT_LOCAL_STORAGE_KEY = 'file_context';
export const SHAREPOINT_CONTEXT_LOCAL_STORAGE_KEY = 'sharepoint_context';
export const CONFLUENCE_CONTEXT_LOCAL_STORAGE_KEY = 'confluence_context';

type ChatProps = {
  chatId: string;
  onFirstAnswerReceived: () => void;
};

function Chat({ chatId, onFirstAnswerReceived }: ChatProps) {
  // Translation
  const { t } = useTranslation();

  // Store state
  const csv = useBoundStore((state) => state.featureFlags?.csv);
  const accessToken = useBoundStore((state) => state.accessToken);
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const userIsMarker = useBoundStore((state) => state.userIsMarker);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const extendedBuckets = useBoundStore((state) => state.extendedBuckets);
  const sentRequests = useBoundStore((state) => state.sentRequests);
  const optimisticUpdateSentRequests = useBoundStore(
    (state) => state.optimisticUpdateSentRequests
  );

  // LLM model storage
  const model = useBoundStore((state) => state.llmModel);

  // Workspace state
  const isPowerModeEnabled =
    workspaceLicenseType === 'PROFESSIONAL' ||
    workspaceLicenseType === 'ENTERPRISE';

  // Chat state
  const [isMessageLimitAlertOpen, setIsMessageLimitAlertOpen] =
    useState<boolean>(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [chatMessages, setChatMessages] = useState<
    // <msgId, message>
    Map<string, ChatMessageType>
  >(new Map());
  const [messageInputString, setMessageInputString] = useState('');
  const [lastSentMessageType, setLastSentMessageType] = useState<
    'USER' | 'USER_DOCUMENT' | 'USER_DOCUMENT_POWER'
  >('USER');
  const [error, setError] = useState('');
  const [nextMessageLoading, setNextMessageLoading] = useState(false);
  const [nextMessageStreaming, setNextMessageStreaming] = useState(false);
  const [chatInputSelected, setChatInputSelected] = useState(false);
  const [attachScrollToBottom, setAttachScrollToBottom] = useState(true);
  const [showSelectFilesWarning, setShowSelectFilesWarning] = useState(false);

  // Collection state
  const [collectionNames, setCollectionNames] = useState(
    new Map(extendedBuckets.map((bucket) => [bucket.id, bucket.display_name]))
  );

  // Refs
  const idleTimerRef = useRef<NodeJS.Timeout>();
  const thingsHappeningRef = useRef(true);
  const messageScrollRef = useRef<HTMLDivElement>(null);
  const streamingMessageRef = useRef('');
  const tempSnippet = useRef<MaiaChatSnippets>();
  const tempGlossaryUsed = useRef<MaiaChatGlossaryUsed>();

  /* ---------------------------------------------------------------- File Selection Logic ---------------------------------------------------------------- */

  // Sharepoint file selection
  const sharepointSelectionKey = `${SHAREPOINT_CONTEXT_LOCAL_STORAGE_KEY}_${chatId}`;
  const persistedSharepointContext = localStorage.getItem(
    sharepointSelectionKey
  );

  let parsedSharepointContext: StorageFileSelectionMap | undefined = undefined;
  try {
    parsedSharepointContext = JSON.parse(persistedSharepointContext ?? '{}');
  } catch (e) {
    localStorage.removeItem(sharepointSelectionKey);
    captureException(e, { level: 'debug' });
  }

  const [selectedSharepointFileMap, setSelectedSharepointFileMap] =
    useState<SharepointFileSelectionMap>(parsedSharepointContext ?? {});
  const sharepointSelectionRef = useRef<SharepointFileSelectionMap>({});

  // Storage file selection
  const storageSelectionKey = `${FILE_CONTEXT_LOCAL_STORAGE_KEY}_${chatId}`;
  const persistedFileContext = localStorage.getItem(storageSelectionKey);

  let parsedFileContext: StorageFileSelectionMap | undefined = undefined;
  try {
    parsedFileContext = JSON.parse(persistedFileContext ?? '{}');
  } catch (e) {
    localStorage.removeItem(storageSelectionKey);
    captureException(e, { level: 'debug' });
  }

  const [selectedStorageFileMap, setSelectedStorageFileMap] =
    useState<StorageFileSelectionMap>(parsedFileContext ?? {});
  const storageSelectionRef = useRef<StorageFileSelectionMap>({});

  // Storage selection handlers
  const storageSelectionStateHandlers = useStorageSelectionStateHandlers(
    (file) => {
      if (file.objectType === 'tempFile') {
        return false;
      }
      return isFileSelectable({
        csvEnabled: csv,
        embeddingStatus: file.embeddingStatus,
        mimeType: file.metadata.mimetype,
      });
    },
    setSelectedStorageFileMap,
    selectedStorageFileMap
  );

  // SharePoint selection handlers
  const sharepointSelectionStateHandlers = useSharepointSelectionStateHandlers(
    setSelectedSharepointFileMap
  );

  // Confluence
  const confluenceSelectionKey = `${CONFLUENCE_CONTEXT_LOCAL_STORAGE_KEY}_${chatId}`;
  const persistedConfluenceContext = localStorage.getItem(
    confluenceSelectionKey
  );

  let parsedConfluenceContext: ConfluenceFileSelectionMap | undefined =
    undefined;
  try {
    parsedConfluenceContext = JSON.parse(persistedConfluenceContext ?? '{}');
  } catch (e) {
    localStorage.removeItem(confluenceSelectionKey);
    captureException(e, { level: 'debug' });
  }

  const [selectedConfluenceFileMap, setSelectedConfluenceFileMap] =
    useState<ConfluenceFileSelectionMap>(parsedConfluenceContext ?? {});
  const confluenceSelectionRef = useRef<ConfluenceFileSelectionMap>({});
  const confluenceSelectionStateHandlers = useConfluenceSelectionStateHandlers(
    setSelectedConfluenceFileMap
  );

  /* ---------------------------------------------------------------- Init socket ---------------------------------------------------------------- */
  const { isConnected, lastMessage, sendMessage } = useSocketIo<
    ChatClient,
    ChatServer
  >({
    socket: chatSocket,
    onConnect: () => {
      setError('');

      if (chatMessages.size === 0 && accessToken) {
        const getPastMessagesRequest: GetPastMessages = {
          amount: 100,
          type: 'GET_PAST_MESSAGES',
          chatId,
          msgId: '',
          workspaceId,
          token: accessToken,
        };
        sendMessage(getPastMessagesRequest);
      } else if (chatMessages.size === 0) {
        console.error(
          'Connected to chat socket and tried to fetch past messages, but accessToken was not set'
        );
      }
    },
    onReconnectStop: () => {
      setError(t('general.reloadError'));
    },
  });

  useEffect(() => {
    thingsHappeningRef.current =
      chatLoading || nextMessageLoading || nextMessageStreaming;
  }, [chatLoading, nextMessageLoading, nextMessageStreaming]);

  useEffect(() => {
    // Disconnect on idle, reconnect on interaction
    function resetTimerAndReconnect() {
      clearTimeout(idleTimerRef.current);
      idleTimerRef.current = setTimeout(() => {
        if (thingsHappeningRef.current) {
          resetTimerAndReconnect();
        } else {
          chatSocket.disconnect();
        }
      }, 120000); // 2 minutes
      if (chatSocket.disconnected) {
        chatSocket.connect();
      }
    }

    document.addEventListener('click', resetTimerAndReconnect);
    document.addEventListener('keypress', resetTimerAndReconnect);

    resetTimerAndReconnect();

    return () => {
      document.removeEventListener('click', resetTimerAndReconnect);
      document.removeEventListener('keypress', resetTimerAndReconnect);
      clearTimeout(idleTimerRef.current);
    };
  }, []);

  useEffect(() => {
    setChatLoading(true);

    return () => {
      const storageSelectionKey = `${FILE_CONTEXT_LOCAL_STORAGE_KEY}_${chatId}`;
      const sharepointSelectionKey = `${SHAREPOINT_CONTEXT_LOCAL_STORAGE_KEY}_${chatId}`;

      if (Object.keys(storageSelectionRef.current).length) {
        localStorage.setItem(
          storageSelectionKey,
          JSON.stringify(storageSelectionRef.current)
        );
      }
      if (Object.keys(sharepointSelectionRef.current).length) {
        localStorage.setItem(
          sharepointSelectionKey,
          JSON.stringify(sharepointSelectionRef.current)
        );
      }
      if (Object.keys(confluenceSelectionRef.current).length) {
        localStorage.setItem(
          confluenceSelectionKey,
          JSON.stringify(confluenceSelectionRef.current)
        );
      }
    };
  }, [chatId]);

  useEffect(() => {
    storageSelectionRef.current = selectedStorageFileMap;
    sharepointSelectionRef.current = selectedSharepointFileMap;
    confluenceSelectionRef.current = selectedConfluenceFileMap;
  }, [selectedStorageFileMap, selectedSharepointFileMap]);

  /* ---------------------------------------------------------------- Handle incoming websocket msgs ---------------------------------------------------------------- */
  useEffect(() => {
    if (lastMessage) {
      switch (lastMessage.type) {
        case 'USER':
        case 'MAIA':
        case 'MAIA_DOCUMENT_POWER':
        case 'USER_DOCUMENT_POWER':
        case 'MAIA_DOCUMENT':
        case 'USER_DOCUMENT': {
          setChatMessages((prev) => {
            prev.set(lastMessage.msgId, lastMessage);
            return new Map(prev);
          });
          break;
        }
        case 'NO_PAST_MESSAGES': {
          setChatLoading(false);
          break;
        }
        case 'MAIA_DOCUMENT_SNIPPETS': {
          setChatMessages((prev) => {
            const maiaDoc = prev.get(lastMessage.msgId);
            if (
              maiaDoc?.type === 'MAIA_DOCUMENT' ||
              maiaDoc?.type === 'MAIA_DOCUMENT_POWER'
            ) {
              maiaDoc.metadata = lastMessage.snippets;
              if (maiaDoc.type === 'MAIA_DOCUMENT_POWER') {
                maiaDoc.wholeFiles = lastMessage.wholeFiles;
                maiaDoc.fileSummaries = lastMessage.fileSummaries;
              }
              prev.set(lastMessage.msgId, maiaDoc);
            } else {
              tempSnippet.current = lastMessage;
            }
            return new Map(prev);
          });
          break;
        }
        case 'MAIA_GLOSSARY_USED': {
          setChatMessages((prev) => {
            const referencedMessage = prev.get(lastMessage.msgId);
            if (
              referencedMessage?.type === 'MAIA' ||
              referencedMessage?.type === 'MAIA_DOCUMENT' ||
              referencedMessage?.type === 'MAIA_DOCUMENT_POWER'
            ) {
              referencedMessage.glossaryUsed = lastMessage.used;
              prev.set(lastMessage.msgId, referencedMessage);
            } else {
              tempGlossaryUsed.current = lastMessage;
            }
            return new Map(prev);
          });
          break;
        }
        case 'MAIA_STREAM': {
          if (!nextMessageStreaming) {
            setNextMessageLoading(false);
            setNextMessageStreaming(true);
          }
          streamingMessageRef.current = `${streamingMessageRef.current}${lastMessage.msg}`;
          break;
        }
        case 'MAIA_SEARCH_IN_FILES_WARNING': {
          setShowSelectFilesWarning(true);
          setTimeout(() => setShowSelectFilesWarning(false), 2000);
          break;
        }
        // Fallthrough is intended here
        // @ts-expect-error
        case 'MAIA_STREAM_ERROR': {
          switch (lastMessage.code) {
            case 520: {
              toaster.create({
                title: t('general.socket.genericStreamError'),
                type: 'error',
              });
              break;
            }
            case 521: {
              toaster.create({
                title: t('general.socket.messageTooLongError'),
                type: 'error',
              });
              break;
            }
            case 522: {
              toaster.create({
                title: t('general.socket.contentFilterError'),
                type: 'error',
              });
              break;
            }
            case 523: {
              toaster.create({
                title: t('general.socket.powerModeNotAllowedError'),
                type: 'error',
              });
              break;
            }
            case 524: {
              toaster.create({
                title: t('general.socket.powerModeJsonError'),
                type: 'error',
              });
              break;
            }
          }
        }
        case 'MAIA_STREAM_END': {
          setNextMessageLoading(false);
          const aborted =
            lastMessage.type === 'MAIA_STREAM_END' && !lastMessage.end;
          const tempMessageContent = streamingMessageRef.current;
          streamingMessageRef.current = '';
          setNextMessageStreaming(false);
          setChatMessages((prev) => {
            if (lastSentMessageType === 'USER_DOCUMENT') {
              prev.set(lastMessage.msgId, {
                chatId,
                createdAt: new Date().getTime(),
                msg: tempMessageContent,
                msgId: lastMessage.msgId,
                type: 'MAIA_DOCUMENT',
                metadata:
                  tempSnippet.current?.msgId === lastMessage.msgId &&
                  tempSnippet.current
                    ? tempSnippet.current.snippets
                    : {
                        type: 'items',
                        items: [],
                      },

                glossaryUsed:
                  tempGlossaryUsed.current?.msgId === lastMessage.msgId &&
                  tempGlossaryUsed.current.used,
                aborted,
              });
            } else if (lastSentMessageType === 'USER_DOCUMENT_POWER') {
              prev.set(lastMessage.msgId, {
                chatId,
                createdAt: new Date().getTime(),
                msg: tempMessageContent,
                msgId: lastMessage.msgId,
                type: 'MAIA_DOCUMENT_POWER',
                metadata:
                  tempSnippet.current?.msgId === lastMessage.msgId &&
                  tempSnippet.current
                    ? tempSnippet.current.snippets
                    : {
                        type: 'items',
                        items: [],
                      },
                wholeFiles:
                  tempSnippet.current?.msgId === lastMessage.msgId &&
                  tempSnippet.current
                    ? tempSnippet.current.wholeFiles
                    : [],
                fileSummaries:
                  tempSnippet.current?.msgId === lastMessage.msgId &&
                  tempSnippet.current
                    ? tempSnippet.current.fileSummaries
                    : [],
                glossaryUsed:
                  tempGlossaryUsed.current?.msgId === lastMessage.msgId &&
                  tempGlossaryUsed.current.used,
                aborted,
              });
            } else {
              // It can happen that the backend is throwing an error but the stream will succeed anyways
              // in this case we do not want to delete the message we want to display everything which made it.
              const possibleMessage = prev.get(lastMessage.msgId);
              prev.set(lastMessage.msgId, {
                chatId,
                createdAt: new Date().getTime(),
                msg: possibleMessage?.msg
                  ? possibleMessage.msg
                  : tempMessageContent,
                msgId: lastMessage.msgId,
                type: 'MAIA',
                glossaryUsed:
                  tempGlossaryUsed.current?.msgId === lastMessage.msgId &&
                  tempGlossaryUsed.current.used,
                aborted,
              });
            }

            if (prev.size === 2) {
              onFirstAnswerReceived();
            }
            return new Map(prev);
          });

          break;
        }
        case 'MAIA_SPAM_PROTECTION_ERROR': {
          setNextMessageLoading(false);
          toaster.create({
            title: (
              <p>
                <span className="font-bold">
                  {t('general.spamProtectionHeader')}
                </span>
                <br />
                <span className="font-semibold">
                  {t('general.spamProtectionDescription')}
                </span>
              </p>
            ),
            type: 'error',
          });
          break;
        }
        case 'MAIA_ABORTED': {
          setNextMessageLoading(false);
          setNextMessageStreaming(false);
          break;
        }
        case 'MAIA_DB_ERROR':
        case 'WS_ERROR': {
          toaster.create({
            title: t('general.socket.webSocketError'),
            type: 'error',
          });
          setNextMessageLoading(false);
          setNextMessageStreaming(false);
          break;
        }
        case 'MAIA_CHAT_DOES_NOT_EXIST_ERROR': {
          toaster.create({
            title: t('general.socket.chatDoesNotExistError'),
            type: 'warning',
          });
          setNextMessageLoading(false);
          setNextMessageStreaming(false);
          break;
        }
        case 'MAIA_INACTIVE_WORKSPACE_ERROR': {
          toaster.create({
            type: 'error',
            title: t('general.workspaceInactiveHint'),
          });
          setNextMessageLoading(false);
          setNextMessageStreaming(false);
          break;
        }
        case 'MAIA_NO_FILES_SELECTED_ERROR': {
          // This error occurs when a MAIA document query is attempted but none of the selection maps actually contained any files

          toaster.create({
            type: 'info',
            title: t('chat.noFilesDocumentModeError.title'),
            description: t('chat.noFilesDocumentModeError.description'),
            duration: 7000,
          });
          storageSelectionStateHandlers.clearSelectedFileMap();
          sharepointSelectionStateHandlers.clearSelectedFileMap();
          confluenceSelectionStateHandlers.clearSelectedFileMap();
          setNextMessageLoading(false);
          setNextMessageStreaming(false);
          setChatMessages((prev) => {
            const messageArray = Array.from(prev);
            const lastMessage = messageArray[messageArray.length - 1];
            // Should exist, because user has just sent a message that resulted in this error
            if (lastMessage) {
              const jsonMessage: GetAnswer = {
                type: 'MAIA_QUERY',
                chatId,
                msg: lastMessage[1].msg,
                // In case of experimental models, use 4o as goto model
                model: ![
                  AvailableModelEnum.GPT_4_O_MINI,
                  AvailableModelEnum.GPT_4_O,
                ].includes(model)
                  ? AvailableModelEnum.GPT_4_O
                  : (model as Extract<
                      AvailableModelEnum,
                      | AvailableModelEnum.GPT_4_O
                      | AvailableModelEnum.GPT_4_O_MINI
                    >),
                msgId: lastMessage[0],
                token: accessToken!,
                workspaceId,
                answerGenerationModel: ![
                  AvailableModelEnum.GPT_4_O_MINI,
                  AvailableModelEnum.GPT_4_O,
                ].includes(model)
                  ? (model as AnswerGenerationModels)
                  : undefined,
              };
              sendMessage(jsonMessage);

              prev.set(lastMessage[0], { ...lastMessage[1], type: 'USER' });
            }

            return new Map(prev);
          });
          setLastSentMessageType('USER');
          setNextMessageLoading(true);

          break;
        }
        case 'CSV_QUERY_GENERATED':
        case 'POWER_MODE_FILE_INFO_OVERFLOW':
        case 'POWER_MODE_SNIPPET_CONTEXT_CUTOFF':
        case 'POWER_MODE_WHOLE_FILE_DISCARDED':
        case 'POWER_MODE_PART_OF_DOC_DISCARDED':
        case 'POWER_MODE_DOC_SUMMARY_DISCARDED':
        case 'POWER_MODE_CSV_RESULT_DISCARDED':
        case 'POWER_MODE_EXTRACTIONS_STARTED':
          // Do nothing, handled in PowerModeLogs
          break;
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    if (chatMessages) {
      setChatLoading(false);
    }
  }, [chatMessages]);

  useLayoutEffect(() => {
    if (messageScrollRef.current && attachScrollToBottom) {
      messageScrollRef.current.scrollTo(
        0,
        messageScrollRef.current.scrollHeight
      );
    }
  }, [chatMessages, streamingMessageRef.current]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (messageScrollRef.current && attachScrollToBottom) {
        messageScrollRef.current.scrollTo(
          0,
          messageScrollRef.current.scrollHeight
        );
      }
    });
    if (messageScrollRef.current) {
      resizeObserver.observe(messageScrollRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [messageScrollRef.current, attachScrollToBottom]);

  /* ---------------------------------------------------------------- Send Message ---------------------------------------------------------------- */
  function handleSendChatMessage() {
    if (
      workspaceLicenseType === 'FREE' &&
      sentRequests >= FREE_USER_REQUEST_LIMIT
    ) {
      setIsMessageLimitAlertOpen(true);
      return;
    }
    setNextMessageLoading(true);
    const msgId = v4();
    /* ------------------------------------------------ Doc message ------------------------------------------------ */
    const sharepointMap = cleanUpFileSelectionMap(
      selectedSharepointFileMap,
      SHAREPOINT_SELECTION_MAP_PATH_DELIMITER
    );
    const cleanedStorageMap = cleanUpFileSelectionMap(
      selectedStorageFileMap,
      STORAGE_SELECTION_MAP_PATH_DELIMITER
    );

    const cleanedConfluenceMap = cleanUpFileSelectionMap(
      selectedConfluenceFileMap,
      CONFLUENCE_SELECTION_MAP_PATH_DELIMITER
    );
    const selectedModel = model;

    if (
      Object.keys(selectedStorageFileMap).length ||
      Object.keys(sharepointMap).length ||
      Object.keys(cleanedConfluenceMap).length
    ) {
      const jsonMessage: GetChatDocumentAnswer | GetChatDocumentPowerAnswer = {
        chatId,
        selectedStorageFileMap: cleanedStorageMap,
        selectedSharepointFileMap: sharepointMap,
        confluenceSelection: cleanedConfluenceMap,
        msg: messageInputString,
        msgId,
        // In case of power mode or experimental models, use 4o as goto model
        model:
          isPowerModeEnabled ||
          ![
            AvailableModelEnum.GPT_4_O_MINI,
            AvailableModelEnum.GPT_4_O,
          ].includes(model)
            ? AvailableModelEnum.GPT_4_O
            : (selectedModel as Extract<
                AvailableModelEnum,
                AvailableModelEnum.GPT_4_O | AvailableModelEnum.GPT_4_O_MINI
              >),
        type: isPowerModeEnabled
          ? 'MAIA_DOCUMENT_POWER_QUERY'
          : 'MAIA_DOCUMENT_QUERY',
        token: accessToken!,
        workspaceId,
        answerGenerationModel: ![
          AvailableModelEnum.GPT_4_O_MINI,
          AvailableModelEnum.GPT_4_O,
        ].includes(model)
          ? (selectedModel as AnswerGenerationModels)
          : undefined,
      };
      sendMessage(jsonMessage);
      setChatMessages((prev) => {
        prev.set(msgId, {
          type: isPowerModeEnabled ? 'USER_DOCUMENT_POWER' : 'USER_DOCUMENT',
          chatId,
          createdAt: new Date().getTime(),
          msg: messageInputString,
          msgId,
        });
        return new Map(prev);
      });
      setLastSentMessageType(
        isPowerModeEnabled ? 'USER_DOCUMENT_POWER' : 'USER_DOCUMENT'
      );
      /* ------------------------------------------------ Regular msg ------------------------------------------------ */
    } else {
      const jsonMessage: GetAnswer = {
        type: 'MAIA_QUERY',
        chatId,
        msg: messageInputString,
        // In case of experimental models, use 4o as goto model
        model: ![
          AvailableModelEnum.GPT_4_O_MINI,
          AvailableModelEnum.GPT_4_O,
        ].includes(model)
          ? AvailableModelEnum.GPT_4_O
          : (selectedModel as Extract<
              AvailableModelEnum,
              AvailableModelEnum.GPT_4_O | AvailableModelEnum.GPT_4_O_MINI
            >),
        msgId,
        token: accessToken!,
        workspaceId,
        answerGenerationModel: ![
          AvailableModelEnum.GPT_4_O_MINI,
          AvailableModelEnum.GPT_4_O,
        ].includes(model)
          ? (selectedModel as AnswerGenerationModels)
          : undefined,
      };
      sendMessage(jsonMessage);
      setChatMessages((prev) => {
        prev.set(msgId, {
          type: 'USER',
          chatId,
          createdAt: new Date().getTime(),
          msg: messageInputString,
          msgId,
        });
        return new Map(prev);
      });
      setLastSentMessageType('USER');
    }
    optimisticUpdateSentRequests(1);
  }

  function handleAbortAnswerGeneration() {
    if (isConnected) {
      const jsonMessage: MaiaStreamAbort = {
        type: 'USER_ABORT',
        token: accessToken!,
        workspaceId,
      };
      sendMessage(jsonMessage);
    }
  }

  /* ---------------------------------------------------------------- Construct Message elements ---------------------------------------------------------------- */
  const messageElements = useMemo(() => {
    const tempMessageElements: ReactElement[] = [];

    const currentMessageGroup: {
      elements: ReactElement[];
      mode: 'POWER' | 'NORMAL' | undefined;
    } = { elements: [], mode: 'NORMAL' };
    let docRelatedMessageGroupOpen = false;
    Array.from(chatMessages.values())
      ?.sort((messageA, messageB) => messageA.createdAt - messageB.createdAt)
      .forEach((message) => {
        docRelatedMessageGroupOpen = message.type.includes('DOCUMENT');
        const currentMode = message.type.includes('POWER') ? 'POWER' : 'NORMAL';
        if (docRelatedMessageGroupOpen) {
          if (
            currentMessageGroup.mode &&
            currentMessageGroup.mode !== currentMode &&
            currentMessageGroup.elements.length > 0
          ) {
            tempMessageElements.push(
              <MaiaMessageGroup mode={currentMessageGroup.mode}>
                {...currentMessageGroup.elements}
              </MaiaMessageGroup>
            );
            currentMessageGroup.elements = [];
          }
          currentMessageGroup.mode = currentMode;
          currentMessageGroup.elements.push(
            <ChatMessage
              key={message.msgId}
              msg={message}
              userIsMarker={userIsMarker}
            />
          );
        } else {
          if (currentMessageGroup.elements.length > 0) {
            tempMessageElements.push(
              <MaiaMessageGroup mode={currentMessageGroup.mode ?? 'NORMAL'}>
                {...currentMessageGroup.elements}
              </MaiaMessageGroup>
            );
            currentMessageGroup.elements = [];
            currentMessageGroup.mode = undefined;
          }
          tempMessageElements.push(
            <ChatMessage
              key={message.msgId}
              msg={message}
              userIsMarker={userIsMarker}
            />
          );
        }
      });
    if (docRelatedMessageGroupOpen) {
      tempMessageElements.push(
        <MaiaMessageGroup mode={currentMessageGroup.mode ?? 'NORMAL'}>
          {...currentMessageGroup.elements}
          {nextMessageStreaming ? (
            <StreamingChatMessage
              streamingMessageContentRef={streamingMessageRef}
              msg={{
                chatId: '',
                createdAt: 0,
                type: 'MAIA',
                msg: '',
                msgId: '',
                aborted: false,
              }}
              userIsMarker={userIsMarker}
            />
          ) : nextMessageLoading ? (
            <MessageLoadingIndicator
              chatInputSelected={chatInputSelected}
              handleAbortAnswerGeneration={handleAbortAnswerGeneration}
              powerMode={currentMessageGroup.mode === 'POWER'}
            />
          ) : null}
        </MaiaMessageGroup>
      );
    } else {
      tempMessageElements.push(
        <>
          {nextMessageStreaming ? (
            <StreamingChatMessage
              streamingMessageContentRef={streamingMessageRef}
              msg={{
                chatId: '',
                createdAt: 0,
                type: 'MAIA',
                msg: '',
                msgId: '',
                aborted: false,
              }}
              onKeyUp={(event) =>
                event.key === 'Escape' && handleAbortAnswerGeneration()
              }
              userIsMarker={userIsMarker}
            />
          ) : nextMessageLoading ? (
            <MessageLoadingIndicator
              chatInputSelected={chatInputSelected}
              handleAbortAnswerGeneration={handleAbortAnswerGeneration}
            />
          ) : null}
        </>
      );
    }

    return tempMessageElements;
  }, [
    nextMessageStreaming,
    nextMessageLoading,
    chatMessages,
    document.activeElement,
  ]);

  useEffect(() => {
    setCollectionNames(
      new Map(
        extendedBuckets.map((bucket) => {
          return [bucket.id, bucket.display_name];
        })
      )
    );
  }, [extendedBuckets]);

  // const handleClearAllFiles = () => {
  //   setSelectedStorageFileMap({});
  //   setSelectedSharepointFileMap({});
  // };

  const chatInput = useMemo(
    () => (
      <ChatInput
        answerOnTheWay={nextMessageLoading || nextMessageStreaming}
        onInputValueChange={(value) => setMessageInputString(value)}
        selectedStorageFileMap={selectedStorageFileMap}
        collectionNames={collectionNames}
        inputValue={messageInputString}
        showSelectFilesWaring={showSelectFilesWarning}
        onSend={() => {
          handleSendChatMessage();
          setMessageInputString('');
        }}
        onFocus={() => setChatInputSelected(true)}
        onBlur={() => setChatInputSelected(false)}
        onEsc={handleAbortAnswerGeneration}
        selectedSharepointFileMap={selectedSharepointFileMap}
        onUpdateSelectedSharepointFileMap={setSelectedSharepointFileMap}
        onUpdateSelectedStorageFileMap={setSelectedStorageFileMap}
        storageSelectionStateHandlers={storageSelectionStateHandlers}
        sharePointSelectionStateHandlers={sharepointSelectionStateHandlers}
        selectedConfluenceFileMap={selectedConfluenceFileMap}
        confluenceSelectionStateHandlers={confluenceSelectionStateHandlers}
      />
    ),
    [
      nextMessageLoading,
      nextMessageStreaming,
      collectionNames,
      messageInputString,
      showSelectFilesWarning,
      selectedSharepointFileMap,
      selectedStorageFileMap,
      selectedConfluenceFileMap,
      confluenceSelectionStateHandlers,
    ]
  );

  const alertButton = useMemo(
    () => (
      <MaiaAlertDialogWithUpgradeButton
        isOpen={isMessageLimitAlertOpen}
        onClose={() => {
          setIsMessageLimitAlertOpen(false);
        }}
        AlertDialogHeaderText={t('searchPanel.messageLimitReached')}
        AlertDialogBodyText={t('searchPanel.messageLimitReachedBody')}
      />
    ),
    [isMessageLimitAlertOpen]
  );

  return (
    <>
      <div className="flex h-screen flex-col">
        {/* Scrollable Chat Container */}
        <div
          className="scrollable-hidden-scrollbar flex-grow"
          ref={messageScrollRef}
          onScroll={(e) => {
            if (
              (e.target as HTMLDivElement).scrollTop +
                (e.target as HTMLDivElement).clientHeight +
                10 >
              (e.target as HTMLDivElement).scrollHeight
            ) {
              setAttachScrollToBottom(true);
            } else {
              setAttachScrollToBottom(false);
            }
          }}
        >
          <div className="grid h-full grid-cols-11 gap-4">
            <div className="col-span-9 col-start-2 flex xl:col-span-7 xl:col-start-3">
              {/* Scrollable Chat Content */}
              <div className="min-w-0 flex-grow py-4">
                {/* Conditional rendering for empty chats or message list */}
                {chatMessages.size === 0 ? (
                  <div className="flex h-full flex-col items-center justify-center">
                    <LogoPure className="fill-maia-gray-300" size={55} />
                    <div className="text-maia-gray-300 mt-9 text-center text-3xl font-bold">
                      {t('chat.emptyChatCatchphrase')}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col gap-4">
                    {...messageElements}
                    {error ? (
                      <div className="text-maia-support-red self-center">
                        {error}
                      </div>
                    ) : null}
                    {chatLoading ? (
                      <>
                        <Skeleton className="h-20 w-2/3 self-end" />
                        <Skeleton className="h-20 w-2/3 self-start" />
                        <Skeleton className="h-20 w-2/3 self-end" />
                        <Skeleton className="h-20 w-2/3 self-start" />
                        <Skeleton className="h-20 w-2/3 self-end" />
                      </>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Chat Input - Always Visible at the Bottom */}
        <div className="grid grid-cols-11 gap-4 pb-4">
          <div className="col-span-9 col-start-2 xl:col-span-7 xl:col-start-3">
            {chatInput}
            <div className="text-maia-text-dark mt-2 text-center text-xs text-opacity-75">
              {t('chat.maiaAssistantNotice')}
            </div>
          </div>
          <ChatRequestLimitToaster />
          {import.meta.env.VITE_MAIA_STAGE === 'prod' ? null : (
            <PowerModeLogs
              className="absolute right-0 top-0 max-h-screen max-w-md"
              latestMessage={lastMessage}
            />
          )}
          {alertButton}
        </div>
      </div>
    </>
  );
}

export default Chat;
