import {
  DetailedHTMLProps,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  IconButton,
  Input,
  MenuContent,
  MenuItem,
  MenuPositioner,
  MenuRoot,
} from '@chakra-ui/react';

import { Button } from './ui/button.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItem from './ListItem.js';
import { MenuTrigger } from './ui/menu.js';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { captureException } from '@sentry/react';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { toaster } from './ui/toaster.js';
import { useTranslation } from 'react-i18next';

type HistoryItemProps = {
  chatId: string;
  selected: boolean;
  chatTopic: string;
  onRename: (name: string) => Promise<PostgrestSingleResponse<null>>;
  domElementProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  onDeleteClick: () => void;
};

function HistoryItem({
  selected,
  chatTopic,
  domElementProps,
  onRename,
  onDeleteClick,
}: HistoryItemProps) {
  const { t } = useTranslation();

  const renamingDivRef = useRef<HTMLDivElement>(null);

  const [topic, setTopic] = useState(chatTopic);

  useEffect(() => {
    setTopic(chatTopic);
  }, [chatTopic]);

  const [renaming, setRenaming] = useState(false);
  const newNameRef = useRef('');

  async function handleChatRename() {
    // TODO think about where to set the topic like cache ...
    if (!newNameRef.current) {
      newNameRef.current = topic ?? '';
      return;
    }
    const error = await onRename(newNameRef.current);

    if (error.error !== null) {
      captureException(error.error);
      toaster.create({ title: t('general.tryAgainError'), type: 'error' });
      return;
    }

    setTopic(newNameRef.current);
  }

  return (
    <ListItem
      className={`group w-full flex-shrink-0 justify-between ${
        renaming && 'h-auto py-1.5'
      }`}
      variant="left"
      selected={selected}
      domElementProps={domElementProps}
    >
      {renaming ? (
        <div className="flex flex-col gap-2.5" ref={renamingDivRef}>
          <Input
            autoFocus
            onFocus={(e) => {
              e.target.select();
            }}
            className="bg-white"
            defaultValue={topic}
            onChange={(e) => {
              newNameRef.current = e.target.value;
            }}
            onBlur={(e) => {
              if (!renamingDivRef.current?.contains(e.relatedTarget)) {
                handleChatRename();
                setRenaming(false);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleChatRename();
                setRenaming(false);
              }
            }}
          />
          <div className="flex items-center gap-2">
            <Button
              className="font-medium"
              variant="outline"
              size="xs"
              onClick={(e) => {
                setRenaming(false);
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {t('general.cancelButton')}
            </Button>
            <Button
              className="bg-maia-accent text-maia-text-light font-medium"
              size="xs"
              onClick={(e) => {
                handleChatRename();
                setRenaming(false);
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {t('general.saveButtons')}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="overflow-hidden text-ellipsis whitespace-nowrap">
            {topic}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            className="z-10"
          >
            <MenuRoot positioning={{ placement: 'bottom-end' }}>
              <MenuTrigger>
                <IconButton
                  size={'sm'}
                  variant="ghost"
                  className={`md:opacity-0 md:group-hover:opacity-100`}
                  aria-label="chat settings"
                >
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </IconButton>
              </MenuTrigger>
              <MenuPositioner>
                <MenuContent className="min-w-[130px]">
                  <MenuItem value="delete" onClick={onDeleteClick}>
                    {t('general.deleteButton')}
                  </MenuItem>
                  <MenuItem value="rename" onClick={() => setRenaming(true)}>
                    {t('general.renameButtons')}
                  </MenuItem>
                </MenuContent>
              </MenuPositioner>
            </MenuRoot>
          </div>
        </>
      )}
    </ListItem>
  );
}

export default HistoryItem;
