import { ROUTES } from 'common-ts';
import ReactMarkdown from 'react-markdown';
import { Ref } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { useApi } from '@/utils/useApi';
import { useBoundStore } from '@/store/useBoundStore';
import { useTypedParams } from 'react-router-typesafe-routes';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

function DocumentDebugView() {
  const { workspaceId, id } = useTypedParams(ROUTES.HOME.DOCUMENT_DEBUG_VIEW);
  const supabase = useBoundStore((state) => state.supabase);

  const { data } = useApi(supabase, '/files', '/layout_view', {
    method: 'POST',
    id,
    workspaceId,
  });

  return (
    <div className="my-10 flex h-full w-full justify-center">
      <ReactMarkdown
        className="prose min-w-0 max-w-5xl text-sm font-medium"
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw as any]}
        components={{
          code(props) {
            const { children, className, ref, ...rest } = props;
            const match = /language-(\w+)/.exec(className || '');
            return match ? (
              <SyntaxHighlighter
                ref={ref as Ref<SyntaxHighlighter>}
                {...rest}
                children={String(children).replace(/\n$/, '')}
                style={vscDarkPlus}
                language={match[1]}
                PreTag="div"
              />
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            );
          },
          a(props) {
            // open links in new tab
            return <a {...props} target="_blank" rel="noreferrer" />;
          },
        }}
      >
        {data?.fullMarkdown}
      </ReactMarkdown>
    </div>
  );
}

export default DocumentDebugView;
