import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ROUTES } from 'common-ts';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { useBoundStore } from '../../store/useBoundStore';
import { useTranslation } from 'react-i18next';

export default function SubscriptionUpdateBanner() {
  const userTier = useBoundStore((state) => state.workspaceLicenseType);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const { t } = useTranslation();

  if (userTier === 'FREE') {
    return (
      <div className="from-maia-brand-purple to-maia-brand-blue flex w-full items-center gap-4 bg-gradient-to-r px-8 py-2 text-white">
        <FontAwesomeIcon icon={faInfoCircle} className="text-2xl" />
        <div className="flex flex-grow justify-center">
          <div className="flex flex-col">
            <span className="text-wrap">
              {t('general.subscriptionUpdate.freeUser')}
            </span>
            <span className="text-xs font-medium">
              {t('general.subscriptionUpdate.pricingRedirect')}{' '}
              <Link
                className="underline"
                to={ROUTES.HOME.PRICING.$buildPath({ params: { workspaceId } })}
              >
                {t('general.subscriptionUpdate.pricingRedirectLink')}
              </Link>
            </span>
          </div>
        </div>
      </div>
    );
  }

  return null;
}
