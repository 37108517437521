import { TFunction } from 'i18next';

/**
 * Gives back a language agnostic string with the time passed since the given timestamp
 *
 * @param timestamp A timestamp string
 * @returns formatted language agnostic string
 */
export function timeAgo(
  timestamp: string,
  t: TFunction<['translation', ...never[]], undefined>
): string {
  const now = new Date();
  const past = new Date(timestamp);
  const seconds = Math.floor((now.getTime() - past.getTime()) / 1000);

  if (seconds < 60) {
    return t('utils.seconds', { count: seconds });
  }
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return t('utils.minutes', { count: minutes });
  }
  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return t('utils.hours', { count: hours });
  }
  const days = Math.floor(hours / 24);
  if (days < 7) {
    return t('utils.days', { count: days });
  }
  const weeks = Math.floor(days / 7);
  if (weeks < 52) {
    return t('utils.weeks', { count: weeks });
  }
  const years = Math.floor(weeks / 52);
  return t('utils.years', { count: years });
}
