import {
  ConfluenceFileSelectionMap,
  SharepointFileSelectionMap,
  StorageFileSelectionMap,
} from 'common-ts';
import {
  FocusEventHandler,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IconButton, Separator, chakra } from '@chakra-ui/react';
import {
  faCabinetFiling,
  faPaperPlane,
} from '@fortawesome/pro-regular-svg-icons';

import AutoResize from 'react-textarea-autosize';
import { Button } from '@/components/ui/button';
import { ChangeLlm } from '@/components/ChangeLlm';
import { ConfluenceSelectionStateHandlers } from '../fileSelector/useConfluenceSelectionStateHandlers.js';
import DataButton from '@/pages/chat/chatInput/DataButton';
import FileSelector from '../fileSelector/FileSelector.js';
import { FileSelectorModal } from '../fileSelector/FileSelectorModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PromptLibraryModal from '../promptLibrary/PromptLibraryModal';
import SelectedDataTagList from '@/pages/chat/chatInput/SelectedDataTagList';
import { SharePointSelectionStateHandlers } from '@/pages/chat/fileSelector/useSharepointSelectionStateHandlers';
import { StorageSelectionStateHandlers } from '@/pages/chat/fileSelector/useStorageSelectionStateHandlers';
import { useTranslation } from 'react-i18next';

type ChatInputProps = {
  answerOnTheWay: boolean;
  inputValue: string;
  collectionNames: Map<string, string>;
  showSelectFilesWaring: boolean;
  selectedSharepointFileMap: SharepointFileSelectionMap;
  selectedStorageFileMap: StorageFileSelectionMap;
  selectedConfluenceFileMap: ConfluenceFileSelectionMap;
  onEsc: () => void;
  onSend: () => void;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  onInputValueChange: (value: string) => void;
  onUpdateSelectedSharepointFileMap: (
    updateValue: SetStateAction<SharepointFileSelectionMap>
  ) => void;
  onUpdateSelectedStorageFileMap: (
    updateValue: SetStateAction<StorageFileSelectionMap>
  ) => void;
  storageSelectionStateHandlers: StorageSelectionStateHandlers;
  sharePointSelectionStateHandlers: SharePointSelectionStateHandlers;
  confluenceSelectionStateHandlers: ConfluenceSelectionStateHandlers;
};

const StyledAutoResize = chakra(AutoResize);

function ChatInput({
  answerOnTheWay,
  inputValue,
  collectionNames,
  showSelectFilesWaring,
  selectedStorageFileMap,
  selectedSharepointFileMap,
  selectedConfluenceFileMap,
  onEsc,
  onSend,
  onBlur,
  onFocus,
  onInputValueChange,
  onUpdateSelectedStorageFileMap,
  onUpdateSelectedSharepointFileMap,
  storageSelectionStateHandlers,
  sharePointSelectionStateHandlers,
  confluenceSelectionStateHandlers,
}: ChatInputProps) {
  const { t } = useTranslation();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isFileSelectorModalOpen, setIsFileSelectorModalOpen] = useState(false);
  const [isPromptLibraryModalOpen, setIsPromptLibraryModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        textAreaRef.current?.focus();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const textArea = useMemo(() => {
    return (
      <StyledAutoResize
        ref={textAreaRef}
        maxLength={16384}
        value={inputValue}
        maxRows={8}
        minH="initial"
        resize="none"
        overflow="auto"
        lineHeight="inherit"
        className="text-maia-text-dark h-full w-full self-center border-none text-sm font-medium outline-none"
        placeholder={t('chat.messagePlaceholder')}
        onChange={(e) => {
          onInputValueChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (e.shiftKey) {
              return;
            }
            if (inputValue.length > 0 && !answerOnTheWay) {
              onSend();
            }
            e.preventDefault();
          } else if (e.key === 'Escape') {
            onEsc();
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus
      />
    );
  }, [inputValue, onSend, t]);

  const fileSelector = useMemo(() => {
    return (
      <FileSelector
        onUpdateSelectedStorageFileMap={onUpdateSelectedStorageFileMap}
        selectedStorageFileMap={selectedStorageFileMap}
        selectedSharepointFileMap={selectedSharepointFileMap}
        selectedConfluenceFileMap={selectedConfluenceFileMap}
        onUpdateSelectedSharepointFileMap={onUpdateSelectedSharepointFileMap}
        storageSelectionStateHandlers={storageSelectionStateHandlers}
        sharePointSelectionStateHandlers={sharePointSelectionStateHandlers}
        confluenceSelectionStateHandlers={confluenceSelectionStateHandlers}
      />
    );
  }, [
    selectedStorageFileMap,
    selectedSharepointFileMap,
    selectedConfluenceFileMap,
    confluenceSelectionStateHandlers,
  ]);

  const dataButton = useMemo(() => {
    return (
      <DataButton
        showSelectFilesWaring={showSelectFilesWaring}
        onOpenFileSelector={() => setIsFileSelectorModalOpen(true)}
        storageSelectionStateHandlers={storageSelectionStateHandlers}
      />
    );
  }, [selectedStorageFileMap]);

  const selectedFileList = useMemo(() => {
    return (
      <SelectedDataTagList
        selectedStorageFileMap={selectedStorageFileMap}
        selectedSharepointFileMap={selectedSharepointFileMap}
        selectedConfluenceFileMap={selectedConfluenceFileMap}
        collectionNames={collectionNames}
        setFileSelectorModalOpen={setIsFileSelectorModalOpen}
        storageSelectionStateHandlers={storageSelectionStateHandlers}
        sharepointSelectionStateHandlers={sharePointSelectionStateHandlers}
        confluenceSelectionStateHandlers={confluenceSelectionStateHandlers}
      />
    );
  }, [
    selectedStorageFileMap,
    collectionNames,
    selectedSharepointFileMap,
    selectedConfluenceFileMap,
  ]);

  const sendButton = useMemo(
    () => (
      <IconButton
        aria-label="send"
        size="xs"
        colorPalette="maia-accent"
        disabled={inputValue.length === 0 || answerOnTheWay}
        onClick={() => {
          onSend();
        }}
      >
        <FontAwesomeIcon icon={faPaperPlane} className="text-white" />
      </IconButton>
    ),
    [inputValue, answerOnTheWay, onSend]
  );

  const promptButton = useMemo(
    () => (
      <Button
        display="flex"
        alignItems="center"
        className="text-maia-text-dark px-0 font-medium"
        aria-label="select-prompt"
        variant="plain"
        onClick={() => {
          setIsPromptLibraryModalOpen(true);
        }}
      >
        <FontAwesomeIcon
          icon={faCabinetFiling}
          className="text-maia-text-dark"
        />
        Prompt
      </Button>
    ),
    [inputValue, answerOnTheWay, onSend]
  );

  const modelSelect = useMemo(() => <ChangeLlm />, []);

  function onClosePromptLibraryModal() {
    setIsPromptLibraryModalOpen(false);
  }

  function handleUsePrompt(prompt: string | undefined) {
    setIsPromptLibraryModalOpen(false);

    setTimeout(() => {
      textAreaRef.current?.focus();
    }, 50);
    if (!prompt) {
      return;
    }
    onInputValueChange(prompt);
  }

  return (
    <div className="border-maia-border flex w-full items-end rounded-md border bg-white shadow-md">
      <div className="flex w-full flex-col items-start px-4 py-3">
        <div className="flex w-full items-end justify-center py-2">
          {textArea}
          {sendButton}
        </div>
        {selectedFileList}
        <Separator className="w-full" />
        <div className="flex w-full justify-between pt-4">
          <div className="flex items-center gap-4">
            {dataButton}
            <Separator orientation="vertical" className="h-full" />
            {promptButton}
          </div>
          <div className="flex items-center justify-end gap-4">
            <Separator orientation="vertical" className="h-full" />
            {modelSelect}
            {/* <Separator orientation="vertical" /> */}
            {/* {helpButton} */}
          </div>
        </div>
      </div>
      <FileSelectorModal
        isOpen={isFileSelectorModalOpen}
        onClose={() => setIsFileSelectorModalOpen(false)}
        fileSelector={fileSelector}
      />
      <PromptLibraryModal
        isOpen={isPromptLibraryModalOpen}
        onClose={onClosePromptLibraryModal}
        onUsePromptClicked={(prompt) => handleUsePrompt(prompt)}
      />
    </div>
  );
}

export default ChatInput;
