import { useEffect, useRef, useState } from 'react';
import {
  DialogTitle,
  HStack,
  IconButton,
  Image,
  Separator,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException } from '@sentry/react';
import { faClone } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useBoundStore } from '@/store/useBoundStore';
import { toaster } from '@/components/ui/toaster';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { Skeleton } from '@/components/ui/skeleton';
import { Tooltip } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { PinInput } from '@/components/ui/pin-input';

/**
 * Type for TOTP Modal Props
 */
type TotpModalProps = {
  /** Controls modal visibility */
  isOpen: boolean;
  /** Callback function to handle modal close */
  onClose: () => void;
};

/**
 * Type for TOTP data structure
 */
type TotpData = {
  qr_code: string;
  secret: string;
  uri: string;
};

const COPY_TOOLTIP_TIMEOUT = 1000;
const PIN_LENGTH = 6;

/**
 * TotpModal Component - Handles 2FA setup using TOTP
 *
 * This component provides a modal interface for setting up Two-Factor Authentication
 * using Time-based One-Time Passwords (TOTP). It includes QR code scanning and
 * manual entry options for maximum compatibility.
 *
 * @param {TotpModalProps} props - Component props
 * @returns {JSX.Element} TOTP setup modal
 */
export default function TotpModal({
  isOpen,
  onClose,
}: TotpModalProps): JSX.Element {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  const [factorId, setFactorId] = useState<string>('');
  const [totpData, setTotpData] = useState<TotpData>();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [tooltipText, setTooltipText] = useState<string>(t('general.copy'));
  const [otpauthUri, setOtpauthUri] = useState<string>('');

  /**
   * Generates an otpauth URI for TOTP configuration
   * @param {string} secret - Base32 encoded secret
   */
  function generateOtpauthUri(secret: string): string {
    if (!secret || !/^[A-Z2-7]+=*$/i.test(secret)) {
      throw new Error('Invalid secret format. Must be base32 encoded.');
    }

    const params = new URLSearchParams({
      secret: secret.toUpperCase(),
      algorithm: 'SHA1',
      digits: PIN_LENGTH.toString(),
      period: '30',
    });

    return `otpauth://totp/MAIA?${params.toString()}`;
  }

  /**
   * Handles copying text to clipboard and updates tooltip
   * @param {string} text - Text to copy
   */
  function handleCopy(text: string): void {
    if (!text) return;

    navigator.clipboard.writeText(text);
    setTooltipText(t('general.copied'));
    setTimeout(() => setTooltipText(t('general.copy')), COPY_TOOLTIP_TIMEOUT);
  }

  /**
   * Initializes 2FA setup process
   */
  async function setup2FA(): Promise<void> {
    const { data, error } = await supabase.auth.mfa.enroll({
      factorType: 'totp',
      friendlyName: 'MAIA',
    });

    if (error) {
      console.error(error.message);
      return;
    }

    const { id, totp } = data;
    setFactorId(id);
    setTotpData(totp);
    setOtpauthUri(generateOtpauthUri(totp.secret));
  }

  /**
   * Cancels 2FA setup process
   */
  async function cancel2FASetup(): Promise<void> {
    if (!factorId) return;

    const { error } = await supabase.auth.mfa.unenroll({
      factorId,
    });

    if (error) {
      console.error(error.message);
    }
  }

  /**
   * Handles modal close and cleanup
   */
  async function handleModalClose(): Promise<void> {
    await cancel2FASetup();
    onClose();
  }

  /**
   * Verifies and completes 2FA setup
   */
  async function handle2FASetupConfirmation(): Promise<void> {
    const { data, error } = await supabase.auth.mfa.challengeAndVerify({
      factorId,
      code: verificationCode,
    });

    if (error || !data) {
      if (error.status === 400 || error.status === 422) {
        toaster.create({
          title: t('userSettings.security.invalidCodeError'),
          type: 'error',
        });
      } else {
        captureException(error);
        toaster.create({
          title: t('userSettings.security.totpModal.error'),
          type: 'error',
        });
        handleModalClose();
      }
      return;
    }

    toaster.create({
      title: t('userSettings.security.totpModal.success'),
      type: 'success',
    });
    onClose();
  }

  useEffect(() => {
    setup2FA();
  }, []);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => !open && handleModalClose()}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t('userSettings.security.totpModal.title')}
          </DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />

        <DialogBody className="flex w-full flex-col items-center gap-y-4">
          <div className="mb-4">
            {t('userSettings.security.totpModal.subtitle')}
          </div>

          <Skeleton loading={!totpData?.qr_code}>
            <Image
              boxSize="100px"
              src={totpData?.qr_code}
              alt="QR code for 2FA setup"
            />
          </Skeleton>

          <div className="flex w-full flex-col items-center gap-y-2">
            <span className="text-xs font-medium">
              {t(
                'userSettings.security.totpModal.enterAuthenticationCodePrompt'
              )}
            </span>
            <HStack colorPalette="maia-accent">
              <PinInput
                type="numeric"
                variant="flushed"
                onValueChange={(e) => setVerificationCode(e.value.join(''))}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handle2FASetupConfirmation();
                }}
                size="md"
                autoFocus
                otp
              />
            </HStack>
          </div>

          <div className="bg-maia-purple-100 flex w-full flex-col items-center gap-y-2 rounded-md p-4 font-medium">
            <div>
              <span className="mr-1">
                {t('userSettings.security.totpModal.scanNotWorking')}
              </span>
              <span className="font-normal">
                {t('userSettings.security.totpModal.enterManually')}
              </span>
            </div>

            <div className="flex w-full items-center gap-x-1">
              <Skeleton loading={!totpData?.secret}>
                <span>{totpData?.secret}</span>
              </Skeleton>
              {totpData?.secret && (
                <Tooltip
                  content={tooltipText}
                  closeDelay={500}
                  positioning={{ placement: 'top' }}
                >
                  <IconButton
                    variant="ghost"
                    size="xs"
                    aria-label={t('general.copy')}
                    className="hover:bg-maia-purple-200 text-maia-gray-600 hover:text-maia-gray-700"
                    onClick={() => handleCopy(totpData.secret)}
                  >
                    <FontAwesomeIcon icon={faClone} className="fill-current" />
                  </IconButton>
                </Tooltip>
              )}
            </div>

            <Separator />

            <div>
              <span className="mr-1">
                {t('userSettings.security.totpModal.backupMethodTitle')}
              </span>
              <span className="font-normal">
                {t('userSettings.security.totpModal.backupMethodDescription')}
              </span>
            </div>

            <div className="flex w-full items-center gap-x-1">
              <Skeleton className="min-w-0 flex-1" loading={!otpauthUri}>
                <span className="inline-block w-full break-all">
                  {otpauthUri}
                </span>
              </Skeleton>
              {totpData?.secret && (
                <Tooltip
                  content={tooltipText}
                  closeDelay={500}
                  positioning={{ placement: 'top' }}
                >
                  <IconButton
                    variant="ghost"
                    size="xs"
                    aria-label={t('general.copy')}
                    className="hover:bg-maia-purple-200 text-maia-gray-600 hover:text-maia-gray-700 flex-shrink-0"
                    onClick={() => handleCopy(otpauthUri)}
                  >
                    <FontAwesomeIcon icon={faClone} className="fill-current" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </DialogBody>

        <Separator />

        <DialogFooter gap={2}>
          <Button
            size="sm"
            className="font-medium"
            variant="outline"
            onClick={handleModalClose}
          >
            {t('userSettings.security.totpModal.secondaryButton')}
          </Button>
          <Button
            ref={confirmButtonRef}
            colorPalette="maia-purple"
            size="sm"
            onClick={handle2FASetupConfirmation}
            disabled={verificationCode.length < PIN_LENGTH}
          >
            {t('userSettings.security.totpModal.primaryButton')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}
