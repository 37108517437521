import { defineRecipe } from '@chakra-ui/react';

export const textAreaRecipe = defineRecipe({
  base: {
    '&:focus-visible': {
      shadow: 'none',
    },
    _selection: {
      backgroundColor: 'gray.200',
      color: 'inherit',
    },
  },
});
