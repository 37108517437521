import { Badge } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import IntegrationStatusTable from './IntegrationStatusTable';
import { ConfluenceProgress } from 'common-ts';
import { timeAgo } from '@/utils/dateFormat';
import { ConfluenceTenantLink } from './ConfluenceTenantLink';

type ConfluenceSpaceStatusTableProps = {
  spaces: ConfluenceProgress[];
};

function ConfluenceSpaceStatusTable({
  spaces,
}: ConfluenceSpaceStatusTableProps) {
  const { t } = useTranslation();

  const statusTranslationMap = {
    PENDING: t(
      'integrationSettings.confluence.connectedSpacesTable.statusBadge.pending'
    ),
    'IN PROGRESS': t(
      'integrationSettings.confluence.connectedSpacesTable.statusBadge.inProgress'
    ),
    SYNCED: t(
      'integrationSettings.confluence.connectedSpacesTable.statusBadge.synced'
    ),
    FAILED: t(
      'integrationSettings.confluence.connectedSpacesTable.statusBadge.failed'
    ),
  };

  return (
    <IntegrationStatusTable<ConfluenceProgress>
      items={spaces}
      getKey={(space) => space.spaceId}
      headerLabels={{
        name: t('integrationSettings.confluence.connectedSpacesTable.space'),
        syncedFiles: t(
          'integrationSettings.confluence.connectedSpacesTable.syncedFiles'
        ),
        totalFiles: t(
          'integrationSettings.confluence.connectedSpacesTable.totalFiles'
        ),
        usedStorage: t(
          'integrationSettings.confluence.connectedSpacesTable.usedStorage'
        ),
        status: t('integrationSettings.confluence.connectedSpacesTable.status'),
        syncedAt: t(
          'integrationSettings.confluence.connectedSpacesTable.lastSyncAt'
        ),
        tenant: t('integrationSettings.confluence.connectedSpacesTable.tenant'),
      }}
      renderName={(space) => space.name}
      renderSyncedFiles={(space) =>
        space.status === 'PENDING' ? '?' : space.syncedFiles
      }
      renderTotalFiles={(space) =>
        space.status === 'PENDING' ? '?' : space.totalFiles
      }
      renderUsedStorage={(space) => `${space.usedStorage.toFixed(2)}MB`}
      renderStatus={(space) => (
        <Badge
          size="sm"
          className={`font-bold ${
            space.status === 'IN PROGRESS'
              ? 'bg-maia-support-yellow'
              : space.status === 'FAILED'
                ? 'bg-maia-support-red'
                : space.status === 'PENDING'
                  ? 'bg-chakra-gray-400'
                  : 'bg-maia-support-green'
          } text-xs text-white`}
        >
          {statusTranslationMap[space.status]}
        </Badge>
      )}
      renderSyncedAt={(space) =>
        `${space.lastSyncStartedAt ? timeAgo(space.lastSyncStartedAt, t) : ''}`
      }
      renderTenant={(space) => (
        <ConfluenceTenantLink tenantUrl={space.tenantUrl} />
      )}
    />
  );
}

export default ConfluenceSpaceStatusTable;
