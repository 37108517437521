import {
  DialogContent,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  ProgressCircleRing,
  ProgressCircleRoot,
} from '@/components/ui/progress-circle';

interface LoadingDialogProps {
  isOpen: boolean;
  title: string;
  description?: string;
}

const LoadingDialog = ({ isOpen, title, description }: LoadingDialogProps) => {
  return (
    <DialogRoot open={isOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center justify-center gap-4 py-6">
          <ProgressCircleRoot value={null} size="lg">
            <ProgressCircleRing />
          </ProgressCircleRoot>
          {description && (
            <p className="text-muted-foreground text-right text-sm">
              {description}
            </p>
          )}
        </div>
      </DialogContent>
    </DialogRoot>
  );
};

export default LoadingDialog;
