import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ROUTES } from 'common-ts';
import { Tooltip } from '@/components/ui/tooltip';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { twMerge } from 'tailwind-merge';

type MaiaDocumentViewButtonProps = {
  className?: string;
  id: string;
  workspaceId: string;
};

function MaiaDocumentViewButton({
  className,
  id,
  workspaceId,
}: MaiaDocumentViewButtonProps) {
  return (
    <Tooltip content="See it how MAIA sees it">
      <Link
        to={ROUTES.HOME.DOCUMENT_DEBUG_VIEW.$buildPath({
          params: { id, workspaceId },
        })}
        className={twMerge('', className)}
        target="_blank"
      >
        <FontAwesomeIcon icon={faEye} />
      </Link>
    </Tooltip>
  );
}

export default MaiaDocumentViewButton;
