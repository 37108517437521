import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '../../components/ui/dialog';
import { useEffect, useState } from 'react';

import { Button } from '../../components/ui/button';
import { Checkbox } from '../../components/ui/checkbox';
import { ConfluenceTenantLink } from './ConfluenceTenantLink';
import SearchBar from '../../components/SearchBar';
import { Skeleton } from '../../components/ui/skeleton';
import { toaster } from '../../components/ui/toaster';
import { useApi } from '../../utils/useApi';
import { useBoundStore } from '../../store/useBoundStore';
import { useTranslation } from 'react-i18next';
import { zeros } from 'common-ts';

export type SpaceItem = {
  name: string;
  id: string;
  clientKey: string;
  selected: boolean;
  tenantUrl: string;
  lastSyncStartedAt: string | null;
  lastSyncFailed: boolean;
};

type ConfluenceSpaceSelectionModalProps = {
  isOpen: boolean;
  onNewSpaceSelected: (spaces: SpaceItem[]) => void;
  onClose: () => void;
};

function ConfluenceSpaceSelectionModal({
  isOpen,
  onNewSpaceSelected,
  onClose,
}: ConfluenceSpaceSelectionModalProps) {
  const { t } = useTranslation();
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const supabase = useBoundStore((state) => state.supabase);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSpaces, setSelectedSpaces] = useState<SpaceItem[]>([]);

  const { data: ConfluenceSpaces, error } = useApi(
    supabase,
    '/integrations',
    '/atlassian/available_spaces',
    { method: 'POST', workspaceId }
  );

  useEffect(() => {
    if (error) {
      toaster.create({
        title: t('general.tryAgainError'),
        type: 'error',
      });
    }
  }, [error]);

  useEffect(() => {
    if (ConfluenceSpaces) {
      setSelectedSpaces(
        ConfluenceSpaces.spaces.sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  }, [ConfluenceSpaces]);

  function handleSpaceSelectToggle(space: SpaceItem) {
    setSelectedSpaces((prev) => {
      const item = prev.filter(
        (s) => s.id === space.id && s.clientKey === space.clientKey
      )[0];

      if (item) {
        item.selected = !item.selected;
        return [...prev].sort((a, b) => a.name.localeCompare(b.name));
      }
      return prev;
    });
  }

  const filteredSpaces =
    ConfluenceSpaces?.spaces
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((space: SpaceItem) =>
        space.name.toLowerCase().includes(searchTerm.toLowerCase())
      ) || [];

  return (
    <DialogRoot open={isOpen} onOpenChange={({ open }) => !open && onClose()}>
      <DialogBackdrop />
      <DialogContent className="max-h-[512px] self-stretch">
        <DialogHeader>
          <DialogTitle>
            {t('integrationSettings.confluence.spaceSelectionModal.title')}
          </DialogTitle>
        </DialogHeader>
        <DialogBody
          className="flex min-h-0 flex-col gap-4"
          colorPalette="maia-accent"
        >
          <div>
            {t(
              'integrationSettings.confluence.spaceSelectionModal.availableSpaces'
            )}
          </div>
          <SearchBar onSearchTermChange={setSearchTerm} />
          <div className="flex min-h-0 flex-col gap-2 overflow-y-auto">
            {ConfluenceSpaces
              ? filteredSpaces.map((space: SpaceItem) => (
                  <div
                    key={space.id.toString()}
                    className="flex items-center justify-between"
                  >
                    <Checkbox
                      className="flex flex-shrink-0 truncate"
                      checked={space.selected}
                      onChange={() => handleSpaceSelectToggle(space)}
                    >
                      {space.name}
                    </Checkbox>
                    <ConfluenceTenantLink
                      className="text-[10px]"
                      tenantUrl={space.tenantUrl}
                    />
                  </div>
                ))
              : zeros(5).map((_, index) => (
                  <Skeleton key={index}>
                    <Checkbox>Loading</Checkbox>
                  </Skeleton>
                ))}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            colorPalette="gray"
            variant="outline"
            mr={2}
            onClick={onClose}
          >
            {t('general.cancelButton')}
          </Button>
          <Button
            colorPalette="maia-accent"
            onClick={() => {
              onNewSpaceSelected(selectedSpaces);
              onClose();
            }}
          >
            {t(
              'integrationSettings.confluence.spaceSelectionModal.confirmButton'
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

export default ConfluenceSpaceSelectionModal;
