import { captureException, getCurrentScope } from '@sentry/react';
import { useEffect, useState } from 'react';

import GenerciBanners from './GenerciBanners.js';
import HomeRoutes from './HomeRoutes.js';
import { IoProvider } from 'socket.io-react-hook';
import MaiaLayout from '../../components/MaiaLayout.js';
import { ROUTES } from 'common-ts';
import { Skeleton } from '@chakra-ui/react';
import TrialInfo from '../../general/subscriptionInfos/TrialInfo.js';
import { fetchApi } from '@/utils/useApi';
import { getDefaultWorkspace } from '@/utils/getDefaultWorkspace';
import i18n from '../../i18n.js';
import { toaster } from '@/components/ui/toaster';
import { useBoundStore } from '@/store/useBoundStore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes';

export default function Home() {
  const { workspaceId } = useTypedParams(ROUTES.HOME);
  const workspaceIdFromGlobalState = useBoundStore(
    (state) => state.workspaceId
  );
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const isLoading = useBoundStore((state) => state.loading);
  const [isWorkspaceCheckLoading, setIsWorkspaceCheckLoading] = useState(false);

  const initStripe = useBoundStore((state) => state.initStripe);
  const setWorkspaceId = useBoundStore((state) => state.setWorkspaceId);

  const stripe = useBoundStore((state) => state.stripe);

  const navigate = useNavigate();
  const workspaceInactiveUserDisabledToastId = 'workspaceInactiveUserDisabled';

  async function checkWorkspaceStatus() {
    setIsWorkspaceCheckLoading(true);
    const session = await supabase.auth.getSession();
    if (!session?.data.session) {
      toaster.create({ title: t('general.sessionExpired'), type: 'warning' });
      navigate(ROUTES.AUTH.SIGN_IN.$buildPath({}));
      return;
    }

    const allWorkspacesRes = await supabase
      .from('workspace')
      .select('*, workspace_user(user_type, disabled)');

    if (allWorkspacesRes.error || !allWorkspacesRes.data) {
      captureException(allWorkspacesRes.error);
      toaster.create({
        title: t('navigateToWorkspace.supabaseError'),
        type: 'error',
      });
      return;
    }

    const currentWorkspace = allWorkspacesRes.data.find(
      (workspace) => workspace.id === workspaceId
    );

    // Workspace does not exist (also shown for USERs if workspace is inactive or they have been disabled for that workspace)
    if (
      !currentWorkspace ||
      ((currentWorkspace.inactive ||
        currentWorkspace.workspace_user[0]?.disabled) &&
        !['OWNER', 'ADMIN'].includes(
          currentWorkspace.workspace_user[0]?.user_type ?? ''
        ))
    ) {
      if (workspaceId) {
        toaster.create({
          type: 'warning',
          title: t('home.workspaceNotExistError'),
          duration: 5000,
        });
      }

      const { data: navigateToWorkspaceId, error } = await getDefaultWorkspace({
        allWorkspacesOfUser: allWorkspacesRes.data,
        supabase,
      });

      if (error) {
        switch (error.name) {
          case 'SupabaseAuthError':
          case 'NoUserSession':
            navigate(ROUTES.AUTH.SIGN_IN.$buildPath({}));
            break;
          case 'BackendError':
            toaster.create({
              title: t('navigateToWorkspace.supabaseError'),
              type: 'error',
            });
            navigate(ROUTES.AUTH.SIGN_IN.$buildPath({}));
            break;
          case 'SessionExpired':
            toaster.create({
              title: t('general.sessionExpired'),
              type: 'warning',
            });
            navigate(ROUTES.AUTH.SIGN_IN.$buildPath({}));
            break;
        }
        return;
      }

      navigate(
        ROUTES.HOME.CHAT.$buildPath({
          params: { workspaceId: navigateToWorkspaceId },
        })
      );
      return;

      // Show hints for OWNER or ADMIN
    } else if (
      currentWorkspace.inactive ||
      currentWorkspace.workspace_user[0]?.disabled
    ) {
      if (toaster.isVisible(workspaceInactiveUserDisabledToastId)) return;
      toaster.create({
        id: workspaceInactiveUserDisabledToastId,
        title: currentWorkspace.workspace_user[0]?.disabled
          ? t('general.workspaceUserDisabledHint')
          : t('general.workspaceInactiveHint'),
        type: 'warning',
        duration: 15000,
      });

      navigate(
        ROUTES.HOME.SETTINGS.WORKSPACE.LICENSE.$buildPath({
          params: { workspaceId },
        })
      );
    }
    setIsWorkspaceCheckLoading(false);
  }

  useEffect(() => {
    if (!stripe) initStripe();
  }, [stripe]);

  useEffect(() => {
    if (workspaceId) {
      setWorkspaceId(workspaceId);
      checkWorkspaceStatus();
    }
    getCurrentScope().setTag('workspaceId', workspaceId);
  }, [workspaceId]);

  // Posthog NPS Survey preconditions
  const [showNPSSurvey, setShowNPSSurvey] = useState(false);

  useEffect(() => {
    async function getUsageMetrics() {
      const usageRes = await fetchApi(supabase, '/user', '/usage', {
        method: 'GET',
      });

      if (usageRes.success) {
        setShowNPSSurvey(
          new Date(usageRes.data.userCreation).getTime() <
            new Date().getTime() - 604800000 && // 7 Days
            (usageRes.data.chats + usageRes.data.searches > 8 ||
              usageRes.data.totalChatMessages > 30)
        );
      }
    }

    getUsageMetrics();
  }, []);

  return workspaceIdFromGlobalState !== workspaceId ||
    isLoading ||
    isWorkspaceCheckLoading ? (
    <div className="text-maia-gray-900 h-screen w-screen">
      <MaiaLayout
        mainPanelContent={<Skeleton height={'full'} width={'full'} />}
        sidePanelContent={<Skeleton />}
        onCloseSidePanelMobile={() => {}}
        onOpenSidePanelMobile={() => {}}
        sidePanelOpenMobile={false}
      />
    </div>
  ) : (
    <IoProvider>
      {/* lang- className is for posthog survey, do not remove */}
      <div
        className={`text-maia-gray-900 h-screen w-screen ${showNPSSurvey ? `nps-lang-${i18n.language}` : ''}`}
      >
        <GenerciBanners />
        <HomeRoutes />
        <TrialInfo />
      </div>
    </IoProvider>
  );
}
