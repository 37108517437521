import {
  Database,
  ROUTES,
  SHAREPOINT_SELECTION_MAP_PATH_DELIMITER,
  SharepointFileSelectionMap,
  zeros,
} from 'common-ts';
import GenericSelectableFileAndFolderList, {
  BasicFileInfo,
  BasicFolderInfo,
} from './GenericSelectableFileAndFolderList';
import { IconButton, Skeleton } from '@chakra-ui/react';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { faAngleRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { fetchApi, useApi } from '@/utils/useApi';

import { Checkbox } from '@/components/ui/checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkButton } from '@/components/ui/link-button';
import ListItem from '../../../components/ListItem';
import { Link as RouterLink } from 'react-router-dom';
import { SharePointSelectionStateHandlers } from './useSharepointSelectionStateHandlers';
import { Tooltip } from '@/components/ui/tooltip';
import { captureException } from '@sentry/react';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { getMsAuthFlowLink } from '@/utils/msAuthFlow';
import { pathStringFromBreadCrumbs } from './utils';
import { toaster } from '@/components/ui/toaster';
import { twMerge } from 'tailwind-merge';
import { useBoundStore } from '@/store/useBoundStore';
import { useTranslation } from 'react-i18next';

type SharepointSiteNavigatorProps = {
  className?: string;
  selectedFileMap: SharepointFileSelectionMap;
  documentViewerActive: boolean;
  onBackClick: () => void;
  onUpdateSelectedFileMap: (
    updateValue: SetStateAction<SharepointFileSelectionMap>
  ) => void;
  sharePointSelectionStateHandlers: SharePointSelectionStateHandlers;
};

function SharepointSiteNavigator({
  className,
  selectedFileMap,
  documentViewerActive,
  onBackClick,
  sharePointSelectionStateHandlers,
  // onUpdateSelectedFileMap,
}: SharepointSiteNavigatorProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);

  const workspaceId = useBoundStore((state) => state.workspaceId);
  const { data: sharepointIntegrationStatus } = useApi(
    supabase,
    '/integrations',
    '/sharepoint/status',
    {
      method: 'POST',
      workspaceId,
    }
  );

  const [loadingSites, setLoadingSites] = useState(true);
  const [availableSites, setAvailableSites] = useState<
    Database['public']['Views']['sharepoint_site_view']['Row'][]
  >([]);
  const [loginRequired, setLoginRequired] = useState(false);
  const [notActivated, setNotActivated] = useState(false);

  /**
   * Refresh contents of the current folder and request syncing of sharepoint view permissions.
   */
  function handleFolderRefresh() {
    // This is a (potentially) long running process that we have decided to not inform users of. They either see files or they don't
    fetchApi(supabase, '/integrations', '/sharepoint/request_permission_sync', {
      method: 'POST',
      workspaceId,
    }).catch((e) => {
      captureException(e);
    });

    sharePointSelectionStateHandlers.refetchCurrentFolderContents();
  }

  useEffect(() => {
    async function fetchSites() {
      setLoadingSites(true);

      const sharepointStatusRes = await fetchApi(
        supabase,
        '/integrations',
        '/sharepoint/status',
        {
          method: 'POST',
          workspaceId,
        }
      );

      if (!sharepointStatusRes.success) {
        if (sharepointStatusRes.error.code === 542) {
          toaster.create({
            type: 'error',
            title: t('general.passiveAggressiveMicrosoftError'),
            description: t('general.passiveAggressiveMicrosoftInfo'),
          });
        } else {
          toaster.create({ type: 'error', title: t('general.reloadError') });
        }
        setLoadingSites(false);
        return;
      }

      const sharepointStatus = sharepointStatusRes.data;

      if (!sharepointStatus) {
        toaster.create({ title: t('general.tryAgainError'), type: 'error' });
        setLoadingSites(false);
        return;
      }

      if (
        !sharepointStatus.activatedForWorkspace ||
        !sharepointStatus.adminPermissionsGranted
      ) {
        setNotActivated(true);
      } else if (!sharepointStatus.loggedInAs) {
        setLoginRequired(true);
      }

      const { data, error } = await supabase
        .from('sharepoint_site_view')
        .select('*')
        .eq('workspace_id', workspaceId);

      if (error) {
        toaster.create({ title: t('general.tryAgainError'), type: 'error' });
        captureException(error);
        setAvailableSites([]);
        setLoadingSites(false);
        return;
      }

      setAvailableSites(data);
      setLoadingSites(false);
    }

    fetchSites();
  }, []);

  const fileList: BasicFileInfo[] = useMemo(() => {
    return sharePointSelectionStateHandlers.files.map((file) => {
      // None of these properties are actually nullable, but they are typed as such because of how views work in postgres.
      return {
        id: file.id!,
        name: file.name!,
        embeddingStatus: file.embedding_status!,
        mimeType: file.mime_type!,
      };
    });
  }, [sharePointSelectionStateHandlers.files]);

  const folderList: BasicFolderInfo[] = useMemo(() => {
    return sharePointSelectionStateHandlers.folders.map((folder) => {
      return {
        id: folder.id!,
        name: folder.name!,
        childFolderCount: folder.child_folder_count!,
        fileCount: folder.file_count!,
      };
    });
  }, [sharePointSelectionStateHandlers.folders]);

  return (
    <div
      className={twMerge(
        'border-maia-border flex w-full flex-col border-b border-t',
        className
      )}
    >
      {notActivated ? (
        <div className="m-auto flex w-3/4 flex-col items-center justify-center gap-2 p-4 text-center">
          <div>
            {t('chat.fileSelector.sharepointAvailableButNotActive')}{' '}
            <RouterLink
              to={ROUTES.HOME.SETTINGS.INTEGRATIONS.SHAREPOINT.$buildPath({
                params: { workspaceId },
              })}
              className="underline"
            >
              {t('chat.fileSelector.sharepointSettingsLink')}
            </RouterLink>
          </div>
        </div>
      ) : loginRequired ? (
        <div className="m-auto flex w-3/4 flex-col items-center justify-center gap-2 p-4 text-center">
          <div>
            {t(
              'integrationSettings.sharepoint.personalConnection.infoAccountNotConnected'
            )}
          </div>
          <LinkButton
            colorPalette="maia-purple"
            href={getMsAuthFlowLink({
              redirectTo: window.location.pathname,
              workspaceId,
              action: 'userAuth',
            }).toString()}
          >
            {t(
              'integrationSettings.sharepoint.personalConnection.connectAccountButton'
            )}
          </LinkButton>
        </div>
      ) : sharepointIntegrationStatus?.reauthRequired ? (
        <div className="m-auto flex w-3/4 flex-col items-center justify-center gap-2 p-4 text-center">
          <div className="whitespace-pre-line font-medium">
            {t(
              'integrationSettings.sharepoint.personalConnection.infoAccountNeedsRefresh'
            )}
          </div>
          <LinkButton
            className="m-auto mt-2"
            variant={'outline'}
            href={getMsAuthFlowLink({
              redirectTo: window.location.pathname,
              workspaceId,
              action: 'userAuth',
            }).toString()}
          >
            {t(
              'integrationSettings.sharepoint.personalConnection.refreshConnectionButton'
            )}
          </LinkButton>
        </div>
      ) : (
        <div className="flex min-h-0 flex-grow">
          {/* ------------------------------------------------ Sites ------------------------------------------------ */}
          <div
            className={`border-maia-border flex flex-grow flex-col gap-2 overflow-y-auto border-r px-4 py-2 md:w-72 md:flex-grow-0 ${sharePointSelectionStateHandlers.currentSite ? 'hidden md:flex' : ''}`}
          >
            <IconButton
              className="self-start md:hidden"
              aria-label="back"
              variant={'ghost'}
              onClick={onBackClick}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
            {loadingSites
              ? zeros(5).map((_, index) => {
                  return (
                    <Skeleton key={index}>
                      <ListItem selected={false}>Loading</ListItem>
                    </Skeleton>
                  );
                })
              : availableSites.map((site) => {
                  const checkedStatus = selectedFileMap[site.id!]?.selected;

                  return (
                    <ListItem
                      key={site.id}
                      selected={
                        site.id ===
                        sharePointSelectionStateHandlers.currentSite?.id
                      }
                      className="flex cursor-pointer select-none items-center gap-2 px-4 text-sm"
                    >
                      <Checkbox
                        colorPalette="maia-purple"
                        className="hover:bg-maia-purple-50 rounded"
                        checked={
                          checkedStatus === null
                            ? 'indeterminate'
                            : typeof checkedStatus === 'undefined'
                              ? false
                              : checkedStatus
                        }
                        onCheckedChange={() => {
                          sharePointSelectionStateHandlers.handleSiteSelectToggle(
                            site.id!,
                            site.name!,
                            sharePointSelectionStateHandlers.currentSite?.id,
                            sharePointSelectionStateHandlers.breadCrumbs
                          );
                        }}
                      />
                      {/* The div hierarchy is a bit awkward here in order to separate the checkbox from the rest of the Listitem's content */}
                      <div
                        className="flex w-28 flex-grow items-center justify-between"
                        onClick={() => {
                          sharePointSelectionStateHandlers.setCurrentSite({
                            id: site.id!,
                            name: site.name!,
                          });
                        }}
                      >
                        <div className="flex min-w-0 items-center gap-2">
                          <FontAwesomeIcon
                            icon={faFolder}
                            className="text-chakra-gray-400 text-base"
                          />
                          <Tooltip content={site.name}>
                            <div className="truncate">{site.name}</div>
                          </Tooltip>
                        </div>
                        <div className="text-chakra-gray-500 flex items-center gap-1">
                          <div>{site.file_count}</div>
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="text-xs"
                          />
                        </div>
                      </div>
                    </ListItem>
                  );
                })}
          </div>
          {/* ------------------------------------------------ Folders and files ------------------------------------------------ */}
          <div
            className={`min-w-96 flex-grow px-4 py-2 ${!sharePointSelectionStateHandlers.currentSite ? 'hidden md:flex' : ''}`}
          >
            <GenericSelectableFileAndFolderList
              className={`${sharePointSelectionStateHandlers.currentSite ? '' : 'hidden'}`}
              breadCrumbs={sharePointSelectionStateHandlers.breadCrumbs}
              folderMapPathFromBreadCrumbs={(breadCrumbs) =>
                pathStringFromBreadCrumbs({
                  breadCrumbs,
                  delimiter: SHAREPOINT_SELECTION_MAP_PATH_DELIMITER,
                })
              }
              files={fileList}
              folders={folderList}
              loading={sharePointSelectionStateHandlers.loading}
              overallFileCount={
                availableSites.find(
                  (availableSite) =>
                    availableSite.id ===
                    sharePointSelectionStateHandlers.currentSite?.id
                )?.file_count ?? 0
              }
              onBackClick={() => {
                if (sharePointSelectionStateHandlers.breadCrumbs.length) {
                  sharePointSelectionStateHandlers.handleGoBackInCrumbs(
                    sharePointSelectionStateHandlers.breadCrumbs.length - 2
                  );
                } else {
                  // Mobile: if already in root folder -> go back to site selection
                  sharePointSelectionStateHandlers.setCurrentSite(undefined);
                }
              }}
              onDeselectAllClick={() => {
                if (sharePointSelectionStateHandlers.currentSite) {
                  sharePointSelectionStateHandlers.handleDeselectAll(
                    sharePointSelectionStateHandlers.breadCrumbs,
                    sharePointSelectionStateHandlers.currentSite.id
                  );
                }
              }}
              onFileCheckboxClick={(fileId) => {
                if (sharePointSelectionStateHandlers.currentSite) {
                  sharePointSelectionStateHandlers.handleFileSelectToggle(
                    sharePointSelectionStateHandlers.breadCrumbs,
                    fileId,
                    sharePointSelectionStateHandlers.currentSite.id
                  );
                }
              }}
              onFolderCheckboxClick={(folderId) => {
                if (sharePointSelectionStateHandlers.currentSite) {
                  sharePointSelectionStateHandlers.handleFolderSelectToggle(
                    sharePointSelectionStateHandlers.breadCrumbs,
                    folderId,
                    sharePointSelectionStateHandlers.currentSite.id
                  );
                }
              }}
              onFolderClick={({ folderId, name }) => {
                sharePointSelectionStateHandlers.handleGoToFolder({
                  folderId,
                  name,
                });
              }}
              onRefreshClick={handleFolderRefresh}
              onSelectAllClick={() => {
                if (sharePointSelectionStateHandlers.currentSite) {
                  sharePointSelectionStateHandlers.handleSelectAll(
                    sharePointSelectionStateHandlers.breadCrumbs,
                    sharePointSelectionStateHandlers.currentSite.id
                  );
                }
              }}
              onSelectFolderInCrumbs={(index) => {
                sharePointSelectionStateHandlers.handleGoBackInCrumbs(index);
              }}
              selectedFileMap={
                sharePointSelectionStateHandlers.currentSite
                  ? (selectedFileMap[
                      sharePointSelectionStateHandlers.currentSite.id
                    ]?.folders ?? {})
                  : {}
              }
              documentViewerActive={documentViewerActive}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SharepointSiteNavigator;
