import {
  CONFLUENCE_SELECTION_MAP_PATH_DELIMITER,
  ConfluenceFileSelectionMap,
  MaiaApiRoutes,
} from 'common-ts';
import { FolderBreadCrumbs, pathStringFromBreadCrumbs } from './utils';
import { SetStateAction, useCallback, useState } from 'react';

import { captureException } from '@sentry/react';
import { fetchApi } from '@/utils/useApi';
import { toaster } from '@/components/ui/toaster';
import { useBoundStore } from '@/store/useBoundStore';
import { useTranslation } from 'react-i18next';

export type Space = {
  name: string;
  id: string;
  clientKey: string;
};

type ContentItem =
  MaiaApiRoutes['/integrations']['/atlassian/content_children']['response']['contentItems'][number];

export type ConfluenceSelectionStateHandlers = {
  contentItems: ContentItem[];
  loading: boolean;
  currentSpace: Space | undefined;
  breadCrumbs: FolderBreadCrumbs;
  setCurrentSpace: (newSpace: Space | undefined) => void;
  handleFolderSelectToggle: (
    currentBreadCrumbs: FolderBreadCrumbs,
    folderId: string,
    spaceId: string,
    clientKey: string
  ) => void;
  handleFileSelectToggle: (
    currentBreadCrumbs: FolderBreadCrumbs,
    fileId: string,
    spaceId: string,
    clientKey: string
  ) => void;
  handleGoToFolder: (folder: { folderId: string; name: string }) => void;
  handleGoBackInCrumbs: (index: number) => void;
  handleSelectAll: (
    currentBreadCrumbs: FolderBreadCrumbs,
    spaceId: string,
    clientKey: string
  ) => void;
  handleDeselectAll: (
    currentBreadCrumbs: FolderBreadCrumbs,
    spaceId: string,
    clientKey: string
  ) => void;
  handleSpaceSelectToggle: (
    clientKey: string,
    spaceId: string,
    spaceName: string,
    currentlyViewingSpaceId: string | undefined,
    currentBreadCrumbs: FolderBreadCrumbs
  ) => void;
  refetchCurrentFolderContents: () => void;
  clearSelectedFileMap: () => void;
};

export default function useConfluenceSelectionStateHandlers(
  onUpdateSelectedFileMap: (
    updateValue: SetStateAction<ConfluenceFileSelectionMap>
  ) => void
): ConfluenceSelectionStateHandlers {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const [currentSpace, setCurrentSpace] = useState<Space | undefined>(
    undefined
  );

  const [loading, setLoading] = useState(false);
  const [contentItems, setContentItems] = useState<ContentItem[]>([]);

  // Confluence has folders as a specific type of content item. But other content items (e.g. pages) can have children as well.
  // In this file, when we are referring to a 'folder' we mean any content item that can have children.
  // For example the 'currentFolderId' corresponds to the id of the content item that the user is currently viewing the children of.

  // List of folders highest to lowest level
  const [breadCrumbs, setBreadCrumbs] = useState<FolderBreadCrumbs>([]);
  const currentFolderId = breadCrumbs[breadCrumbs.length - 1]?.id;

  /**
   * Wrapper around `setCurrentSpace`.
   * For navigation, you should use this instead of `setCurrentSpace` because it synchronously updates the folder contents (and other dependent state) with the new spaceId.
   *
   * @param newSpace.id The id of the new space.
   * @param newSpace.name The name of the new space.
   */
  function handleChangeCurrentSpace(newSpace: Space | undefined) {
    setBreadCrumbs([]);
    setContentItems([]);
    setLoading(false);

    setCurrentSpace(newSpace);
    if (newSpace) {
      fetchContentItems({
        clientKey: newSpace.clientKey,
        spaceId: newSpace.id,
        spaceName: newSpace.name,
        breadCrumbs: [],
        parentContentId: undefined,
      });
    }
  }

  /**
   * Wrapper around `setBreadCrumbs`. For navigation, you should use this instead of `setBreadCrumbs` because it synchronously updates the folder contents with the changed breadcrumbs.
   * @param newBreadCrumbs The updated breadCrumbs or a function to update them based on the previous breadCrumbs.
   */
  function handleChangeBreadCrumbs(
    newBreadCrumbs: SetStateAction<FolderBreadCrumbs>
  ) {
    if (typeof newBreadCrumbs === 'function') {
      setBreadCrumbs((prev) => {
        const updatedBreadCrumbs = newBreadCrumbs(prev);

        fetchContentItems({
          breadCrumbs: updatedBreadCrumbs,
          clientKey: currentSpace!.clientKey,
          spaceId: currentSpace!.id,
          spaceName: currentSpace!.name,
          parentContentId:
            updatedBreadCrumbs[updatedBreadCrumbs.length - 1]?.id,
        });
        return updatedBreadCrumbs;
      });
    } else {
      setBreadCrumbs(newBreadCrumbs);

      fetchContentItems({
        breadCrumbs: newBreadCrumbs,
        clientKey: currentSpace!.clientKey,
        spaceId: currentSpace!.id,
        spaceName: currentSpace!.name,
        parentContentId: newBreadCrumbs[newBreadCrumbs.length - 1]?.id,
      });
    }
  }

  /**
   * Fetch content items that are children of the specified parent content and updates the state accordingly. Only fetches direct children.
   *
   * @param clientKey The clientKey uniquely identifying the Confluence tenant.
   * @param spaceId The id (`integrations.atl_space.id`) of the Confluence space for which to fetch the content items.
   * @param spaceName The name of the Confluence space specified by the spaceId param.
   * @param parentContentId The id (`integrations.atl_content.id`) of the 'folder' to fetch the contents of. If no folderId is provided, fetches the direct children of the space (content items that have `NULL` set as their `parent_id`).
   * @param breadCrumbs The breadcrumbs specifying the currently selected path.
   */
  const fetchContentItems = useCallback(
    async ({
      clientKey,
      spaceId,
      spaceName,
      parentContentId,
      breadCrumbs,
    }: {
      clientKey: string;
      spaceId: string;
      spaceName: string;
      parentContentId: string | undefined;
      breadCrumbs: FolderBreadCrumbs;
    }) => {
      setLoading(true);
      const res = await fetchApi(
        supabase,
        '/integrations',
        '/atlassian/content_children',
        {
          method: 'POST',
          clientKey,
          parentContentId: parentContentId ?? null,
          spaceId,
          workspaceId,
        }
      );

      if (!res.success) {
        toaster.create({
          title: t('general.tryAgainError'),
          type: 'error',
        });
        captureException(res.error);
        setContentItems([]);
        setLoading(false);
        return;
      }

      handleUpdateCurrentFolderFiles(
        clientKey,
        spaceId,
        spaceName,
        res.data.contentItems,
        breadCrumbs
      );
      setLoading(false);
    },
    [workspaceId]
  );

  /**
   * Function to refetch the content of the *current* folder.
   * Only use in cases where no navigation is happening (siteId and current folder are not changing).
   * Might produce unexpected results otherwise because of async state updates.
   */
  const refetchCurrentFolderContents = useCallback(async () => {
    if (currentSpace) {
      await fetchContentItems({
        clientKey: currentSpace.clientKey,
        spaceId: currentSpace.id,
        spaceName: currentSpace.name,
        breadCrumbs,
        parentContentId: currentFolderId,
      });
    }
  }, [currentSpace, currentFolderId, breadCrumbs, fetchContentItems]);

  /**
   * Use when files contained in a folder have been loaded.
   * Sets the files that will be displayed to `contentItems`.
   * Takes all necessary steps to correctly update `selectedFileMap`. No matter if the files and folders are loaded for the first time or have been seen before.
   * `contentItems` must represent to contents of the current folder (as determined by `currentBreadcrumbs`) in order for the logic surrounding `selectedFileMap` to work correctly.
   *
   * !breadcrumbs are passed as an argument and not taken from state.
   * This is to ensure that the proper path at the time of calling this function is used, instead of possibly out of sync state.
   *
   * @param spaceId The id of the current space.
   * @param spaceName The name of the current space.
   * @param contentItemData The content items contained in the current folder.
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder.
   */
  function handleUpdateCurrentFolderFiles(
    clientKey: string,
    spaceId: string,
    spaceName: string,
    contentItemData: ContentItem[],
    currentBreadCrumbs: FolderBreadCrumbs
  ) {
    setContentItems(contentItemData);

    const folderPath = pathStringFromBreadCrumbs({
      breadCrumbs: currentBreadCrumbs,
      delimiter: CONFLUENCE_SELECTION_MAP_PATH_DELIMITER,
    });

    const clientKeySpaceId = `${clientKey}${CONFLUENCE_SELECTION_MAP_PATH_DELIMITER}${spaceId}`;

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      if (!prevSelectedFileMap[clientKeySpaceId]) {
        prevSelectedFileMap[clientKeySpaceId]! = {
          selected: false,
          folders: {},
          name: spaceName,
        };
      }

      const siteSelectionInfo = prevSelectedFileMap[clientKeySpaceId]!;
      const folderSelectionInfo = siteSelectionInfo.folders[folderPath];

      // Folder has not been opened before -> no selection info in state yet
      // Should only happen for root folder
      if (!folderSelectionInfo) {
        siteSelectionInfo.folders[folderPath] = {
          selected: !!siteSelectionInfo.selected,
          files: Object.fromEntries(
            contentItemData
              .filter((file) => file.embeddingStatus === 'FINISHED')
              .map((file) => {
                return [file.id!, !!siteSelectionInfo.selected];
              })
          ),
        };
      }
      // Folder has already been opened OR marked as selected -> update with missing files
      else {
        const updatedFileMap = Object.fromEntries(
          contentItemData
            .filter((file) => file.embeddingStatus === 'FINISHED')
            .map((file) => {
              return [
                file.id!,
                folderSelectionInfo.selected === null
                  ? (folderSelectionInfo.files[file.id!] ?? false)
                  : folderSelectionInfo.selected,
              ];
            })
        );

        prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath] = {
          selected: folderSelectionInfo.selected,
          files: {
            ...folderSelectionInfo.files,
            ...updatedFileMap,
          },
        };
      }

      // Content items that have children are 'folders'
      for (const contentItem of contentItemData) {
        if (contentItem.hasChildren || contentItem.type !== 'page') {
          const childFolderPath = `${folderPath}${CONFLUENCE_SELECTION_MAP_PATH_DELIMITER}${contentItem.id!}`;

          const childFolderInfo =
            prevSelectedFileMap[clientKeySpaceId]!.folders[childFolderPath];
          // Child folder not seen yet
          if (!childFolderInfo) {
            prevSelectedFileMap[clientKeySpaceId]!.folders[childFolderPath] = {
              selected:
                !!prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!
                  .selected,
              files: {},
            };
          } else {
            prevSelectedFileMap[clientKeySpaceId]!.folders[
              childFolderPath
            ]!.selected =
              prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]
                ?.selected === null
                ? (prevSelectedFileMap[clientKeySpaceId]!.folders[
                    childFolderPath
                  ]?.selected ?? null)
                : prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!
                    .selected;
          }
        }
      }

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Handles the selection / deselection of an entire folder
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder. !This is the folder the user is currently seeing the contents of, not the one being selected.
   * @param folderId Id of the 'folder' to be selected. Content items with children are treated as folders.
   * They have two selection states. One folder selection state which signifies the selection of all their children and one selection state as the individual file that they represent.
   * @param spaceId space id of the current space.
   */
  function handleFolderSelectToggle(
    currentBreadCrumbs: FolderBreadCrumbs,
    folderId: string,
    spaceId: string,
    clientKey: string
  ) {
    const folderPath = `${pathStringFromBreadCrumbs({
      breadCrumbs: currentBreadCrumbs,
      delimiter: CONFLUENCE_SELECTION_MAP_PATH_DELIMITER,
    })}${CONFLUENCE_SELECTION_MAP_PATH_DELIMITER}${folderId}`;

    const clientKeySpaceId = `${clientKey}${CONFLUENCE_SELECTION_MAP_PATH_DELIMITER}${spaceId}`;

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      // Only set folder itself to selected. Selection state of contained files is handled in case the folder is opened.
      prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!.selected =
        !prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!.selected;

      // Go up the hierarchy to set all parent folders to indeterminate
      // This may lead to folders having the indeterminate state even though all their children are selected
      // But in order to eliminate this inconsistency, we would need to recursively go through all their children and check their selected states, which could have a huge performance impact.
      Object.keys(prevSelectedFileMap[clientKeySpaceId]!.folders).forEach(
        (pathKey) => {
          if (pathKey !== folderPath && folderPath.startsWith(pathKey)) {
            prevSelectedFileMap[clientKeySpaceId]!.folders[pathKey]!.selected =
              null;
          }
        }
      );

      // Set space to indeterminate
      prevSelectedFileMap[clientKeySpaceId]!.selected = null;

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Handles all state updates necessary to select / deselect a file.
   *
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder.
   * @param contentItemId Id of the file to be selected.
   * @param spaceId Id of the current space.
   */
  function handleFileSelectToggle(
    currentBreadCrumbs: FolderBreadCrumbs,
    contentItemId: string,
    spaceId: string,
    clientKey: string
  ) {
    const folderPath = pathStringFromBreadCrumbs({
      breadCrumbs: currentBreadCrumbs,
      delimiter: CONFLUENCE_SELECTION_MAP_PATH_DELIMITER,
    });

    const clientKeySpaceId = `${clientKey}${CONFLUENCE_SELECTION_MAP_PATH_DELIMITER}${spaceId}`;

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!.files[
        contentItemId
      ] =
        !prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!.files[
          contentItemId
        ];

      // Go up the hierarchy to set all parent folders to indeterminate
      // See `handleFolderSelectToggle` for caveats
      Object.keys(prevSelectedFileMap[clientKeySpaceId]!.folders).forEach(
        (pathKey) => {
          if (folderPath.startsWith(pathKey)) {
            prevSelectedFileMap[clientKeySpaceId]!.folders[pathKey]!.selected =
              null;
          }
        }
      );

      // Set site to indeterminate
      prevSelectedFileMap[clientKeySpaceId]!.selected = null;

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Handles the navigation to a folder that is a direct child of the currently viewed folder.
   * @param folderId The id of the folder to navigate to
   * @param name The name of the folder to navigate to
   */
  function handleGoToFolder({
    folderId,
    name,
  }: {
    folderId: string;
    name: string;
  }) {
    handleChangeBreadCrumbs((prev) => {
      return [...prev, { id: folderId, name }];
    });
  }

  /**
   * Handles going back in the current folder hierarchy based on the breadcrumbs.
   * @param index The index of the breadcrumb representing the folder to navigate to.
   */
  function handleGoBackInCrumbs(index: number) {
    handleChangeBreadCrumbs((prev) => {
      return prev.slice(0, index + 1);
    });
  }

  /**
   * Performs all state updates necessary to select all folders and files contained within the currently viewed folder.
   *
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder.
   * @param spaceId Id of the current space
   * @param clientKey The clientKey uniquely identifying the Confluence tenant.
   */
  function handleSelectAll(
    currentBreadCrumbs: FolderBreadCrumbs,
    spaceId: string,
    clientKey: string
  ) {
    const folderPath = pathStringFromBreadCrumbs({
      breadCrumbs: currentBreadCrumbs,
      delimiter: CONFLUENCE_SELECTION_MAP_PATH_DELIMITER,
    });

    const clientKeySpaceId = `${clientKey}${CONFLUENCE_SELECTION_MAP_PATH_DELIMITER}${spaceId}`;

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      // Set all entries in the current folder to selected (all contained files)
      Object.keys(
        prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!.files
      ).forEach((key) => {
        prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!.files[key] =
          true;
      });

      // Set the folder itself to selected
      prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!.selected =
        true;

      // Set site to true if done at root level, otherwise set to indeterminate
      prevSelectedFileMap[clientKeySpaceId]!.selected =
        folderPath === '' ? true : null;

      Object.keys(prevSelectedFileMap[clientKeySpaceId]!.folders).forEach(
        (pathKey) => {
          // Set all parent folders to indeterminate
          // See `handleFolderSelectToggle` for caveats
          if (pathKey !== folderPath && folderPath.startsWith(pathKey)) {
            prevSelectedFileMap[clientKeySpaceId]!.folders[pathKey]!.selected =
              prevSelectedFileMap[clientKeySpaceId]!.folders[pathKey]!
                .selected === null ||
              prevSelectedFileMap[clientKeySpaceId]!.folders[pathKey]!
                .selected === false
                ? null
                : true;
          }
          // Set all child folders to selected
          else if (pathKey.startsWith(folderPath)) {
            prevSelectedFileMap[clientKeySpaceId]!.folders[pathKey]!.selected =
              true;
          }
        }
      );

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Performs all state updates necessary to deselect all folders and files contained within the currently viewed folder.
   *
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder.
   * @param spaceId The id of the current space
   * @param clientKey The clientKey uniquely identifying the Confluence tenant.
   */
  function handleDeselectAll(
    currentBreadCrumbs: FolderBreadCrumbs,
    spaceId: string,
    clientKey: string
  ) {
    const folderPath = pathStringFromBreadCrumbs({
      breadCrumbs: currentBreadCrumbs,
      delimiter: CONFLUENCE_SELECTION_MAP_PATH_DELIMITER,
    });

    const clientKeySpaceId = `${clientKey}${CONFLUENCE_SELECTION_MAP_PATH_DELIMITER}${spaceId}`;

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      // Set all entries in the current folder to not selected (all contained files)
      Object.keys(
        prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!.files
      ).forEach((key) => {
        prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!.files[key] =
          false;
      });

      // Set the folder itself to not selected
      prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!.selected =
        false;

      // Set site to false if done at root level, otherwise set to indeterminate
      prevSelectedFileMap[clientKeySpaceId]!.selected =
        folderPath === '' ? false : null;

      Object.keys(prevSelectedFileMap[clientKeySpaceId]!.folders).forEach(
        (pathKey) => {
          // Set all parent folders to indeterminate
          // See `handleFolderSelectToggle` for caveats
          if (pathKey !== folderPath && folderPath.startsWith(pathKey)) {
            prevSelectedFileMap[clientKeySpaceId]!.folders[pathKey]!.selected =
              prevSelectedFileMap[clientKeySpaceId]!.folders[pathKey]!
                .selected === null ||
              prevSelectedFileMap[clientKeySpaceId]!.folders[pathKey]!
                .selected === true
                ? null
                : false;
          }
          // Set all child folders to not selected
          else if (pathKey.startsWith(folderPath)) {
            prevSelectedFileMap[clientKeySpaceId]!.folders[pathKey]!.selected =
              false;
          }
        }
      );

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Performs all state updates necessary to select / deselect an entire space
   *
   * @param clientKey The clientKey uniquely identifying the Confluence tenant.
   * @param spaceId The id of the space to be selected
   * @param spaceName the name of the space to be selected.
   * @param currentlyViewingSpaceId The id of the space that is currently being traversed
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder
   */
  function handleSpaceSelectToggle(
    clientKey: string,
    spaceId: string,
    spaceName: string,
    currentlyViewingSpaceId: string | undefined,
    currentBreadCrumbs: FolderBreadCrumbs
  ) {
    const folderPath = pathStringFromBreadCrumbs({
      breadCrumbs: currentBreadCrumbs,
      delimiter: CONFLUENCE_SELECTION_MAP_PATH_DELIMITER,
    });

    const clientKeySpaceId = `${clientKey}${CONFLUENCE_SELECTION_MAP_PATH_DELIMITER}${spaceId}`;

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      let select = true;
      if (!prevSelectedFileMap[clientKeySpaceId]) {
        prevSelectedFileMap[clientKeySpaceId] = {
          selected: select,
          folders: {},
          name: spaceName,
        };
      } else {
        select = !prevSelectedFileMap[clientKeySpaceId]!.selected;
        prevSelectedFileMap[clientKeySpaceId]!.selected = select;
      }

      if (spaceId === currentlyViewingSpaceId && folderPath) {
        // If user is viewing a folder in the site to be selected
        // Set all entries in the current folder to selected (all contained files)
        Object.keys(
          prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!.files
        ).forEach((key) => {
          prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!.files[
            key
          ] = select;
        });

        // Set the folder itself to selected
        prevSelectedFileMap[clientKeySpaceId]!.folders[folderPath]!.selected =
          select;
      }

      // Set all folders of the site that are already present in the selectedFileMap to selected
      Object.keys(prevSelectedFileMap[clientKeySpaceId]!.folders).forEach(
        (key) => {
          if (key !== 'selected') {
            prevSelectedFileMap[clientKeySpaceId]!.folders[key]!.selected =
              select;
          }
        }
      );

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Clears the selectedFileMap.
   */
  function clearSelectedFileMap() {
    onUpdateSelectedFileMap({});
  }

  return {
    contentItems,
    loading,
    currentSpace,
    breadCrumbs,
    setCurrentSpace: handleChangeCurrentSpace,
    handleFolderSelectToggle,
    handleFileSelectToggle,
    handleGoToFolder,
    handleGoBackInCrumbs,
    handleSelectAll,
    handleDeselectAll,
    handleSpaceSelectToggle,
    refetchCurrentFolderContents,
    clearSelectedFileMap,
  };
}
