import {
  BreadcrumbEllipsis,
  BreadcrumbLink,
  BreadcrumbRoot,
} from '@/components/ui/breadcrumb';

import { decodeName } from 'common-ts';
import { Tooltip } from '../../../components/ui/tooltip';

type SharepointBreadCrumbsProps = {
  className?: string;
  folders: { id: string; name: string }[];
  onFolderSelect: (index: number) => void;
};

const MAX_LENGTH = 4;

function SharepointBreadCrumbs({
  className,
  folders,
  onFolderSelect,
}: SharepointBreadCrumbsProps) {
  const shouldShowEllipsis = folders.length > MAX_LENGTH;

  const displayFolders = shouldShowEllipsis
    ? [
        folders[0]!, // root folder, not null considering we check for length > 4
        { id: 'ellipsis', name: '' }, // placeholder for ellipsis
        ...folders.slice(folders.length - 2), // last two folders
      ]
    : folders;

  return (
    <BreadcrumbRoot separator={'>'} className={className}>
      {displayFolders.map((folder, index) => {
        if (folder.id === 'ellipsis') {
          return <BreadcrumbEllipsis key="ellipsis" />;
        }

        let originalIndex;
        if (shouldShowEllipsis) {
          if (index === 0) {
            originalIndex = 0;
          } else {
            originalIndex = folders.length - (displayFolders.length - index);
          }
        } else {
          originalIndex = index;
        }

        return (
          <BreadcrumbLink
            key={`${folder.id}_${originalIndex}`}
            as={'div'}
            className="cursor-pointer"
            onClick={() => onFolderSelect(originalIndex)}
          >
            <Tooltip content={decodeName(folder.name)}>
              <span className="w-full max-w-[400px] truncate whitespace-nowrap">
                {decodeName(folder.name)}
              </span>
            </Tooltip>
          </BreadcrumbLink>
        );
      })}
    </BreadcrumbRoot>
  );
}

export default SharepointBreadCrumbs;
