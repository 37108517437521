import SubscriptionCard, { SubscriptionCardProps } from './SubscriptionCard';

import { ROUTES } from 'common-ts';
import { fetchApi } from '@/utils/useApi';
import { useBoundStore } from '@/store/useBoundStore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toaster } from '../ui/toaster';

type Tier = Omit<SubscriptionCardProps, 'isActive' | 'isLoaded'>;

type SubscriptionTableProps = {
  isEligibleForTrial: boolean | undefined;
};

export default function SubscriptionTable({
  isEligibleForTrial,
}: SubscriptionTableProps) {
  const { t } = useTranslation();
  const licenseType = useBoundStore((state) => state.workspaceLicenseType);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const supabase = useBoundStore((state) => state.supabase);

  const navigate = useNavigate();

  async function handleCustomerPortalRedirect() {
    const portalRes = await fetchApi(supabase, '/payment', '/load_portal', {
      method: 'POST',
      returnUrl: `${window.location.origin}${ROUTES.HOME.SETTINGS.WORKSPACE.LICENSE.$buildPath({ params: { workspaceId } })}`,
      workspaceId,
    });
    if (portalRes.success) {
      window.location.assign(portalRes.data.url);
    } else {
      toaster.create({
        title: t('general.tryAgainError'),
        type: 'error',
      });
    }
  }

  const tiers: Tier[] = [
    {
      title: t('pricingTable.tiers.basic.title'),
      price: t('pricingTable.tiers.basic.price'),
      priceDescription: t('pricingTable.tiers.basic.priceDescription'),
      features: t('pricingTable.tiers.basic.features', { returnObjects: true }),
      targetAudience: t('pricingTable.tiers.basic.targetAudience'),
      subtitle: t('pricingTable.tiers.basic.subtitle'),
      includes: t('pricingTable.tiers.basic.includes'),
      ctaButtonLabel:
        licenseType === 'FREE'
          ? isEligibleForTrial
            ? t('pricingTable.tiers.basic.ctaButtonLabel')
            : 'Upgrade'
          : t('pricingTable.changePlan'),
      onConfirm:
        licenseType === 'FREE'
          ? () => {
              navigate(
                ROUTES.HOME.ONBOARDING.$buildPath({ params: { workspaceId } }),
                {
                  state: ROUTES.HOME.ONBOARDING.$buildState({
                    state: { isTrial: isEligibleForTrial, plan: 'BASIC' },
                  }),
                }
              );
            }
          : handleCustomerPortalRedirect,
    },
    {
      title: t('pricingTable.tiers.professional.title'),
      price: t('pricingTable.tiers.professional.price'),
      priceDescription: t('pricingTable.tiers.professional.priceDescription'),
      features: t('pricingTable.tiers.professional.features', {
        returnObjects: true,
      }),
      targetAudience: t('pricingTable.tiers.professional.targetAudience'),
      subtitle: t('pricingTable.tiers.professional.subtitle'),
      includes: t('pricingTable.tiers.professional.includes'),
      ctaButtonLabel:
        licenseType === 'FREE'
          ? isEligibleForTrial
            ? t('pricingTable.tiers.professional.ctaButtonLabel')
            : 'Upgrade'
          : t('pricingTable.changePlan'),
      onConfirm:
        licenseType === 'FREE'
          ? () => {
              navigate(
                ROUTES.HOME.ONBOARDING.$buildPath({ params: { workspaceId } }),
                {
                  state: ROUTES.HOME.ONBOARDING.$buildState({
                    state: {
                      isTrial: isEligibleForTrial,
                      plan: 'PROFESSIONAL',
                    },
                  }),
                }
              );
            }
          : handleCustomerPortalRedirect,
    },
    {
      title: t('pricingTable.tiers.enterprise.title'),
      price: t('pricingTable.tiers.enterprise.price'),
      priceDescription: t('pricingTable.tiers.enterprise.priceDescription'),
      features: t('pricingTable.tiers.enterprise.features', {
        returnObjects: true,
      }),
      targetAudience: t('pricingTable.tiers.enterprise.targetAudience'),
      subtitle: t('pricingTable.tiers.enterprise.subtitle'),
      includes: t('pricingTable.tiers.enterprise.includes'),
      ctaButtonLabel: t('pricingTable.tiers.enterprise.ctaButtonLabel'),
    },
  ];

  return (
    <div className="mx-auto grid w-max grid-cols-1 grid-rows-3 divide-x border border-solid border-gray-200 lg:grid-cols-3 lg:grid-rows-1">
      {tiers.map((tier, index) => (
        <SubscriptionCard
          isLoaded={isEligibleForTrial !== undefined}
          isActive={licenseType?.toLowerCase() === tier.title.toLowerCase()}
          key={index}
          {...tier}
        />
      ))}
    </div>
  );
}
