import { Group, IconButton, MenuSeparator } from '@chakra-ui/react';
import { LuCheck, LuChevronDown } from 'react-icons/lu';
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from '@/components/ui/menu';

import { Button } from '@/components/ui/button';
import { StorageSelectionStateHandlers } from '@/pages/chat/fileSelector/useStorageSelectionStateHandlers';
import { Tooltip } from '@/components/ui/tooltip';
import { useBoundStore } from '@/store/useBoundStore';
import { useTranslation } from 'react-i18next';

export type SelectOption = {
  label: string;
  value: 'all' | 'workspace' | 'shared' | 'personal';
  isSelected: boolean;
  toggleSelectFunction: (isSelected: boolean) => void;
  isDisabled: boolean;
};

type DataButtonProps = {
  showSelectFilesWaring: boolean;
  onOpenFileSelector: () => void;
  storageSelectionStateHandlers: StorageSelectionStateHandlers;
};

const DataButton = ({
  showSelectFilesWaring,
  onOpenFileSelector,
  storageSelectionStateHandlers,
}: DataButtonProps) => {
  const { t } = useTranslation();
  const extendedBuckets = useBoundStore((state) => state.extendedBuckets);

  return (
    <Tooltip
      content={t('chat.noFilesSelectedInfo')}
      open={showSelectFilesWaring}
      disabled
      showArrow
    >
      <Group attached>
        <Button
          className="text-maia-text-dark font-medium"
          aria-label="select-data"
          variant="plain"
          onClick={onOpenFileSelector}
        >
          {t('chat.fileSelector.button')}
        </Button>
        <MenuRoot closeOnSelect={false}>
          <MenuTrigger asChild>
            <IconButton variant="plain" size="lg">
              <LuChevronDown />
            </IconButton>
          </MenuTrigger>
          <MenuContent>
            <MenuItem
              value={'all'}
              onClick={() => {
                storageSelectionStateHandlers.handleToggleSelectAllCollections(
                  !storageSelectionStateHandlers.isAllCollectionsSelected
                );
              }}
              className="font-medium"
            >
              <span className="flex w-full items-center justify-between">
                <span>{t('chat.fileSelector.quickSelectOptions.all')}</span>
                <span className="ml-2 w-6">
                  {storageSelectionStateHandlers.isAllCollectionsSelected && (
                    <LuCheck />
                  )}
                </span>
              </span>
            </MenuItem>
            <MenuSeparator />
            <MenuItem
              value={'personal'}
              onClick={() => {
                storageSelectionStateHandlers.handleToggleSelectAllPersonalCollections(
                  !storageSelectionStateHandlers.isAllPersonalCollectionsSelected
                );
              }}
              className="font-medium"
              disabled={
                !extendedBuckets.find(
                  (bucket) =>
                    !bucket.is_shared_to_user && !bucket.is_public_for_workspace
                )
              }
            >
              <span className="flex w-full items-center justify-between">
                <span>
                  {t('chat.fileSelector.quickSelectOptions.personal')}
                </span>
                <span className="ml-2 w-6">
                  {storageSelectionStateHandlers.isAllPersonalCollectionsSelected && (
                    <LuCheck />
                  )}
                </span>
              </span>
            </MenuItem>
            <MenuItem
              value={'shared'}
              onClick={() => {
                storageSelectionStateHandlers.handleToggleSelectAllSharedCollections(
                  !storageSelectionStateHandlers.isAllSharedCollectionsSelected
                );
              }}
              className="font-medium"
              disabled={
                !extendedBuckets.find(
                  (bucket) =>
                    bucket.is_shared_to_user && !bucket.is_public_for_workspace
                )
              }
            >
              <span className="flex w-full items-center justify-between">
                <span>{t('chat.fileSelector.quickSelectOptions.shared')}</span>
                <span className="ml-2 w-6">
                  {storageSelectionStateHandlers.isAllSharedCollectionsSelected && (
                    <LuCheck />
                  )}
                </span>
              </span>
            </MenuItem>
            <MenuItem
              value={'workspace'}
              onClick={() => {
                storageSelectionStateHandlers.handleToggleSelectAllWorkspaceCollections(
                  !storageSelectionStateHandlers.isAllWorkspaceCollectionsSelected
                );
              }}
              className="font-medium"
              disabled={
                !extendedBuckets.find(
                  (bucket) => bucket.is_public_for_workspace
                )
              }
            >
              <span className="flex w-full items-center justify-between">
                <span>
                  {t('chat.fileSelector.quickSelectOptions.workspace')}
                </span>
                <span className="ml-2 w-6">
                  {storageSelectionStateHandlers.isAllWorkspaceCollectionsSelected && (
                    <LuCheck />
                  )}
                </span>
              </span>
            </MenuItem>
          </MenuContent>
        </MenuRoot>
      </Group>
    </Tooltip>
  );
};

export default DataButton;
