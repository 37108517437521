import { Badge, IconButton } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import IntegrationStatusTable from './IntegrationStatusTable';
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from '../../components/ui/menu';
import { SharepointSiteStatus } from 'common-ts';

type SharepointSiteStatusTableProps = {
  sites: SharepointSiteStatus[];
  onDisconnectClick: (sharepointSiteId: string) => void;
};

function SharepointSiteStatusTable({
  sites,
  onDisconnectClick,
}: SharepointSiteStatusTableProps) {
  const { t } = useTranslation();

  const statusTranslationMap = {
    PENDING: t(
      'integrationSettings.sharepoint.connectedSitesTable.statusBadge.pending'
    ),
    'IN PROGRESS': t(
      'integrationSettings.sharepoint.connectedSitesTable.statusBadge.inProgress'
    ),
    SYNCED: t(
      'integrationSettings.sharepoint.connectedSitesTable.statusBadge.synced'
    ),
    FAILED: t(
      'integrationSettings.sharepoint.connectedSitesTable.statusBadge.failed'
    ),
  };

  return (
    <IntegrationStatusTable<SharepointSiteStatus>
      items={sites}
      getKey={(site) => site.id}
      headerLabels={{
        name: t('integrationSettings.sharepoint.connectedSitesTable.site'),
        syncedFiles: t(
          'integrationSettings.sharepoint.connectedSitesTable.syncedFiles'
        ),
        totalFiles: t(
          'integrationSettings.sharepoint.connectedSitesTable.totalFiles'
        ),
        usedStorage: t(
          'integrationSettings.sharepoint.connectedSitesTable.usedStorage'
        ),
        status: t('integrationSettings.sharepoint.connectedSitesTable.status'),
      }}
      renderName={(site) => site.name}
      renderSyncedFiles={(site) =>
        site.status === 'PENDING' ? '?' : site.syncedFiles
      }
      renderTotalFiles={(site) =>
        site.status === 'PENDING' ? '?' : site.totalFiles
      }
      renderUsedStorage={(site) => `${site.usedStorage.toFixed(2)}MB`}
      renderStatus={(site) => (
        <Badge
          size="sm"
          className={`font-bold ${
            site.status === 'IN PROGRESS'
              ? 'bg-maia-support-yellow'
              : site.status === 'FAILED'
                ? 'bg-maia-support-red'
                : site.status === 'PENDING'
                  ? 'bg-chakra-gray-400'
                  : 'bg-maia-support-green'
          } text-xs text-white`}
        >
          {statusTranslationMap[site.status]}
        </Badge>
      )}
      renderAction={(site) => (
        <MenuRoot>
          <MenuTrigger>
            <IconButton
              size="sm"
              variant="ghost"
              aria-label="Sharepoint site settings"
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </IconButton>
          </MenuTrigger>
          <MenuContent className="min-w-[130px]">
            <MenuItem
              value="disconnect"
              onClick={() => onDisconnectClick(site.siteId)}
            >
              {t(
                'integrationSettings.sharepoint.connectedSitesTable.disconnect'
              )}
            </MenuItem>
          </MenuContent>
        </MenuRoot>
      )}
    />
  );
}

export default SharepointSiteStatusTable;
