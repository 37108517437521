import { faTrash, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';

import AccessControl from '../../../components/AccessControl';
import { Button } from '@/components/ui/button';
import ContentHeader from '../../../components/ContentHeader';
import DeleteModal from '../../../components/DeleteModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GlossaryUploadButton from './GlossaryUploadButton';
import MaiaFullGridWidthContent from '../../../components/layout/MaiaFullGridWidthContent';
import MaiaMainGrid from '../../../components/layout/MaiaMainGrid';
import { Skeleton } from '@chakra-ui/react';
import SortableTable from '../../../components/SortableTable/SortableTable';
import { captureException } from '@sentry/react';
import { fetchApi } from '../../../utils/useApi';
import { toaster } from '../../../components/ui/toaster';
import { useBoundStore } from '../../../store/useBoundStore';
import { useTranslation } from 'react-i18next';

function GlossarySettings() {
  const { t } = useTranslation();
  const workspaceName = useBoundStore((state) => state.workspaceName);
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const [glossaryLoading, setGlossaryLoading] = useState(false);
  const [glossaryItems, setGlossaryItems] = useState<
    { key: string; value: string }[]
  >([]);

  const [showDeleteGlossaryModal, setShowDeleteGlossaryModal] = useState(false);

  async function fetchGlossaryEntries() {
    setGlossaryLoading(true);
    const { data, error } = await supabase
      .from('glossary')
      .select('key, value')
      .eq('workspace_id', workspaceId);

    if (error) {
      captureException(error);
      toaster.create({ type: 'error', title: t('general.reloadError') });
      setGlossaryLoading(false);
      return;
    }

    setGlossaryItems(data);
    setGlossaryLoading(false);
  }

  async function handleDeleteGlossary() {
    setGlossaryLoading(true);
    const { success } = await fetchApi(
      supabase,
      '/settings',
      '/delete_glossary',
      {
        method: 'POST',
        workspaceId,
      }
    );

    if (!success) {
      toaster.create({
        title: t('general.tryAgainError'),
        type: 'error',
      });
    }

    await fetchGlossaryEntries();
  }

  useEffect(() => {
    fetchGlossaryEntries();
  }, []);

  return (
    <MaiaMainGrid>
      <MaiaFullGridWidthContent className="mb-4 flex flex-col gap-4">
        <ContentHeader
          headingLabel={t('workspaceSettings.menu.glossary')}
          icon={faUsers}
          iconLabel={workspaceName}
          buttons={[
            <AccessControl allowedRoles={['ADMIN', 'OWNER']}>
              <Button
                colorPalette="red"
                disabled={!glossaryItems.length}
                onClick={() => {
                  setShowDeleteGlossaryModal(true);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
                {t('workspaceSettings.glossarySettings.deleteGlossaryButton')}
              </Button>
            </AccessControl>,
            <AccessControl allowedRoles={['ADMIN', 'OWNER']}>
              <GlossaryUploadButton
                onSuccess={() => {
                  fetchGlossaryEntries();
                }}
              />
            </AccessControl>,
          ]}
        />
        <Skeleton loading={glossaryLoading} className="flex-grow">
          <SortableTable
            objects={glossaryItems}
            customColumns={{
              key: {
                title: t(
                  'workspaceSettings.glossarySettings.glossaryTable.keyHeader'
                ),
                cellValue: (o) => o.key,
              },
              value: {
                title: t(
                  'workspaceSettings.glossarySettings.glossaryTable.valueHeader'
                ),
                cellValue: (o) => o.value,
              },
            }}
          />
        </Skeleton>
        <DeleteModal
          isOpen={showDeleteGlossaryModal}
          onClose={() => {
            setShowDeleteGlossaryModal(false);
          }}
          onConfirm={() => {
            handleDeleteGlossary();
            setShowDeleteGlossaryModal(false);
          }}
          title={t(
            'workspaceSettings.glossarySettings.deleteGlossaryModal.title'
          )}
          subtitle={t(
            'workspaceSettings.glossarySettings.deleteGlossaryModal.deleteGlossaryWarning'
          )}
        />
      </MaiaFullGridWidthContent>
    </MaiaMainGrid>
  );
}

export default GlossarySettings;
