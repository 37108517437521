import { Separator, Spinner, Table } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import AccessControl from '../../components/AccessControl';
import { Button } from '@/components/ui/button';
import BuyAdaPagesButton from '../../components/ada/BuyAdaPagesButton';
import ContentHeader from '../../components/ContentHeader';
import MaiaFullGridWidthContent from '../../components/layout/MaiaFullGridWidthContent';
import MaiaMainGrid from '../../components/layout/MaiaMainGrid';
import { ROUTES } from 'common-ts';
import SettingsSection from '../settings/SettingsSection';
import { captureException } from '@sentry/react';
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { fetchApi } from '@/utils/useApi';
import { handleWorkspaceRenew } from '@/utils/handleSubscription';
import { toaster } from '@/components/ui/toaster';
import { useBoundStore } from '@/store/useBoundStore';
import { useTranslation } from 'react-i18next';

const PROFESSIONAL_PRICE = 39;
const BASIC_PRICE = 19;

const numFormat = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR',
});

function LicenseManagement() {
  const supabase = useBoundStore((state) => state.supabase);
  const stripe = useBoundStore((state) => state.stripe);
  const stripeLoading = useBoundStore((state) => state.stripeLoading);
  const isInFreeTrial = useBoundStore((state) => state.isInFreeTrial);

  const { t } = useTranslation();
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const workspaceInactive = useBoundStore((state) => state.workspaceInactive);

  const [portalLoading, setPortalLoading] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const [currentNumSeats, setCurrentNumSeats] = useState(0);
  const [desiredNumSeats, setDesiredNumSeats] = useState(0);
  const [workspaceName, setWorkspaceName] = useState('');

  useEffect(() => {
    async function fetchWorkspaceInfo() {
      const { data, error } = await supabase
        .from('workspace')
        .select()
        .eq('id', workspaceId);

      if (error || !data?.[0]) {
        captureException(
          error ??
            'Error fetching workspace info, but supabase did not return an error'
        );
        toaster.create({ title: t('general.reloadError'), type: 'error' });
        return;
      }

      setCurrentNumSeats(data[0].total_seats);
      setDesiredNumSeats(data[0].total_seats);
      setWorkspaceName(data[0].name);
    }

    fetchWorkspaceInfo();
  }, []);

  const price = isInFreeTrial
    ? 0
    : workspaceLicenseType === 'PROFESSIONAL'
      ? PROFESSIONAL_PRICE
      : workspaceLicenseType === 'BASIC'
        ? BASIC_PRICE
        : 0;

  return (
    <MaiaMainGrid>
      <MaiaFullGridWidthContent className="flex flex-col gap-4">
        <ContentHeader
          headingLabel={t('userSettings.menu.license')}
          icon={faUsers}
          buttons={[]}
          iconLabel={workspaceName}
        />
        <SettingsSection>
          {t('workspaceSettings.licenseManagement.title')}
        </SettingsSection>
        <div className="border-maia-border rounded-xl border">
          <Table.Root variant="line">
            <Table.Header>
              <Table.Row className="font-semibold">
                <Table.ColumnHeader color={'messenger.900'}>
                  {t('workspaceSettings.licenseManagement.plan')}
                </Table.ColumnHeader>
                <Table.ColumnHeader>
                  {t('workspaceSettings.licenseManagement.price')}
                </Table.ColumnHeader>
                <Table.ColumnHeader>
                  {t('workspaceSettings.licenseManagement.seats')}
                </Table.ColumnHeader>
                <Table.ColumnHeader>
                  {t('workspaceSettings.licenseManagement.nextPriceEstimate')}
                </Table.ColumnHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body className="text-maia-text-dark">
              <Table.Row>
                <Table.Cell className="font-medium">
                  {workspaceLicenseType
                    ? workspaceLicenseType.charAt(0).toUpperCase() +
                      workspaceLicenseType.slice(1).toLowerCase()
                    : '-'}
                  <br />
                  {isInFreeTrial ? (
                    <span className="text-xs font-normal">
                      {`${t('workspaceSettings.licenseManagement.freeTrial')} ${Math.ceil((new Date(isInFreeTrial).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24)} ${t('workspaceSettings.licenseManagement.daysLeft')}`}
                    </span>
                  ) : null}
                </Table.Cell>
                <Table.Cell>{numFormat.format(price)}</Table.Cell>
                <Table.Cell>{currentNumSeats}</Table.Cell>
                <Table.Cell>
                  {numFormat.format(price * desiredNumSeats)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="font-medium">
                  {t('workspaceSettings.licenseManagement.total')}
                </Table.Cell>
                <Table.Cell />
                <Table.Cell />
                <Table.Cell>
                  {numFormat.format(price * desiredNumSeats)}
                  <br />
                  {`(${t('workspaceSettings.licenseManagement.excludingTax')})`}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table.Root>
        </div>
        {!!workspaceInactive && (
          <Button
            loading={checkoutLoading || stripeLoading}
            className="self-start"
            colorPalette="maia-accent"
            onClick={() => {
              setCheckoutLoading(true);
              handleWorkspaceRenew(workspaceId, stripe!, supabase).then(() => {
                setCheckoutLoading(false);
              });
            }}
          >
            {t('workspaceSettings.licenseManagement.renewSubscription')}
          </Button>
        )}
        <AccessControl allowedLicenses={['PROFESSIONAL']}>
          <BuyAdaPagesButton
            className="self-start"
            redirectTo={ROUTES.HOME.SETTINGS.WORKSPACE.LICENSE.$buildPath({
              params: { workspaceId },
            })}
            withInfoTooltip
          />
        </AccessControl>
        <div className="flex items-center gap-1">
          <Button
            variant={'outline'}
            onClick={async () => {
              setPortalLoading(true);
              const portalRes = await fetchApi(
                supabase,
                '/payment',
                '/load_portal',
                {
                  method: 'POST',
                  returnUrl: window.location.href,
                  workspaceId,
                }
              );
              setPortalLoading(false);
              if (portalRes.success) {
                window.location.assign(portalRes.data.url);
              } else {
                toaster.create({
                  title: t('general.tryAgainError'),
                  type: 'error',
                });
              }
            }}
          >
            {t('workspaceSettings.licenseManagement.stripeLink')}
          </Button>
          {portalLoading && <Spinner size={'sm'} />}
        </div>
        <Separator />
        <div className="text-xs">
          {t('workspaceSettings.licenseManagement.invoiceInfo')}{' '}
          <a className="underline" href="mailto:info@prodlane.io">
            info@prodlane.io
          </a>
        </div>
      </MaiaFullGridWidthContent>
    </MaiaMainGrid>
  );
}

export default LicenseManagement;
