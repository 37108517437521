import { Input, Link, Text } from '@chakra-ui/react';
import {
  ProgressCircleRing,
  ProgressCircleRoot,
} from '../../components/ui/progress-circle.js';
import { ValidationError, boolean, object, ref, string } from 'yup';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Alert } from '../../components/ui/alert.js';
import { Button } from '../../components/ui/button.js';
import { Checkbox } from '../../components/ui/checkbox.js';
import { Field } from '../../components/ui/field.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PasswordInput } from '@/components/ui/password-input.js';
import { captureException } from '@sentry/react';
import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons';
import i18n from '../../i18n';
import { supportedLanguages } from 'common-ts';
import { t } from 'i18next';
import { useBoundStore } from '../../store/useBoundStore.js';

type RegisterError = {
  email?: string;
  newPassword?: string;
  repeatPassword?: string;
  general?: string;
  agreeToTerms?: string;
};

type SignupFormProps = {
  redirectToSignin: () => void;
};

export default function SignupForm({ redirectToSignin }: SignupFormProps) {
  const supaBaseClient = useBoundStore((state) => state.supabase);

  const [currentLanguage, setCurrentLanguage] = useState(
    supportedLanguages.find((lang) => lang.short === i18n.language)
  );

  const regEmail = useRef('');
  const newPassword = useRef('');
  const repeatPassword = useRef('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const [registerError, setRegisterError] = useState<RegisterError>({});
  const [loading, setLoading] = useState(false);
  const [showVerificationPrompt, setShowVerificationPrompt] = useState(false);

  useEffect(() => {
    setCurrentLanguage(
      supportedLanguages.find((lang) => lang.short === i18n.language)
    );
  }, [i18n.language]);

  const registerSchema = useMemo(() => {
    return object().shape({
      email: string()
        .required(`${t('loginView.emptyMail')}`)
        .email(`${t('loginView.mailNotValid')}`), // optional and should be valid email if provided
      newPassword: string()
        .required(`${t('loginView.emptyPw')}`)
        .min(8, `${t('loginView.passwordTooShortError')}`), // optional and no specific validation if provided
      repeatPassword: string().oneOf(
        [ref('newPassword')],
        `${t('loginView.passwordMustMatchError')}`
      ),
      agreeToTerms: boolean().isTrue(`${t('loginView.agreeToTermsError')}`),
    });
  }, [t]);

  async function handleRegister(
    email: string,
    newPassword: string,
    repeatPassword: string
  ) {
    setLoading(true);
    setRegisterError({});
    try {
      await registerSchema.validate(
        {
          email,
          newPassword,
          repeatPassword,
          agreeToTerms,
        },
        {
          abortEarly: false,
        }
      );
    } catch (error) {
      if (error instanceof ValidationError) {
        const regErr: RegisterError = {};
        error.inner.forEach((element) => {
          regErr[element.path as keyof RegisterError] = element.message;
        });
        setRegisterError(regErr);
        setLoading(false);
        return;
      }
    }
    if (!supaBaseClient) {
      setRegisterError({
        general: `${t(`loginView.clientError`)}`,
      });
    } else {
      try {
        const registerResponse = await supaBaseClient.auth.signUp({
          email,
          password: newPassword,
        });

        if (registerResponse.error) {
          // TODO: Find a better way to handle various errors
          //  But therefore research has to happen to identify the important once
          if (
            registerResponse.error.message
              .toLowerCase()
              .includes('sign up with domain is blocked')
          ) {
            setRegisterError({
              general: t('loginView.domainBlocked'),
            });
          } else {
            setRegisterError({
              general: registerResponse.error.message,
            });
          }
          setLoading(false);
          return;
        }

        supaBaseClient.auth.startAutoRefresh();

        setShowVerificationPrompt(true);
      } catch (error) {
        captureException(error);
        setRegisterError({
          general: `${t('general.reloadError')}`,
        });
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (showVerificationPrompt) {
      setTimeout(() => {
        setShowVerificationPrompt(false);
        redirectToSignin();
      }, 10000);
    }
  }, [showVerificationPrompt]);
  return (
    <div>
      {showVerificationPrompt ? (
        <div className="my-8 flex flex-col items-center space-y-8 px-8">
          <Text fontSize={'2xl'}>
            {t('loginView.emailVerificationPrompt.title')}
          </Text>
          <Text color={'blue.400'}>
            <FontAwesomeIcon
              className="h-16 w-16 stroke-current"
              icon={faPaperPlane}
            />
          </Text>
          <Text
            className="text-center"
            onClick={() => setShowVerificationPrompt((prev) => !prev)}
          >
            {t('loginView.emailVerificationPrompt.text')}
          </Text>
        </div>
      ) : (
        <>
          {registerError.general && (
            <Alert status="error">{registerError.general}</Alert>
          )}
          <Field
            label="Email"
            invalid={!!registerError.email}
            errorText={t('loginView.emptyMail')}
          >
            <Input
              id="email"
              type="email"
              onChange={(v) => (regEmail.current = v.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleRegister(
                    regEmail.current,
                    newPassword.current,
                    repeatPassword.current
                  );
                }
              }}
              tabIndex={1}
            />
          </Field>
          <Field
            className="pt-2"
            label={t('loginView.password')}
            invalid={!!registerError.newPassword}
            errorText={registerError.newPassword}
          >
            <PasswordInput
              tabIndex={2}
              onChange={(e) => {
                newPassword.current = e.currentTarget.value;
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleRegister(
                    regEmail.current,
                    newPassword.current,
                    repeatPassword.current
                  );
                }
              }}
            />
          </Field>
          <Field
            className="pt-2"
            label={t('loginView.repeatPw')}
            invalid={!!registerError.repeatPassword}
            errorText={registerError.repeatPassword}
          >
            <PasswordInput
              tabIndex={3}
              onChange={(e) => {
                repeatPassword.current = e.currentTarget.value;
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleRegister(
                    regEmail.current,
                    newPassword.current,
                    repeatPassword.current
                  );
                }
              }}
            />
          </Field>
          <Field
            invalid={!!registerError.agreeToTerms}
            errorText={registerError.agreeToTerms}
          >
            <Checkbox
              className="pt-6 font-normal"
              size="sm"
              onCheckedChange={(e) =>
                setAgreeToTerms(e.checked === true ? e.checked : false)
              }
              checked={agreeToTerms}
              tabIndex={4}
            >
              <span className="font-normal">
                {t('loginView.agreeToMaiasTerms')}{' '}
              </span>
              <Link
                href={
                  currentLanguage?.short === 'de'
                    ? 'https://www.iubenda.com/nutzungsbedingungen/26153837'
                    : 'https://www.iubenda.com/terms-and-conditions/67199844'
                }
                className="font-normal text-blue-500"
              >
                {t('loginView.termsLinkText')}
              </Link>
              <span className="font-normal"> {t('loginView.and')} </span>
              <Link
                href={
                  currentLanguage?.short === 'de'
                    ? 'https://www.iubenda.com/privacy-policy/26153837'
                    : 'https://www.iubenda.com/privacy-policy/67199844'
                }
                className="font-normal text-blue-500"
              >
                {t('loginView.privacyLinkText')}
              </Link>
            </Checkbox>
          </Field>
          <div className="flex items-center justify-between pt-6">
            <div />
            {loading ? (
              <ProgressCircleRoot value={null}>
                <ProgressCircleRing cap="round" />
              </ProgressCircleRoot>
            ) : (
              <Button
                className="bg-maia-accent text-maia-text-light"
                onClick={() =>
                  handleRegister(
                    regEmail.current,
                    newPassword.current,
                    repeatPassword.current
                  )
                }
                tabIndex={5}
              >
                {t('loginView.register')}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
