import { Database, ROUTES } from 'common-ts';
import { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import { fetchApi } from '@/utils/useApi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTypedState } from 'react-router-typesafe-routes';
import { useBoundStore } from '@/store/useBoundStore';

type MfaMethod = Exclude<Database['public']['Enums']['mfa_type'], 'NONE'>;

export default function TwoFactorSelect() {
  const { email, password, inviteCode } = useTypedState(
    ROUTES.AUTH.TWO_FACTOR_SELECT
  );
  const supabase = useBoundStore((state) => state.supabase);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<MfaMethod | undefined>();

  useEffect(() => {
    if (!email || !password) {
      navigate(ROUTES.AUTH.SIGN_IN.$buildPath({}));
    }
  }, []);

  const handleMethodSelect = (mfaType: MfaMethod) => {
    setLoading(mfaType);

    fetchApi(
      supabase,
      '/auth',
      '/two_factor_challenge',
      {
        method: 'POST',
        email,
        password,
        mfaType,
      },
      true
    ).then((res) => {
      setLoading(undefined);

      if (res.status === 200 && res.data?.challengeId) {
        navigate(ROUTES.AUTH.TWO_FACTOR.$buildPath({}), {
          state: ROUTES.AUTH.TWO_FACTOR.$buildState({
            state: {
              email,
              password,
              challengeId: res.data.challengeId,
              type: mfaType,
              inviteCode,
            },
          }),
        });
      } else {
        navigate(ROUTES.AUTH.SIGN_IN.$buildPath({}));
      }
    });
  };

  return (
    <div className="flex flex-col items-center justify-center space-y-6">
      <span className="text-maia-gray-900">
        {t(`userSettings.auth.2FA.twoFactorSelectPrompt`)}
      </span>
      <div className="flex space-x-3">
        <Button
          variant={'solid'}
          colorPalette="maia-accent"
          size={'md'}
          onClick={() => handleMethodSelect('EMAIL')}
          disabled={loading && loading !== 'EMAIL'}
          loading={loading === 'EMAIL'}
        >
          E-Mail
        </Button>
        <Button
          variant={'solid'}
          colorPalette="maia-accent"
          size={'md'}
          onClick={() => handleMethodSelect('AUTHENTICATOR')}
          disabled={loading && loading !== 'AUTHENTICATOR'}
          loading={loading === 'AUTHENTICATOR'}
        >
          App
        </Button>
      </div>
    </div>
  );
}
