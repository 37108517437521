import { Box } from '@chakra-ui/react';
import AccessControl from '../../components/AccessControl';
import { Table } from '@chakra-ui/react';
import React from 'react';

export type IntegrationStatusTableProps<T> = {
  items: T[];
  getKey: (item: T) => string;
  headerLabels: {
    name: string;
    syncedFiles: string;
    totalFiles: string;
    usedStorage: string;
    status: string;
    syncedAt?: string;
    tenant?: string;
  };
  renderName: (item: T) => React.ReactNode;
  renderSyncedFiles: (item: T) => React.ReactNode;
  renderTotalFiles: (item: T) => React.ReactNode;
  renderUsedStorage: (item: T) => React.ReactNode;
  renderStatus: (item: T) => React.ReactNode;
  renderAction?: (item: T) => React.ReactNode;
  renderSyncedAt?: (item: T) => React.ReactNode;
  renderTenant?: (item: T) => React.ReactNode;
};

function IntegrationStatusTable<T>({
  items,
  getKey,
  headerLabels,
  renderName,
  renderSyncedFiles,
  renderTotalFiles,
  renderUsedStorage,
  renderStatus,
  renderAction,
  renderSyncedAt,
  renderTenant,
}: IntegrationStatusTableProps<T>) {
  return (
    <Box className="border-maia-border flex-shrink-0 rounded-xl border bg-white pb-1.5">
      <Table.Root variant="line">
        <Table.Header>
          <Table.Row className="font-semibold">
            <Table.ColumnHeader borderRadius="12px">
              {headerLabels.name}
            </Table.ColumnHeader>
            <Table.ColumnHeader>{headerLabels.syncedFiles}</Table.ColumnHeader>
            <Table.ColumnHeader>{headerLabels.totalFiles}</Table.ColumnHeader>
            <Table.ColumnHeader>{headerLabels.usedStorage}</Table.ColumnHeader>
            <Table.ColumnHeader>{headerLabels.status}</Table.ColumnHeader>
            {renderSyncedAt && headerLabels.syncedAt && (
              <Table.ColumnHeader>{headerLabels.syncedAt}</Table.ColumnHeader>
            )}
            {renderTenant && headerLabels.tenant && (
              <Table.ColumnHeader>{headerLabels.tenant}</Table.ColumnHeader>
            )}
            {renderAction && (
              <AccessControl allowedRoles={['ADMIN', 'OWNER']}>
                <Table.ColumnHeader borderRadius="12px" />
              </AccessControl>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body className="text-maia-text-dark">
          {items.map((item) => (
            <Table.Row key={getKey(item)}>
              <Table.Cell borderRadius="12px">{renderName(item)}</Table.Cell>
              <Table.Cell>{renderSyncedFiles(item)}</Table.Cell>
              <Table.Cell>{renderTotalFiles(item)}</Table.Cell>
              <Table.Cell>{renderUsedStorage(item)}</Table.Cell>
              <Table.Cell>{renderStatus(item)}</Table.Cell>
              {renderSyncedAt && (
                <Table.Cell>{renderSyncedAt(item)}</Table.Cell>
              )}
              {renderTenant && <Table.Cell>{renderTenant(item)}</Table.Cell>}
              {renderAction && (
                <AccessControl allowedRoles={['ADMIN', 'OWNER']}>
                  <Table.Cell>{renderAction(item)}</Table.Cell>
                </AccessControl>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </Box>
  );
}

export default IntegrationStatusTable;
