import { AvailableModelEnum } from 'common-ts';
import { StateCreator } from 'zustand';
import { WorkspaceInfoSlice } from '@/store/slices/createWorkspaceInfoSlice';
import { validateModelForPlan } from '../utils';

type LLMState = {
  llmModel: AvailableModelEnum;
};

type LLMAction = {
  setModel: (newModel: AvailableModelEnum) => void;
};

export type LLMSlice = LLMState & LLMAction;

export const initialLLMState: LLMState = {
  llmModel: AvailableModelEnum.GPT_4_O,
};

export const createLLMSlice: StateCreator<
  LLMSlice & WorkspaceInfoSlice,
  [],
  [],
  LLMSlice
> = (set, get) => ({
  ...initialLLMState,
  setModel: (newModel) => {
    const userPlan = get().workspaceLicenseType;
    const validNewModel = validateModelForPlan(userPlan, newModel);

    set({ llmModel: validNewModel });
  },
});
