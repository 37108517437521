import { useEffect, useState, useMemo, ChangeEvent } from 'react';
import { object, string, ref } from 'yup';
import { useNavigate, useSearchParams, Routes, Route } from 'react-router-dom';
import { ROUTES } from 'common-ts';
import { useBoundStore } from '../../store/useBoundStore';
import NotFoundPage from '../iframe/NotFoundComponent';
import { Input, VStack, Container } from '@chakra-ui/react';
import { navigateToDefaultWorkspace } from '../../utils/getDefaultWorkspace';
import { useTranslation } from 'react-i18next';
import { Field } from '../../components/ui/field';
import { Button } from '../../components/ui/button';
import { Alert } from '../../components/ui/alert';
import { toaster } from '../../components/ui/toaster';

type ResetPasswordError = {
  password?: string;
  confirmPassword?: string;
  general?: string;
};

/**
 * Reset password page. This page is used to reset the password of a user.
 * It's used when the user clicks on the link in the email sent to them.
 * The url of this page must contain the email and token parameters otherwise it will show a 404 page.
 */
export default function ResetPassword() {
  const supabase = useBoundStore((state) => state.supabase);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [resetError, setResetError] = useState<ResetPasswordError>({});
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const passwordSchema = useMemo(() => {
    return object().shape({
      password: string()
        .required(t('resetPasswordView.emptyPassword'))
        .min(8, t('resetPasswordView.passwordTooShort')),
      confirmPassword: string()
        .required(t('resetPasswordView.confirmRequired'))
        .oneOf([ref('password')], t('resetPasswordView.passwordMustMatch')),
    });
  }, [t]);

  useEffect(() => {
    const emailParam = searchParams.get('email');
    const tokenParam = searchParams.get('token');

    if (!emailParam || !tokenParam) {
      setResetError({
        general: t('resetPasswordView.invalidLink'),
      });
      return;
    }

    setEmail(emailParam);
    setToken(tokenParam);
  }, [searchParams]);

  const validateForm = async () => {
    try {
      await passwordSchema.validate(formData, { abortEarly: false });
      setResetError({});
      return true;
    } catch (error) {
      if (error instanceof Error) {
        const validationError = error as any;
        const errors: ResetPasswordError = {};
        validationError.inner.forEach((element: any) => {
          errors[element.path as keyof ResetPasswordError] = element.message;
        });
        setResetError(errors);
      }
      return false;
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setResetError({});

    const isValid = await validateForm();
    if (!isValid) return;

    setIsLoading(true);
    try {
      // Verify the token if it's not verified yet
      if (!isTokenVerified) {
        const { error: otpError } = await supabase.auth.verifyOtp({
          email,
          token,
          type: 'email',
        });

        if (otpError) {
          if (otpError.code === 'otp_expired') {
            setResetError({
              general: t('resetPasswordView.tokenExpired'),
            });
            setIsTokenExpired(true);
            setTimeout(() => {
              navigate(ROUTES.AUTH.FORGOT_PASSWORD.$buildPath({}));
            }, 2000);
            return;
          }
          throw otpError;
        }
        setIsTokenVerified(true);
      }

      // Update the user's password
      const { error: updateError } = await supabase.auth.updateUser({
        password: formData.password,
      });

      if (updateError) {
        // If the user tries to set the same password as the current one show an error
        if (updateError.code === 'same_password') {
          setResetError({
            general: t('resetPasswordView.samePassword'),
          });
          return;
        }
        throw updateError;
      }

      // Passwort changed successfully, navigate to the default workspace
      setIsSuccess(true);
      setTimeout(() => {
        navigateToDefaultWorkspace({
          navigateFn: navigate,
          toaster,
          supabase,
          translationFn: t,
        });
      }, 2000);
    } catch {
      setResetError({
        general: t('resetPasswordView.generalError'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!email || !token) {
    return (
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  }

  return (
    <Container maxW="md">
      <VStack gap={6} align="stretch">
        <h1 className="text-2xl font-bold">{t('resetPasswordView.title')}</h1>

        <form onSubmit={handleSubmit}>
          <VStack gap={4}>
            <Field
              invalid={!!resetError.password}
              label={t('resetPasswordView.newPassword')}
              errorText={resetError.password}
            >
              <Input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                disabled={isSuccess || isLoading || isTokenExpired}
              />
            </Field>

            <Field
              invalid={!!resetError.confirmPassword}
              label={t('resetPasswordView.confirmPassword')}
              errorText={resetError.confirmPassword}
            >
              <Input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                disabled={isSuccess || isLoading || isTokenExpired}
              />
            </Field>

            {resetError.general && (
              <Alert status="error">{resetError.general}</Alert>
            )}

            {isSuccess && (
              <Alert status="success">
                {t('resetPasswordView.successMessage')}
              </Alert>
            )}

            <Button
              type="submit"
              className="bg-maia-accent text-maia-text-light"
              width="100%"
              loading={isLoading}
              disabled={isLoading || isTokenExpired || isSuccess}
            >
              {t('resetPasswordView.submitButton')}
            </Button>
          </VStack>
        </form>
      </VStack>
    </Container>
  );
}
