import {
  ConfluenceFileSelectionMap,
  SharepointFileSelectionMap,
  StorageFileSelectionMap,
} from 'common-ts';
import { Separator, useBreakpointValue } from '@chakra-ui/react';
import { SetStateAction, useMemo, useState } from 'react';

import AccessControl from '../../../components/AccessControl.js';
import CollectionNavigator from './CollectionNavigator.js';
import ConfluenceLogo from '@/components/icons/ConfluenceLogo.js';
import { ConfluenceSelectionStateHandlers } from './useConfluenceSelectionStateHandlers.js';
import ConfluenceSpaceNavigator from './ConfluenceSpaceNavigator.js';
import { ErrorBoundary } from '@sentry/react';
import Feature from '@/components/Feature.js';
import ListItem from '../../../components/ListItem.js';
import SearchBar from '@/components/SearchBar.js';
import { SharePointSelectionStateHandlers } from '@/pages/chat/fileSelector/useSharepointSelectionStateHandlers';
import SharepointLogo from '../../../components/icons/SharepointLogo.js';
import SharepointSiteNavigator from './SharepointSiteNavigator.js';
import SharepointWaitlistInfo from '../../../components/sharepoint/SharepointWaitlistInfo.js';
import { StorageSelectionStateHandlers } from '@/pages/chat/fileSelector/useStorageSelectionStateHandlers';
import { Switch } from '@/components/ui/switch.js';
import { twMerge } from 'tailwind-merge';
import { useBoundStore } from '@/store/useBoundStore';
import { useTranslation } from 'react-i18next';

type Source =
  | 'OWN_COLLECTIONS'
  | 'SHARED'
  | 'WORKSPACE'
  | 'SHAREPOINT'
  | 'CONFLUENCE';

type FileSelectorProps = {
  className?: string;
  selectedSharepointFileMap: SharepointFileSelectionMap;
  selectedStorageFileMap: StorageFileSelectionMap;
  selectedConfluenceFileMap: ConfluenceFileSelectionMap;
  onUpdateSelectedSharepointFileMap: (
    updateValue: SetStateAction<SharepointFileSelectionMap>
  ) => void;
  onUpdateSelectedStorageFileMap: (
    updateValue: SetStateAction<StorageFileSelectionMap>
  ) => void;
  storageSelectionStateHandlers: StorageSelectionStateHandlers;
  sharePointSelectionStateHandlers: SharePointSelectionStateHandlers;
  confluenceSelectionStateHandlers: ConfluenceSelectionStateHandlers;
};

function FileSelector({
  className,
  selectedStorageFileMap,
  selectedSharepointFileMap,
  selectedConfluenceFileMap,
  onUpdateSelectedSharepointFileMap,
  onUpdateSelectedStorageFileMap,
  storageSelectionStateHandlers,
  sharePointSelectionStateHandlers,
  confluenceSelectionStateHandlers,
}: FileSelectorProps) {
  const { t } = useTranslation();
  const extendedBuckets = useBoundStore((state) => state.extendedBuckets);
  const featureFlags = useBoundStore((state) => state.featureFlags);

  const [searchTerm, setSearchTerm] = useState('');
  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [selectedSource, setSelectedSource] = useState<Source | undefined>(
    isMobile ? undefined : 'OWN_COLLECTIONS'
  );

  const [documentViewerActive, setDocumentViewerActive] = useState(false);

  function handleChangeSelectedSource(source: Source | undefined) {
    setSelectedSource(source);
    storageSelectionStateHandlers.updateCurrentCollection(undefined);
  }

  const yourCollections = useMemo(() => {
    return extendedBuckets.filter(
      (bucket) => !bucket.is_shared_to_user && !bucket.is_public_for_workspace
    );
  }, [extendedBuckets]);

  const sharedCollections = useMemo(() => {
    return extendedBuckets.filter(
      (bucket) => bucket.is_shared_to_user && !bucket.is_public_for_workspace
    );
  }, [extendedBuckets]);

  const workspaceCollections = useMemo(() => {
    return extendedBuckets.filter((bucket) => bucket.is_public_for_workspace);
  }, [extendedBuckets]);

  return (
    <div
      // claude here!
      className={twMerge(
        'flex flex-col gap-4 overflow-x-auto',
        // Mobile styles
        'h-[calc(100vh-2rem)] w-[calc(100vw-2rem)]',
        // Desktop styles
        'md:h-full md:w-full',
        className
      )}
    >
      <div className="flex items-center justify-between px-4 pt-4">
        <div className="flex items-center gap-16">
          <div className="text-lg font-bold">
            {t('chat.fileSelector.title')}
          </div>
          <Switch
            className={
              documentViewerActive
                ? 'opacity-100'
                : 'opacity-0 hover:opacity-20'
            }
            colorPalette={'maia-accent'}
            checked={documentViewerActive}
            onCheckedChange={(e) => setDocumentViewerActive(e.checked)}
          />
        </div>
        <SearchBar
          onSearchTermChange={setSearchTerm}
          className={`${!selectedSource || selectedSource === 'SHAREPOINT' || !storageSelectionStateHandlers.currentCollection ? 'invisible' : ''}`}
        />
      </div>
      <div className="flex-grow md:flex md:h-[515px]">
        {/* ---------------------------------------------------------------- Source selection ---------------------------------------------------------------- */}
        <div
          className={`border-maia-border flex min-w-72 flex-col gap-2 border-b border-r border-t p-4 ${selectedSource ? 'hidden md:flex' : ''}`}
        >
          {/* ------------------------------------------------ MAIA Data Manager ------------------------------------------------ */}
          <div className="font-medium">
            {t('chat.fileSelector.collectionsTitle')}
          </div>
          <ListItem
            className="cursor-pointer select-none"
            selected={selectedSource === 'OWN_COLLECTIONS'}
            variant="left"
            domElementProps={{
              onClick: () => {
                handleChangeSelectedSource('OWN_COLLECTIONS');
              },
            }}
          >
            {t('chat.fileSelector.yourCollections')}
          </ListItem>
          <ListItem
            className="cursor-pointer select-none"
            selected={selectedSource === 'SHARED'}
            variant="left"
            domElementProps={{
              onClick: () => {
                handleChangeSelectedSource('SHARED');
              },
            }}
          >
            {t('chat.fileSelector.sharedWithYou')}
          </ListItem>
          <ListItem
            className="cursor-pointer select-none"
            selected={selectedSource === 'WORKSPACE'}
            variant="left"
            domElementProps={{
              onClick: () => {
                handleChangeSelectedSource('WORKSPACE');
              },
            }}
          >
            {t('chat.fileSelector.workspaceCollections')}
          </ListItem>
          {/* ------------------------------------------------ Integrations ------------------------------------------------ */}
          <AccessControl
            allowedLicenses={['ENTERPRISE', 'PROFESSIONAL', 'BASIC']}
          >
            <Separator />
            <div className="font-medium">
              {t('chat.fileSelector.integrationsTitle')}
            </div>
            <ListItem
              className="cursor-pointer select-none"
              selected={selectedSource === 'SHAREPOINT'}
              variant="left"
              domElementProps={{
                onClick: () => {
                  handleChangeSelectedSource('SHAREPOINT');
                },
              }}
            >
              <div className="flex items-center gap-2">
                <SharepointLogo />
                <div>{t('integrationSettings.sharepoint.title')}</div>
              </div>
            </ListItem>
            <Feature name="confluence">
              <ListItem
                className="cursor-pointer select-none"
                selected={selectedSource === 'CONFLUENCE'}
                variant="left"
                domElementProps={{
                  onClick: () => {
                    handleChangeSelectedSource('CONFLUENCE');
                  },
                }}
              >
                <div className="flex items-center gap-2">
                  <ConfluenceLogo size={16} />
                  <div>{t('integrationSettings.confluence.title')}</div>
                </div>
              </ListItem>
            </Feature>
          </AccessControl>
        </div>
        <ErrorBoundary
          fallback={
            <div className="p-4">
              {t('chat.fileSelector.errorBoundaryInfo')}
            </div>
          }
        >
          <div
            className={`flex h-full flex-grow ${!selectedSource ? 'invisible md:visible' : ''}`}
          >
            {/* ---------------------------------------------------------------- Your collections ---------------------------------------------------------------- */}
            {selectedSource === 'OWN_COLLECTIONS' ? (
              <CollectionNavigator
                selectedCollection={
                  storageSelectionStateHandlers.currentCollection
                }
                searchTerm={searchTerm}
                collections={yourCollections}
                onUpdateSelectedFileMap={onUpdateSelectedStorageFileMap}
                selectedFileMap={selectedStorageFileMap}
                onBackClick={() => handleChangeSelectedSource(undefined)}
                storageSelectionStateHandlers={storageSelectionStateHandlers}
                documentViewerActive={documentViewerActive}
              />
            ) : /* ---------------------------------------------------------------- Shared with you ---------------------------------------------------------------- */
            selectedSource === 'SHARED' ? (
              <CollectionNavigator
                selectedCollection={
                  storageSelectionStateHandlers.currentCollection
                }
                searchTerm={searchTerm}
                collections={sharedCollections}
                onUpdateSelectedFileMap={onUpdateSelectedStorageFileMap}
                selectedFileMap={selectedStorageFileMap}
                onBackClick={() => handleChangeSelectedSource(undefined)}
                storageSelectionStateHandlers={storageSelectionStateHandlers}
                documentViewerActive={documentViewerActive}
              />
            ) : /* ---------------------------------------------------------------- Workspace ---------------------------------------------------------------- */
            selectedSource === 'WORKSPACE' ? (
              <CollectionNavigator
                selectedCollection={
                  storageSelectionStateHandlers.currentCollection
                }
                searchTerm={searchTerm}
                collections={workspaceCollections}
                onUpdateSelectedFileMap={onUpdateSelectedStorageFileMap}
                selectedFileMap={selectedStorageFileMap}
                onBackClick={() => handleChangeSelectedSource(undefined)}
                storageSelectionStateHandlers={storageSelectionStateHandlers}
                documentViewerActive={documentViewerActive}
              />
            ) : selectedSource === 'SHAREPOINT' ? (
              /* ---------------------------------------------------------------- Sharepoint ---------------------------------------------------------------- */
              featureFlags?.sharepoint ? (
                <SharepointSiteNavigator
                  className="h-full"
                  onBackClick={() => {
                    handleChangeSelectedSource(undefined);
                  }}
                  selectedFileMap={selectedSharepointFileMap}
                  onUpdateSelectedFileMap={onUpdateSelectedSharepointFileMap}
                  sharePointSelectionStateHandlers={
                    sharePointSelectionStateHandlers
                  }
                  documentViewerActive={documentViewerActive}
                />
              ) : (
                <SharepointWaitlistInfo className="border-maia-border border-b border-t p-4" />
              )
            ) : selectedSource === 'CONFLUENCE' ? (
              /* ---------------------------------------------------------------- Confluence ---------------------------------------------------------------- */
              featureFlags?.confluence ? (
                <ConfluenceSpaceNavigator
                  className="h-full"
                  onBackClick={() => handleChangeSelectedSource(undefined)}
                  selectedFileMap={selectedConfluenceFileMap}
                  selectionStateHandlers={confluenceSelectionStateHandlers}
                  documentViewerActive={documentViewerActive}
                />
              ) : (
                <div />
              )
            ) : (
              <div className="border-maia-border flex-grow border-b border-t" />
            )}
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default FileSelector;
