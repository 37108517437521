import GenericSelectableFileAndFolderList, {
  BasicFileInfo,
  BasicFolderInfo,
} from './GenericSelectableFileAndFolderList.js';
import {
  STORAGE_SELECTION_MAP_PATH_DELIMITER,
  StorageFileSelectionMap,
} from 'common-ts';
import { SetStateAction, useMemo } from 'react';

import SelectableFileSearchResultList from './SelectableFileSearchResultList.js';
import { StorageSelectionStateHandlers } from './useStorageSelectionStateHandlers.js';
import { pathStringFromBreadCrumbs } from './utils.js';

type SelectableCollectionFileListProps = {
  className?: string;
  totalFiles: number;
  searchTerm: string;
  selectedFileMap: StorageFileSelectionMap;
  selectedCollection: { name: string; id: string };
  documentViewerActive: boolean;
  onBackFromRootClick: () => void;
  onUpdateSelectedFileMap: (
    updateValue: SetStateAction<StorageFileSelectionMap>
  ) => void;
  storageSelectionStateHandlers: StorageSelectionStateHandlers;
};

function SelectableCollectionFileList({
  className,
  totalFiles,
  searchTerm,
  selectedFileMap,
  selectedCollection,
  documentViewerActive,
  onBackFromRootClick,
  storageSelectionStateHandlers,
}: SelectableCollectionFileListProps) {
  const fileList: BasicFileInfo[] = useMemo(() => {
    return storageSelectionStateHandlers.files.map((file) => {
      return {
        embeddingStatus:
          file.objectType === 'fileWithStrategy'
            ? file.embeddingStatus
            : 'PENDING',
        id: file.id,
        mimeType:
          file.objectType === 'fileWithStrategy'
            ? file.metadata.mimetype
            : file.type,
        name: file.name,
      };
    });
  }, [storageSelectionStateHandlers.files]);

  const folderList: BasicFolderInfo[] = useMemo(() => {
    return storageSelectionStateHandlers.folders.map((folder) => {
      return {
        id: folder.name,
        name: folder.name,
      };
    });
  }, [storageSelectionStateHandlers.folders]);

  return searchTerm ? (
    <SelectableFileSearchResultList
      className={className}
      selectedFileMap={selectedFileMap}
      collectionId={selectedCollection.id}
      searchTerm={searchTerm}
      onFileCheckboxClick={(fileId, filePath) => {
        const pathSplit =
          filePath === ''
            ? []
            : filePath.split(STORAGE_SELECTION_MAP_PATH_DELIMITER);
        storageSelectionStateHandlers.handleFileSelectToggle(
          pathSplit.map((folderName) => {
            return { id: folderName, name: folderName };
          }),
          fileId,
          selectedCollection.id
        );
      }}
      onSearchResultsLoaded={(searchResult) => {
        storageSelectionStateHandlers.handleSearchResultSelectionUpdates({
          searchResult,
          collectionId: selectedCollection.id,
        });
      }}
    />
  ) : (
    <GenericSelectableFileAndFolderList
      className={className}
      hideCounts
      documentViewerActive={documentViewerActive}
      folderMapPathFromBreadCrumbs={(breadCrumbs) =>
        pathStringFromBreadCrumbs({
          breadCrumbs,
          delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
        })
      }
      onRefreshClick={
        storageSelectionStateHandlers.refetchCurrentFolderContents
      }
      files={fileList}
      folders={folderList}
      breadCrumbs={storageSelectionStateHandlers.breadCrumbs}
      overallFileCount={totalFiles}
      loading={storageSelectionStateHandlers.loading}
      onBackClick={() => {
        if (storageSelectionStateHandlers.breadCrumbs.length) {
          storageSelectionStateHandlers.handleGoBackInCrumbs(
            storageSelectionStateHandlers.breadCrumbs.length - 2
          );
        } else {
          onBackFromRootClick();
        }
      }}
      onDeselectAllClick={() => {
        storageSelectionStateHandlers.handleDeselectAll(
          storageSelectionStateHandlers.breadCrumbs,
          selectedCollection.id
        );
      }}
      onFileCheckboxClick={(fileId) => {
        storageSelectionStateHandlers.handleFileSelectToggle(
          storageSelectionStateHandlers.breadCrumbs,
          fileId,
          selectedCollection.id
        );
      }}
      onFolderCheckboxClick={(folderId) => {
        storageSelectionStateHandlers.handleFolderSelectToggle(
          storageSelectionStateHandlers.breadCrumbs,
          folderId,
          selectedCollection.id
        );
      }}
      onFolderClick={({ folderId, name }) => {
        storageSelectionStateHandlers.handleGoToFolder({ folderId, name });
      }}
      onSelectAllClick={() => {
        storageSelectionStateHandlers.handleSelectAllInFolder(
          storageSelectionStateHandlers.breadCrumbs,
          selectedCollection.id
        );
      }}
      onSelectFolderInCrumbs={(index) => {
        storageSelectionStateHandlers.handleGoBackInCrumbs(index);
      }}
      selectedFileMap={selectedFileMap[selectedCollection.id]?.folders ?? {}}
    />
  );
}

export default SelectableCollectionFileList;
