import { AtlAuthFlowState } from '../../utils/msAuthFlow';
import { ROUTES } from 'common-ts';
import { captureException } from '@sentry/react';
import { fetchApi } from '../../utils/useApi';
import { toaster } from '../../components/ui/toaster';
import { useBoundStore } from '../../store/useBoundStore';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTypedSearchParams } from 'react-router-typesafe-routes';

function AtlassianOauthPage() {
  const { t } = useTranslation();
  const [searchParams] = useTypedSearchParams(ROUTES.ATL_OAUTH);
  const supabase = useBoundStore((state) => state.supabase);
  const navigate = useNavigate();

  useEffect(() => {
    async function handleAuthFlow() {
      let authFlowState: AtlAuthFlowState;
      try {
        authFlowState = JSON.parse(searchParams.state);
      } catch (error: any) {
        captureException(error);
        toaster.create({ type: 'error', title: t('general.reloadError') });
        return;
      }

      const res = await fetchApi(
        supabase,
        '/integrations',
        '/atlassian/login',
        {
          method: 'POST',
          code: searchParams.code,
          workspaceId: authFlowState.workspaceId,
        }
      );

      if (!res.success) {
        toaster.create({
          type: 'error',
          title: t('integrationSettings.confluence.atlLoginError'),
        });
      }

      navigate(authFlowState.redirectTo);
    }

    handleAuthFlow();
  }, []);

  return (
    <div className="flex flex-col items-center gap-4 px-8 py-4">
      <div>{t('oauth.redirectionInfo')}</div>
    </div>
  );
}

export default AtlassianOauthPage;
