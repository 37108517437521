import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '../../../components/ui/dialog.js';
import { useEffect, useRef, useState } from 'react';

import AccessControl from '../../../components/AccessControl.js';
import { Button } from '../../../components/ui/button.js';
import { Checkbox } from '../../../components/ui/checkbox.js';
import { Field } from '../../../components/ui/field.js';
import { Input } from '@chakra-ui/react';
import { fetchApi } from '../../../utils/useApi.js';
import { useBoundStore } from '../../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

type CreateCollectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onCollectionCreated?: (collectionId: string) => void;
};

function CreateCollectionModal({
  isOpen,
  onClose,
  onCollectionCreated,
}: CreateCollectionModalProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const collectionNameRef = useRef(t('fileManagerPanel.newCollectionName'));
  const [collectionNameError, setCollectionNameError] = useState(false);
  const [makeCollectionPublic, setMakeCollectionPublic] = useState(false);

  const [creatingCollectionLoading, setCreatingCollectionLoading] =
    useState(false);

  async function handleCreateNewCollection() {
    setCreatingCollectionLoading(true);
    if (!collectionNameRef.current) {
      setCollectionNameError(true);
      setCreatingCollectionLoading(false);
      return;
    }
    const bucketId = v4();
    setCollectionNameError(false);
    const { data } = await fetchApi(supabase, '/files', '/create_collection', {
      name: collectionNameRef.current,
      uuid: bucketId,
      method: 'POST',
      workspaceId,
      publicInWorkspace: makeCollectionPublic,
    });
    if (data?.id) onCollectionCreated?.(data.id);
    setCreatingCollectionLoading(false);

    onClose();
  }

  useEffect(() => {
    if (!isOpen) {
      collectionNameRef.current = t('fileManagerPanel.newCollectionName');
    }
  }, [isOpen]);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => !open && onClose()}
      motionPreset="slide-in-bottom"
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t('fileManagerPanel.newCollectionModalHeader')}
          </DialogTitle>
        </DialogHeader>{' '}
        <DialogCloseTrigger />
        <DialogBody
          className="flex flex-col gap-4"
          colorPalette={'maia-accent'}
        >
          <Field
            invalid={collectionNameError}
            label={t('fileManagerPanel.newCollectionModalInputLabel')}
            errorText={t('fileManagerPanel.newCollectionInputEmptyError')}
          >
            <Input
              autoFocus
              onChange={(e) => (collectionNameRef.current = e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCreateNewCollection();
                }
              }}
              defaultValue={collectionNameRef.current}
            />
          </Field>
          <AccessControl
            allowedLicenses={['PROFESSIONAL', 'ENTERPRISE']}
            allowedRoles={['ADMIN', 'OWNER']}
          >
            <Checkbox
              checked={makeCollectionPublic}
              onCheckedChange={({ checked }) => {
                setMakeCollectionPublic(
                  checked === 'indeterminate' ? false : checked
                );
              }}
            >
              {t('fileManagerPanel.makePublicWorkspaceCollection')}
            </Checkbox>
          </AccessControl>
        </DialogBody>
        <DialogFooter className="gap-2">
          <Button className="font-medium" onClick={onClose} variant="outline">
            {t('general.cancelButton')}
          </Button>
          <Button
            className="font-medium"
            colorPalette="maia-accent"
            onClick={handleCreateNewCollection}
            loading={creatingCollectionLoading}
          >
            {t('fileManagerPanel.newCollectionModalConfirmButton')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

export default CreateCollectionModal;
