import { NavigateFunction } from 'react-router-dom';
import { ROUTES } from 'common-ts';
import { SupabaseClient } from '@supabase/supabase-js';
import { getCurrentScope } from '@sentry/react';

/**
 * Logs the user out and navigates back to the signin page.
 * Also resets the sentry scope.
 */
export function logout(supabase: SupabaseClient, navigate: NavigateFunction) {
  supabase?.auth.signOut().then(() => {
    getCurrentScope().setUser(null);
    navigate(ROUTES.AUTH.SIGN_IN.$buildPath({}));
  });
}
