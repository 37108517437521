import { Button, ButtonProps } from '@chakra-ui/react';

import { ROUTES } from 'common-ts';
import { useBoundStore } from '../store/useBoundStore.js';
import { useNavigate } from 'react-router-dom';

export default function UpgradeSubscriptionButton(props: ButtonProps) {
  const navigate = useNavigate();

  const workspaceId = useBoundStore((state) => state.workspaceId);
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );

  return (
    workspaceLicenseType &&
    workspaceLicenseType === 'FREE' && (
      <Button
        {...props}
        className="h-6 px-2 text-sm"
        colorPalette="maia-purple"
        onClick={() => {
          navigate(ROUTES.HOME.PRICING.$buildPath({ params: { workspaceId } }));
        }}
      />
    )
  );
}
