import { Navigate, Route, Routes } from 'react-router-dom';

import ChatPage from '../chat/ChatPage';
import DocumentDebugView from '../documentView/DocumentDebugView';
import FileManager from '../fileManager/FileManager';
import OnboardingProcess from '../onboarding/OnboardingProcess';
import Pricing from '../pricing/Pricing';
import { ROUTES } from 'common-ts';
import Search from '../search/Search.js';
import Settings from '../settings/Settings.js';
import { fetchApi } from '@/utils/useApi';
import { useBoundStore } from '@/store/useBoundStore';
import { useEffect } from 'react';

function HomeRoutes() {
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const isOnboarded = useBoundStore((state) => state.isOnboarded);
  const featureFlags = useBoundStore((state) => state.featureFlags);

  useEffect(() => {
    // If sharepoint integration is activated, silently request permission sync
    async function requestPermissionSync() {
      await fetchApi(
        supabase,
        '/integrations',
        '/sharepoint/request_permission_sync',
        { method: 'POST', workspaceId }
      );
    }

    if (featureFlags?.sharepoint) {
      requestPermissionSync();
    }
  }, [workspaceId]);

  return (
    <Routes>
      {workspaceLicenseType !== 'FREE' && (
        <>
          <Route
            path=""
            element={
              <Navigate
                to={ROUTES.HOME.CHAT.$buildPath({ params: { workspaceId } })}
              />
            }
          />
          <Route
            path={ROUTES.HOME.$.CHAT.$path({ relative: true })}
            element={<ChatPage />}
          />
          <Route
            path={ROUTES.HOME.$.FILE_MANAGER.$path({ relative: true })}
            element={<FileManager />}
          />
          <Route
            path={ROUTES.HOME.$.SEARCH.$path({ relative: true })}
            element={<Search />}
          />
        </>
      )}
      <Route
        path={ROUTES.HOME.$.SETTINGS.$path({ relative: true })}
        element={<Settings />}
      />
      <Route
        path={ROUTES.HOME.$.PRICING.$path({ relative: true })}
        element={<Pricing />}
      />
      <Route
        path={ROUTES.HOME.$.ONBOARDING.$path({ relative: true })}
        element={<OnboardingProcess />}
      />
      <Route
        path={ROUTES.HOME.$.DOCUMENT_DEBUG_VIEW.$path({ relative: true })}
        element={<DocumentDebugView />}
      />
      <Route
        path="*"
        element={
          <Navigate
            to={
              workspaceLicenseType === 'FREE'
                ? isOnboarded
                  ? ROUTES.HOME.SETTINGS.$buildPath({
                      params: { workspaceId },
                    })
                  : ROUTES.HOME.PRICING.$buildPath({
                      params: { workspaceId },
                      searchParams: {
                        navBackOnClose:
                          ROUTES.HOME.SETTINGS.ACCOUNT.PERSONAL.$buildPath({
                            params: { workspaceId },
                          }),
                      },
                    })
                : ROUTES.HOME.CHAT.$buildPath({
                    params: { workspaceId },
                  })
            }
          />
        }
      />
    </Routes>
  );
}

export default HomeRoutes;
