import { create } from 'zustand';
import { createStripeSlice, StripeSlice } from './slices/createStripeSlice';
import {
  createSupabaseSlice,
  SupabaseSlice,
} from './slices/createSupabaseSlice';
import {
  createUserInfoSlice,
  UserInfoSlice,
} from './slices/createUserInfoSlice';
import {
  createWorkspaceInfoSlice,
  WorkspaceInfoSlice,
} from './slices/createWorkspaceInfoSlice';
import { createSharedSlice, SharedSlice } from './slices/createSharedSlice';
import { persist } from 'zustand/middleware';
import { createLLMSlice, LLMSlice } from '@/store/slices/createLLMSlice';

// Zustand recommends global state should be located in a single Zustand store.
// This is the main global store based on individual slices to achieve modularity and maintainability.
// more can be read here: https://docs.pmnd.rs/zustand/guides/slices-pattern

type Store = StripeSlice &
  SupabaseSlice &
  UserInfoSlice &
  WorkspaceInfoSlice &
  LLMSlice &
  SharedSlice;

export const PERSISTED_STORE_KEY = 'maia-bound-store';

export const useBoundStore = create<Store>()(
  persist(
    (...a) => ({
      ...createStripeSlice(...a),
      ...createSupabaseSlice(...a),
      ...createUserInfoSlice(...a),
      ...createWorkspaceInfoSlice(...a),
      ...createSharedSlice(...a),
      ...createLLMSlice(...a),
    }),
    {
      name: PERSISTED_STORE_KEY,
      partialize: (state) => ({ llmModel: state.llmModel }),
    }
  )
);
