import { ROUTES } from 'common-ts';
import { fetchApi } from './useApi';
import { SupabaseClient } from '@supabase/supabase-js';
import { navigateToDefaultWorkspace } from './getDefaultWorkspace.js';
import { CreateToasterReturn } from '@chakra-ui/react';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router-dom';

type Translate = TFunction<'translation', undefined>;

export async function handleJoin(
  inviteCode: string,
  supabase: SupabaseClient,
  translationFn: Translate,
  navigate: NavigateFunction,
  toaster: CreateToasterReturn
) {
  const { data: sessionData, error: sessionError } =
    await supabase.auth.getSession();

  if (sessionError) {
    console.error('Error getting session data', sessionError);
    return;
  }
  const { session } = sessionData;

  if (session) {
    const res = await fetchApi(supabase, '/workspace', '/join_with_code', {
      method: 'POST',
      code: inviteCode,
    });

    // If the user is not able to join the workspace, redirect to the default workspace
    if (!res.success) {
      let errorMsg = translationFn('general.tryAgainError');
      let errorStatus: 'error' | 'info' = 'error';
      if (res.status === 404) {
        errorMsg = translationFn('workspaceSettings.inviteLinkInvalidError');
      } else if (
        res.status === 500 &&
        res.data?.error &&
        res.data.error.toLowerCase().includes('duplicate')
      ) {
        errorMsg = translationFn(
          'workspaceSettings.inviteLinkAlreadyJoinedError'
        );
        errorStatus = 'info';
      }

      toaster.create({ title: errorMsg, type: errorStatus });
      navigateToDefaultWorkspace({
        navigateFn: navigate,
        toaster,
        supabase,
        translationFn,
      });
      return;
    }

    toaster.create({
      title: translationFn('workspaceSettings.inviteLinkSuccess'),
      type: 'success',
    });

    navigate(
      ROUTES.HOME.$buildPath({ params: { workspaceId: res.data.workspaceId } })
    );
  } else {
    toaster.create({
      title: translationFn('workspaceSettings.inviteLinkSessionNotFoundError'),
      type: 'info',
    });
    navigate(
      ROUTES.AUTH.SIGN_IN.$buildPath({
        searchParams: {
          inviteCode,
        },
      })
    );
  }
}
