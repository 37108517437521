type AuthFlowState = {
  workspaceId: string;
  redirectTo: string;
};

export type MSAuthFlowState = AuthFlowState & {
  action: 'activate' | 'userAuth';
};

export type AtlAuthFlowState = AuthFlowState & {
  state: string; // should be a hash of session id
};

function createAuthUrl(baseUrl: string, params: Record<string, string>): URL {
  const url = new URL(baseUrl);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });
  return url;
}

export function getMsAuthFlowLink({
  redirectTo,
  workspaceId,
  action,
}: MSAuthFlowState): URL {
  const params = {
    client_id: import.meta.env.VITE_MAIA_MS_GRAPH_CLIENT_ID,
    response_type: 'code',
    redirect_uri: `${import.meta.env.VITE_MAIA_APP_PUBLIC_URL}${import.meta.env.VITE_MAIA_MS_GRAPH_AUTHORIZE_REDIRECT_ROUTE}`,
    response_mode: 'query',
    scope: import.meta.env.VITE_MAIA_MS_GRAPH_USER_AUTH_SCOPE,
    state: JSON.stringify({ action, redirectTo, workspaceId }),
  };

  return createAuthUrl(
    'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize',
    params
  );
}

export function getAtlAuthFlowLink({
  state,
  workspaceId,
  redirectTo,
}: AtlAuthFlowState): URL {
  const params = {
    audience: 'api.atlassian.com',
    client_id: import.meta.env.VITE_MAIA_ATL_CLIENT_ID,
    response_type: 'code',
    redirect_uri: `${import.meta.env.VITE_MAIA_APP_PUBLIC_URL}${import.meta.env.VITE_MAIA_ATL_AUTHORIZE_REDIRECT_ROUTE}`,
    state: JSON.stringify({ state, redirectTo, workspaceId }),
    scope: import.meta.env.VITE_MAIA_ATL_USER_AUTH_SCOPE,
    prompt: 'consent',
  };

  return createAuthUrl('https://auth.atlassian.com/authorize', params);
}
