export var AvailableModelEnum;
(function (AvailableModelEnum) {
    AvailableModelEnum["GPT_4_O"] = "gpt-4-o";
    AvailableModelEnum["GPT_4_O_MINI"] = "gpt-4-o-mini";
    AvailableModelEnum["MISTRAL_LARGE_2411"] = "Mistral-large-2411";
    AvailableModelEnum["META_LLAMA_3_3_70B_INSTRUCT"] = "Meta-Llama-3-3-70B-Instruct";
    AvailableModelEnum["GEMINI_2_0_FLASH"] = "gemini-2.0-flash-001";
    AvailableModelEnum["GEMINI_1_5_PRO"] = "gemini-1.5-pro";
    AvailableModelEnum["CLAUDE_3_5_SONNET"] = "anthropic.claude-3-5-sonnet-20240620-v1:0";
})(AvailableModelEnum || (AvailableModelEnum = {}));
export const supportedModelsDisplayNameMap = new Map([
    // keeping the naming convention consistent with ChatGpt
    [AvailableModelEnum.GPT_4_O, 'GPT-4o'],
    [AvailableModelEnum.GPT_4_O_MINI, 'GPT-4o mini'],
    [AvailableModelEnum.MISTRAL_LARGE_2411, 'Mistral large'],
    [AvailableModelEnum.META_LLAMA_3_3_70B_INSTRUCT, 'Llama 3.3'],
    [AvailableModelEnum.GEMINI_2_0_FLASH, 'Gemini 2.0 Flash'],
    [AvailableModelEnum.GEMINI_1_5_PRO, 'Gemini 1.5 Pro'],
    [AvailableModelEnum.CLAUDE_3_5_SONNET, 'Claude 3.5 Sonnet'],
]);
const availableModelForPlan = new Map([
    ['FREE', []],
    ['BASIC', [AvailableModelEnum.GPT_4_O_MINI, AvailableModelEnum.GPT_4_O]],
    [
        'PROFESSIONAL',
        [
            AvailableModelEnum.GPT_4_O_MINI,
            AvailableModelEnum.GPT_4_O,
            AvailableModelEnum.MISTRAL_LARGE_2411,
            AvailableModelEnum.META_LLAMA_3_3_70B_INSTRUCT,
            AvailableModelEnum.GEMINI_2_0_FLASH,
            AvailableModelEnum.GEMINI_1_5_PRO,
            AvailableModelEnum.CLAUDE_3_5_SONNET,
        ],
    ],
    [
        'ENTERPRISE',
        [
            AvailableModelEnum.GPT_4_O_MINI,
            AvailableModelEnum.GPT_4_O,
            AvailableModelEnum.MISTRAL_LARGE_2411,
            AvailableModelEnum.META_LLAMA_3_3_70B_INSTRUCT,
            AvailableModelEnum.GEMINI_2_0_FLASH,
            AvailableModelEnum.GEMINI_1_5_PRO,
            AvailableModelEnum.CLAUDE_3_5_SONNET,
        ],
    ],
]);
/**
 * Determines whether a given model is available for a specified license tier.
 * If `byPassPlan` is true, the function checks only if the model exists, ignoring the user's plan restrictions.
 *
 * @param plan - The license tier to check model availability against.
 * @param model - The model to verify availability for.
 * @param byPassPlan - If true, bypasses plan restrictions but still ensures the model exists.
 * @returns True if the model is available for the given plan or globally (if `byPassPlan` is enabled), otherwise false.
 */
export function isModelAvailableForPlan(plan, model, byPassPlan) {
    if (byPassPlan) {
        return Object.values(AvailableModelEnum).includes(model);
    }
    const allowedModels = availableModelForPlan.get(plan);
    if (!allowedModels) {
        return false;
    }
    return allowedModels.includes(model);
}
/**
 * Retrieves the available models for a given license tier.
 * If `byPassPlan` is true, returns all available and experimental models, ignoring plan restrictions.
 *
 * @param plan - The license tier to retrieve available models for.
 * @param byPassPlan - If true, returns all models regardless of the user's plan.
 * @returns An array of available models for the specified plan.
 */
export function getAvailableModelsForPlan(plan, byPassPlan) {
    if (byPassPlan) {
        return [...Object.values(AvailableModelEnum)];
    }
    return availableModelForPlan.get(plan) ?? [];
}
