import { useEffect, useState } from 'react';

import {
  AvailableModelEnum,
  getAvailableModelsForPlan,
  supportedModelsDisplayNameMap,
} from 'common-ts';
import { useBoundStore, PERSISTED_STORE_KEY } from '@/store/useBoundStore';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from './ui/select.js';
import { createListCollection } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown } from '@fortawesome/pro-regular-svg-icons';

type ModelOption = {
  label: string;
  value: AvailableModelEnum;
};

type ChangeLlmProps = {
  onChange?: () => void;
  withGPTModelsOnly?: boolean;
};

export function ChangeLlm({
  onChange,
  withGPTModelsOnly = false,
}: ChangeLlmProps) {
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );

  const accessibleLlms = useBoundStore((state) => state.accessibleLlms);
  const model = useBoundStore((state) => state.llmModel);
  const setLlmModel = useBoundStore((state) => state.setModel);

  const [modelOptions, setModelOptions] = useState(
    createListCollection<ModelOption>({ items: [] })
  );

  const updateAvailableModels = () => {
    const availableModelsForPlan = getAvailableModelsForPlan(
      workspaceLicenseType ?? 'FREE',
      import.meta.env.VITE_MAIA_STAGE !== 'prod' ||
        import.meta.env.VITE_MAIA_STAGE !== 'release'
    );

    const supportedModels = createListCollection<ModelOption>({
      items: Array.from(supportedModelsDisplayNameMap.entries())
        .filter(
          ([modelEnum, _]) =>
            !withGPTModelsOnly ||
            modelEnum === AvailableModelEnum.GPT_4_O ||
            modelEnum === AvailableModelEnum.GPT_4_O_MINI
        )
        .filter(
          ([modelEnum, _]) =>
            availableModelsForPlan.includes(modelEnum) &&
            accessibleLlms.includes(modelEnum)
        )
        .map(([modelEnum, modelName]) => {
          return { label: modelName, value: modelEnum };
        }),
    });

    setModelOptions(supportedModels);
  };

  useEffect(() => {
    updateAvailableModels();
  }, [model, workspaceLicenseType]);

  useEffect(() => {
    // In case another model is selected, it will fallback to GPT-4o for search
    // As Search only supports GPT models
    if (
      withGPTModelsOnly &&
      ![AvailableModelEnum.GPT_4_O, AvailableModelEnum.GPT_4_O_MINI].includes(
        model
      )
    ) {
      setLlmModel(AvailableModelEnum.GPT_4_O);
    }

    // this is used to sync states between tabs
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === PERSISTED_STORE_KEY) {
        const store = JSON.parse(
          localStorage.getItem(PERSISTED_STORE_KEY) ?? ''
        );

        setLlmModel(store?.state?.llmModel);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  function persistGpt(model: AvailableModelEnum) {
    setLlmModel(model);
    onChange?.();
  }

  return (
    // for some reason, select is really adamant about default styles. Probably a bug!
    <SelectRoot
      collection={modelOptions}
      size={'sm'}
      onValueChange={({ value }) => persistGpt(value[0] as AvailableModelEnum)}
      variant={'subtle'}
      value={[model]}
      unstyled
    >
      <SelectTrigger className="flex w-max min-w-36 items-center justify-center">
        <SelectValueText />
        <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
      </SelectTrigger>
      <SelectContent className="border-maia-border flex w-max flex-col gap-y-0.5 rounded border border-solid bg-white px-2 py-2 shadow">
        {modelOptions.items.map((supportedModel) => (
          <SelectItem
            item={supportedModel}
            key={supportedModel.value}
            className={`hover:bg-maia-blue-100 flex w-full cursor-pointer items-center justify-between rounded px-2 py-1 ${model === supportedModel.value ? 'bg-gray-100' : ''}`}
          >
            <span>{supportedModel.label}</span>
            {model === supportedModel.value && (
              <FontAwesomeIcon icon={faCheck} className="ml-4" />
            )}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
}
