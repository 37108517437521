import ConfluenceSpaceSelectionModal, {
  SpaceItem,
} from './ConfluenceSpaceSelectionModal';
import { fetchApi, useApi } from '../../utils/useApi';
import { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import { ConfluenceProgress } from '../../../../common-ts/src/types/confluence';
import ConfluenceSpaceStatusTable from './ConfluenceSpaceStatusTable';
import ContentHeader from '../../components/ContentHeader';
import { LinkButton } from '../../components/ui/link-button';
import MaiaFullGridWidthContent from '../../components/layout/MaiaFullGridWidthContent';
import MaiaMainGrid from '../../components/layout/MaiaMainGrid';
import { Skeleton } from '../../components/ui/skeleton';
import { faPlug } from '@fortawesome/pro-regular-svg-icons';
import { getAtlAuthFlowLink } from '../../utils/msAuthFlow';
import { getSHA256ifSupported } from '@/utils/hashFunctions';
import { toaster } from '../../components/ui/toaster';
import { useBoundStore } from '../../store/useBoundStore';
import { useTranslation } from 'react-i18next';

function ConfluenceSettings() {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const [state, setState] = useState<string | undefined>(undefined);
  const [spaceSelectionModalOpen, setSpaceSelectionModalOpen] = useState(false);

  const {
    data: confluenceIntegrationStatus,
    error: confluenceStatusError,
    refetch: refetchIntegrationStatus,
  } = useApi(supabase, '/integrations', '/atlassian/status', {
    method: 'POST',
    workspaceId,
  });

  useEffect(() => {
    // Get random state https://developer.atlassian.com/cloud/jira/platform/oauth-2-3lo-apps/#authcode
    supabase.auth.getSession().then(async (v) => {
      if (v.data.session?.access_token) {
        setState(
          await getSHA256ifSupported(v.data.session.access_token.slice(0, 10))
        );
      }
    });
  }, []);

  const loading = !confluenceIntegrationStatus && !confluenceStatusError;

  async function handleLogout() {
    const res = await fetchApi(supabase, '/integrations', '/atlassian/logout', {
      method: 'POST',
      workspaceId,
    });

    if (!res.success) {
      toaster.create({ title: t('general.tryAgainError'), type: 'error' });
      return;
    }

    refetchIntegrationStatus();
  }

  async function handleSpaceSelection(spaces: SpaceItem[]) {
    const res = await fetchApi(
      supabase,
      '/integrations',
      '/atlassian/select_spaces',
      { method: 'POST', workspaceId, spaces }
    );

    if (!res.success) {
      toaster.create({ title: t('general.tryAgainError'), type: 'error' });
      return;
    }

    refetchIntegrationStatus();
  }

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (confluenceIntegrationStatus) {
      // Wild type thing confluenceIntegrationStatus.spaceProgress is of type ConfluenceProgress[]
      // but somehow it's of type any if you get something from this array like this its working i don't get it
      const spaceProgress: ConfluenceProgress[] =
        confluenceIntegrationStatus.spaceProgress;
      if (spaceProgress.find((v) => v.status !== 'SYNCED')) {
        timeoutId = setTimeout(() => {
          refetchIntegrationStatus();
        }, 5000);
      }
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [confluenceIntegrationStatus, confluenceStatusError]);

  return (
    <MaiaMainGrid>
      <MaiaFullGridWidthContent className="flex flex-col gap-4">
        <ContentHeader
          headingLabel={t('integrationSettings.title')}
          icon={faPlug}
          iconLabel={t('integrationSettings.title')}
          buttons={[
            <Skeleton loading={loading || !state}>
              {state && !confluenceIntegrationStatus?.validConnection && (
                <LinkButton
                  className="self-start"
                  colorPalette="maia-accent"
                  href={getAtlAuthFlowLink({
                    redirectTo: window.location.pathname,
                    workspaceId,
                    state,
                  }).toString()}
                >
                  {t('integrationSettings.confluence.activateButton')}
                </LinkButton>
              )}
              <Button
                colorPalette={'gray'}
                variant={'subtle'}
                disabled={!confluenceIntegrationStatus?.validConnection}
                onClick={() => {
                  setSpaceSelectionModalOpen(true);
                }}
              >
                {t('integrationSettings.confluence.connectSpaceButton')}
              </Button>
              {confluenceIntegrationStatus?.validConnection && (
                <Button
                  colorPalette="red"
                  onClick={() => handleLogout()}
                  ml={3}
                >
                  {t('integrationSettings.confluence.deactivateButton')}
                </Button>
              )}
            </Skeleton>,
          ]}
        />
        <div className="flex flex-col gap-6">
          {/* ---------------------------------------------------------------- Status text ---------------------------------------------------------------- */}
          <Skeleton loading={loading || !state}>
            <div className="whitespace-pre-line font-medium">
              {(() => {
                if (!confluenceIntegrationStatus?.validConnection)
                  return t('integrationSettings.confluence.activated');
                if (
                  confluenceIntegrationStatus.connectedTenants.length > 0 &&
                  confluenceIntegrationStatus.spaceProgress.length === 0
                )
                  return t('integrationSettings.confluence.noSpaceConnected');
                if (confluenceIntegrationStatus.connectedTenants.length === 0)
                  return t('integrationSettings.confluence.noTenantAvailable');
                return null;
              })()}
            </div>
          </Skeleton>
          {!!confluenceIntegrationStatus?.spaceProgress.length && (
            <ConfluenceSpaceStatusTable
              spaces={confluenceIntegrationStatus.spaceProgress}
            />
          )}
        </div>
      </MaiaFullGridWidthContent>
      {spaceSelectionModalOpen ? (
        <ConfluenceSpaceSelectionModal
          isOpen={spaceSelectionModalOpen}
          onNewSpaceSelected={handleSpaceSelection}
          onClose={() => {
            setSpaceSelectionModalOpen(false);
          }}
        />
      ) : null}
    </MaiaMainGrid>
  );
}

export default ConfluenceSettings;
