import {
  CONFLUENCE_SELECTION_MAP_PATH_DELIMITER,
  ConfluenceFileSelectionMap,
  SupportedFileType,
  zeros,
} from 'common-ts';
import GenericSelectableFileAndFolderList, {
  BasicFileInfo,
  BasicFolderInfo,
} from './GenericSelectableFileAndFolderList';
import { IconButton, Skeleton } from '@chakra-ui/react';
import { faAngleRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useMemo, useState } from 'react';

import { Checkbox } from '@/components/ui/checkbox';
import { ConfluenceSelectionStateHandlers } from './useConfluenceSelectionStateHandlers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkButton } from '@/components/ui/link-button';
import ListItem from '../../../components/ListItem';
import { Tooltip } from '@/components/ui/tooltip';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { getAtlAuthFlowLink } from '@/utils/msAuthFlow';
import { getSHA256ifSupported } from '@/utils/hashFunctions';
import { pathStringFromBreadCrumbs } from './utils';
import { twMerge } from 'tailwind-merge';
import { useApi } from '@/utils/useApi';
import { useBoundStore } from '@/store/useBoundStore';
import { useTranslation } from 'react-i18next';

type ConfluenceSpaceNavigatorProps = {
  className?: string;
  selectedFileMap: ConfluenceFileSelectionMap;
  selectionStateHandlers: ConfluenceSelectionStateHandlers;
  documentViewerActive: boolean;
  onBackClick: () => void;
};

function ConfluenceSpaceNavigator({
  className,
  selectedFileMap,
  selectionStateHandlers,
  documentViewerActive,
  onBackClick,
}: ConfluenceSpaceNavigatorProps) {
  const { t } = useTranslation();

  const workspaceId = useBoundStore((state) => state.workspaceId);
  const supabase = useBoundStore((state) => state.supabase);

  const [randomStateForConfluenceLogin, setRandomStateForConfluenceLogin] =
    useState<string | undefined>(undefined);

  const { data: confluenceIntegrationStatus } = useApi(
    supabase,
    '/integrations',
    '/atlassian/status',
    { method: 'POST', workspaceId }
  );

  const { data: availableSpacesRes, loading: loadingSpaces } = useApi(
    supabase,
    '/integrations',
    '/atlassian/connected_spaces',
    { method: 'POST', workspaceId }
  );

  useEffect(() => {
    // Get random state https://developer.atlassian.com/cloud/jira/platform/oauth-2-3lo-apps/#authcode
    supabase.auth.getSession().then(async (v) => {
      if (v.data.session?.access_token) {
        setRandomStateForConfluenceLogin(
          await getSHA256ifSupported(v.data.session.access_token.slice(0, 10))
        );
      }
    });
  }, []);

  const [fileList, folderList] = useMemo(() => {
    const fileList: (BasicFileInfo & { hasChildren: boolean })[] = [];
    const folderList: BasicFolderInfo[] = [];

    const mimeType: SupportedFileType = 'text/plain';

    for (const contentItem of selectionStateHandlers.contentItems) {
      if (contentItem.type === 'page') {
        fileList.push({
          embeddingStatus: contentItem.embeddingStatus ?? 'FAILED',
          hasChildren: contentItem.hasChildren,
          id: contentItem.id,
          mimeType,
          name: contentItem.title,
        });
      } else {
        folderList.push({ id: contentItem.id, name: contentItem.title });
      }
    }

    return [fileList, folderList];
  }, [selectionStateHandlers.contentItems]);

  return (
    <div
      className={twMerge(
        'border-maia-border flex w-full flex-col border-b border-t',
        className
      )}
    >
      {!randomStateForConfluenceLogin || !confluenceIntegrationStatus ? (
        <div className="flex w-full">
          <div className="flex flex-grow flex-col gap-2 px-4 py-2">
            {zeros(5).map((_, index) => {
              return (
                <Skeleton key={index}>
                  <ListItem selected>Loading</ListItem>
                </Skeleton>
              );
            })}
          </div>
          <div className="flex flex-grow flex-col gap-2 px-4 py-2">
            {zeros(5).map((_, index) => {
              return (
                <Skeleton key={index}>
                  <ListItem selected>Loading</ListItem>
                </Skeleton>
              );
            })}
          </div>
        </div>
      ) : !confluenceIntegrationStatus.validConnection ? (
        <div className="m-auto flex w-3/4 flex-col items-center justify-center gap-2 p-4 text-center">
          <div>
            {t(
              'integrationSettings.confluence.personalConnection.infoAccountNotConnected'
            )}
          </div>
          <LinkButton
            colorPalette="maia-purple"
            href={getAtlAuthFlowLink({
              redirectTo: window.location.pathname,
              workspaceId,
              state: randomStateForConfluenceLogin,
            }).toString()}
          >
            {t(
              'integrationSettings.confluence.personalConnection.connectAccountButton'
            )}
          </LinkButton>
        </div>
      ) : (
        <div className="flex min-h-0 flex-grow">
          {/* ------------------------------------------------ Sites ------------------------------------------------ */}
          <div
            className={`border-maia-border flex flex-grow flex-col gap-2 overflow-y-auto border-r px-4 py-2 md:w-72 md:flex-grow-0 ${selectionStateHandlers.currentSpace ? 'hidden md:flex' : ''}`}
          >
            <IconButton
              className="self-start md:hidden"
              aria-label="back"
              variant={'ghost'}
              onClick={onBackClick}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
            {loadingSpaces
              ? zeros(5).map((_, index) => {
                  return (
                    <Skeleton key={index}>
                      <ListItem selected={false}>Loading</ListItem>
                    </Skeleton>
                  );
                })
              : availableSpacesRes?.spaces.map((space) => {
                  const checkedStatus =
                    selectedFileMap[
                      `${space.clientKey}${CONFLUENCE_SELECTION_MAP_PATH_DELIMITER}${space.id}`
                    ]?.selected;

                  return (
                    <ListItem
                      key={space.id}
                      selected={
                        space.id === selectionStateHandlers.currentSpace?.id
                      }
                      className="flex cursor-pointer select-none items-center gap-2 px-4 text-sm"
                    >
                      <Checkbox
                        colorPalette="maia-purple"
                        className="hover:bg-maia-purple-50 rounded"
                        checked={
                          checkedStatus === null
                            ? 'indeterminate'
                            : typeof checkedStatus === 'undefined'
                              ? false
                              : checkedStatus
                        }
                        onCheckedChange={() => {
                          selectionStateHandlers.handleSpaceSelectToggle(
                            space.clientKey,
                            space.id,
                            space.name,
                            selectionStateHandlers.currentSpace?.id,
                            selectionStateHandlers.breadCrumbs
                          );
                        }}
                      />
                      {/* The div hierarchy is a bit awkward here in order to separate the checkbox from the rest of the Listitem's content */}
                      <div
                        className="flex w-28 flex-grow items-center justify-between"
                        onClick={() => {
                          selectionStateHandlers.setCurrentSpace({
                            id: space.id,
                            name: space.name,
                            clientKey: space.clientKey,
                          });
                        }}
                      >
                        <div className="flex min-w-0 items-center gap-2">
                          <FontAwesomeIcon
                            icon={faFolder}
                            className="text-chakra-gray-400 text-base"
                          />
                          <Tooltip content={space.name}>
                            <div className="truncate">{space.name}</div>
                          </Tooltip>
                        </div>
                        <div className="text-chakra-gray-500 flex items-center gap-1">
                          {/* <div>{space.file_count}</div> */}
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="text-xs"
                          />
                        </div>
                      </div>
                    </ListItem>
                  );
                })}
          </div>
          {/* ------------------------------------------------ Folders and files ------------------------------------------------ */}
          <div
            className={`min-w-96 flex-grow px-4 py-2 ${!selectionStateHandlers.currentSpace ? 'hidden md:flex' : ''}`}
          >
            <GenericSelectableFileAndFolderList
              className={`${selectionStateHandlers.currentSpace ? '' : 'hidden'}`}
              hideCounts
              breadCrumbs={selectionStateHandlers.breadCrumbs}
              folderMapPathFromBreadCrumbs={(breadCrumbs) =>
                pathStringFromBreadCrumbs({
                  breadCrumbs,
                  delimiter: CONFLUENCE_SELECTION_MAP_PATH_DELIMITER,
                })
              }
              files={fileList}
              folders={folderList}
              fileCountsAsFolder={(file) => file.hasChildren}
              loading={selectionStateHandlers.loading}
              onBackClick={() => {
                if (selectionStateHandlers.breadCrumbs.length) {
                  selectionStateHandlers.handleGoBackInCrumbs(
                    selectionStateHandlers.breadCrumbs.length - 2
                  );
                } else {
                  // Mobile: if already in root folder -> go back to site selection
                  selectionStateHandlers.setCurrentSpace(undefined);
                }
              }}
              onDeselectAllClick={() => {
                if (selectionStateHandlers.currentSpace) {
                  selectionStateHandlers.handleDeselectAll(
                    selectionStateHandlers.breadCrumbs,
                    selectionStateHandlers.currentSpace.id,
                    selectionStateHandlers.currentSpace.clientKey
                  );
                }
              }}
              onFileCheckboxClick={(fileId) => {
                if (selectionStateHandlers.currentSpace) {
                  selectionStateHandlers.handleFileSelectToggle(
                    selectionStateHandlers.breadCrumbs,
                    fileId,
                    selectionStateHandlers.currentSpace.id,
                    selectionStateHandlers.currentSpace.clientKey
                  );
                }
              }}
              onFolderCheckboxClick={(folderId) => {
                if (selectionStateHandlers.currentSpace) {
                  selectionStateHandlers.handleFolderSelectToggle(
                    selectionStateHandlers.breadCrumbs,
                    folderId,
                    selectionStateHandlers.currentSpace.id,
                    selectionStateHandlers.currentSpace.clientKey
                  );
                }
              }}
              onFolderClick={({ folderId, name }) => {
                selectionStateHandlers.handleGoToFolder({
                  folderId,
                  name,
                });
              }}
              onRefreshClick={
                selectionStateHandlers.refetchCurrentFolderContents
              }
              onSelectAllClick={() => {
                if (selectionStateHandlers.currentSpace) {
                  selectionStateHandlers.handleSelectAll(
                    selectionStateHandlers.breadCrumbs,
                    selectionStateHandlers.currentSpace.id,
                    selectionStateHandlers.currentSpace.clientKey
                  );
                }
              }}
              onSelectFolderInCrumbs={(index) => {
                selectionStateHandlers.handleGoBackInCrumbs(index);
              }}
              selectedFileMap={
                selectionStateHandlers.currentSpace
                  ? (selectedFileMap[
                      `${selectionStateHandlers.currentSpace.clientKey}${CONFLUENCE_SELECTION_MAP_PATH_DELIMITER}${selectionStateHandlers.currentSpace.id}`
                    ]?.folders ?? {})
                  : {}
              }
              documentViewerActive={documentViewerActive}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfluenceSpaceNavigator;
