/**
 * Function to attach a stack trace to self defined errors. If Error().stack is not supported by the engine, returns the original error unchanged.
 * @param error The error to attach the stack trace to
 * @returns The original error with attached stack trace (if supported by the engine)
 */
export function maiaErrorWithStack(error) {
    if (error.payload &&
        typeof error.payload === 'object' &&
        'originalError' in error.payload) {
        try {
            error.payload.originalError = JSON.stringify(error.payload.originalError, Object.getOwnPropertyNames(error.payload.originalError));
        }
        catch {
            error.payload.originalError = JSON.stringify(error.payload.originalError, (_, value) => {
                if (typeof value === 'bigint') {
                    return value.toString();
                }
                else {
                    return value;
                }
            });
        }
    }
    const errorObj = new Error();
    const propertyIsSupported = typeof errorObj?.stack === 'string';
    if (!propertyIsSupported) {
        return error;
    }
    return { ...error, stack: errorObj.stack };
}
/**
 * Helper function to create a maiaErrorWithStack error response for a supabase postgres error.
 * Supabase returns a PostgrestError when a query fails. This function uses the PostgrestError as the payload for the
 * maiaErrorWithStack error. This way we can return supabase postgres errors in a consistent way.
 * The data field of the response will always be null.
 * @example
 * const { data, error } = await supabase.from('example_table').eq('id', 1).select()
 * if (error) return createSupabaseErrorResponse(error, 'Failed to fetch data from example_table')
 * @param error The PostgrestError returned by supabase
 * @param message The custom message to attach to the error, this is not the message from the PostgrestError.
 */
export const createSupabaseErrorResponse = (error, message) => ({
    data: null,
    error: maiaErrorWithStack({
        message,
        name: 'SupabasePostgresError',
        payload: {
            message: error.message,
            details: error.details,
            hint: error.hint,
            code: error.code,
        },
    }),
});
