/**
 * generates a safe SHA 256 if its supported by the used browser otherwise it will create an unsecure one.
 *
 * @param data a string which should be hashed
 * @returns a hashed string
 */
export async function getSHA256ifSupported(data: string) {
  // Helper function for a basic non-cryptographic hash
  /**
   * A fallback hash functions for browsers which do not support the crypto package
   *
   * @param input a string what every you want to hash.
   * @returns a shitty has with 16 chars but if you need this the browser is the problem not the shitty hash
   */
  function fallbackHash(input: string) {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return hash.toString(16); // Return as hexadecimal
  }

  if (window.crypto?.subtle) {
    try {
      const encoder = new TextEncoder();
      const buffer = await crypto.subtle.digest(
        'SHA-256',
        encoder.encode(data)
      );
      return Array.from(new Uint8Array(buffer))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
    } catch (error) {
      console.warn(
        'SHA-256 failed, falling back to non-cryptographic hash:',
        error
      );
    }
  } else {
    console.warn('Crypto API not supported, using fallback hash.');
  }

  // Fallback to the non-cryptographic hash
  return fallbackHash(data);
}
