import { defineSlotRecipe } from '@chakra-ui/react';
import { progressAnatomy } from '@chakra-ui/react/anatomy';

export const progressCircleSlotRecipe = defineSlotRecipe({
  className: 'chakra-progress-circle',
  slots: progressAnatomy.keys(),
  base: {
    circle: {
      _indeterminate: {
        animation: 'spin 2s linear infinite',
      },
    },
    circleTrack: {
      '--track-color': 'colors.maia-accent.500',
    },
    circleRange: {
      stroke: 'maia-gray.200',
      _indeterminate: {
        animation: 'circular-progress 3s linear infinite',
      },
    },
  },
});
