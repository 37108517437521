import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { useBoundStore } from '../../store/useBoundStore';
import { captureException } from '@sentry/react';
import NotFoundPage from '../iframe/NotFoundComponent';
import { navigateToDefaultWorkspace } from '../../utils/getDefaultWorkspace';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'common-ts';
import { toaster } from '../../components/ui/toaster';
import { Container, VStack, Text } from '@chakra-ui/react';
import { Alert } from '../../components/ui/alert';
import { Button } from '../../components/ui/button';

type InviteConfirmationError = {
  general?: string;
};

/**
 * Invite confirmation page. This page is used when a user clicks on an invite link.
 * The user can join the workspace by clicking the "Accept Invitation" button.
 * If the token is expired, the user is prompted to login or reset their password.
 */
export default function AcceptInvite() {
  const supabase = useBoundStore((state) => state.supabase);
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<InviteConfirmationError>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const emailParam = searchParams.get('email');
    const tokenParam = searchParams.get('token');

    if (!emailParam || !tokenParam) {
      return;
    }

    setEmail(emailParam);
    setToken(tokenParam);
  }, [searchParams]);

  const handleInviteConfirmation = async () => {
    setIsLoading(true);
    setError({});

    try {
      // Verify the invite token
      const { error: otpError } = await supabase.auth.verifyOtp({
        email,
        token,
        type: 'invite',
      });

      if (otpError) {
        if (otpError.code === 'otp_expired') {
          setError({
            general: t('inviteConfirmationView.tokenExpired'),
          });
          setIsTokenExpired(true);
          return;
        }
        throw otpError;
      }

      // If successful, navigate to the default workspace
      navigateToDefaultWorkspace({
        navigateFn: navigate,
        toaster,
        supabase,
        translationFn: t,
      });
    } catch (err) {
      captureException(err);
      setError({
        general: t('inviteConfirmationView.confirmError'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!email || !token) {
    return (
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  }

  return (
    <Container maxW="md">
      <VStack gap={6} align="stretch" className="py-8">
        <Text fontSize="2xl" fontWeight="bold" className="text-center">
          {t('inviteConfirmationView.welcome')}
        </Text>

        {!isTokenExpired && (
          <Text className="text-center">
            {t('inviteConfirmationView.instructions', { email })}
          </Text>
        )}

        {error.general && <Alert status="error">{error.general}</Alert>}

        {!isTokenExpired ? (
          <Button
            className="bg-maia-accent text-maia-text-light w-full"
            onClick={handleInviteConfirmation}
            loading={isLoading}
          >
            {t('inviteConfirmationView.acceptInvite')}
          </Button>
        ) : (
          <VStack gap={4} className="w-full">
            <Text className="text-center">
              {t('inviteConfirmationView.alreadyRegistered')}
            </Text>
            <Button
              className="bg-maia-accent text-maia-text-light w-full"
              onClick={() => navigate(ROUTES.AUTH.SIGN_IN.$buildPath({}))}
            >
              {t('inviteConfirmationView.goToLogin')}
            </Button>
            <Button
              variant="ghost"
              className="w-full"
              onClick={() =>
                navigate(ROUTES.AUTH.FORGOT_PASSWORD.$buildPath({}))
              }
            >
              {t('inviteConfirmationView.forgotPassword')}
            </Button>
          </VStack>
        )}
      </VStack>
    </Container>
  );
}
