import { Tabs } from '@chakra-ui/react';
import { ROUTES } from 'common-ts';
import SigninForm from './SigninForm.js';
import SignupForm from './SignupForm.js';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSearchParams } from 'react-router-typesafe-routes';

export enum LoginViewTabEnum {
  SIGNIN = 'signin',
  SIGNUP = 'signup',
}

type LoginViewProps = {
  defaultTab?: LoginViewTabEnum;
};

function LoginView({ defaultTab }: LoginViewProps) {
  const [searchParams] = useTypedSearchParams(ROUTES.AUTH.SIGN_IN);
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultTab ?? LoginViewTabEnum.SIGNIN);
  const navigate = useNavigate();
  return (
    <>
      <Tabs.Root
        colorPalette={'maia-accent'}
        className="w-full"
        value={value}
        onValueChange={(e) => {
          const newValue = e.value as LoginViewTabEnum;
          setValue(newValue);
          navigate(
            newValue === LoginViewTabEnum.SIGNIN
              ? ROUTES.AUTH.SIGN_IN.$buildPath({
                  params: {},
                  searchParams: searchParams.inviteCode
                    ? { inviteCode: searchParams.inviteCode }
                    : undefined,
                })
              : ROUTES.AUTH.SIGN_UP.$buildPath({
                  params: {},
                  searchParams: searchParams.inviteCode
                    ? { inviteCode: searchParams.inviteCode }
                    : undefined,
                })
          );
        }}
      >
        <Tabs.List className="text-sm">
          <Tabs.Trigger value={LoginViewTabEnum.SIGNIN}>
            {t('loginView.login')}
          </Tabs.Trigger>
          <Tabs.Trigger value={LoginViewTabEnum.SIGNUP}>
            {t('loginView.register')}
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.ContentGroup>
          <Tabs.Content value={LoginViewTabEnum.SIGNIN}>
            <SigninForm />
          </Tabs.Content>
          <Tabs.Content value={LoginViewTabEnum.SIGNUP}>
            <SignupForm
              redirectToSignin={() => setValue(LoginViewTabEnum.SIGNUP)}
            />
          </Tabs.Content>
        </Tabs.ContentGroup>
      </Tabs.Root>
      {/* <LoginWithSingleSignOn />*/}
    </>
  );
}

export default LoginView;
